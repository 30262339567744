import { Select } from "@chakra-ui/react";
import { REGISTER_TYPES } from "../../constant/register_type";

export default function FilterByRegisterType({
  selectedRegisterType,
  onChangeRegisterType,
}) {
  const registerTypeList = REGISTER_TYPES;
  return (
    <div style={{ width: "250px", color: "#293038", background: "#ffffff" }}>
      <Select placeholder="Phương thức đăng ký" onChange={onChangeRegisterType}>
        {registerTypeList.map(registerType => {
          return (
            <option
              onClick={() => onChangeRegisterType(registerType.key)}
              selected={registerType.key === selectedRegisterType}
              value={registerType.key}
              color="#293038"
            >
              {registerType.value}
            </option>
          );
        })}
      </Select>
    </div>
  );
}
