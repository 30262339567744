import { useState, useEffect } from "react";
import {
  apiAddChannel,
  apiEditChannel,
  apiGetChannels,
} from "../../api/channels.api";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";

import { CHANNEL_STATUSES } from "../../constant/channel_statuses";

export default function ModalEditChannel({
  channel,
  isOpen,
  onOpen,
  onClose,
  onOkEdit,
}) {
  const [loading, setLoading] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [isDirty, setIsDirty] = useState({});
  const [channelToEdit, setChannelToEdit] = useState({});
  const formRules = {
    name: [
      {
        key: "required",
        message: "Vui lòng điền tên kênh.",
        validate: value => !value,
      },
      {
        key: "maxLength",
        message: "Tên kênh không được quá 100 ký tự.",
        validate: value => value && value.length > 100,
      },
    ],
    // description: [
    //   {
    //     key: "required",
    //     message: "Vui lòng điền mô tả.",
    //     validate: value => !value,
    //   },
    //   {
    //     key: "maxLength",
    //     message: "Mô tả không được quá 500 ký tự.",
    //     validate: value => value && value.length > 500,
    //   },
    // ],
    status: [
      {
        key: "required",
        message: "Vui lòng chọn trạng thái.",
        validate: value => !value,
      },
    ],
  };
  const [errorForm, setErrorForm] = useState({});
  const toast = useToast();

  useEffect(() => {
    onSave();
  }, [isSave]);
  useEffect(() => {
    setChannelToEdit({ ...channel });
  }, [channel]);
  useEffect(() => {
    validateForm();
    const tempDirty = {};
    Object.keys(formRules).forEach(field => {
      tempDirty[field] = channelToEdit[field] || isDirty[field] ? true : false;
    });
    setIsDirty(tempDirty);
  }, [channelToEdit]);
  const onSave = async () => {
    if (isSave && !validateForm()) {
      setLoading(true);
      const payload = {
        name: channelToEdit.name,
        description: channelToEdit.description,
        status: channelToEdit.status,
      };

      await apiEditChannel(channel._id, payload).then(
        response => {
          toast({
            title: "Sửa thành công.",
            description: "Sửa thành công.",
            status: "success",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
          setLoading(false);
          onOkEdit();
          setIsSave(false);
          setChannelToEdit({});
          setIsDirty({});
          onClose();
          return true;
        },
        error => {
          console.log(error);
          toast({
            title: error?.response?.data?.message || "Đã xảy ra lỗi.",
            position: "top-right",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          setIsSave(false);
          setLoading(false);
        }
      );
    } else {
      setIsSave(false);
    }
  };
  const isError = (field, value) => {
    if (Object.keys(formRules).includes(field)) {
      const rules = formRules[field];
      const error = rules.find(rule => rule.validate(value));
      setErrorForm({
        ...errorForm,
        [field]: error,
      });
      return error;
    }
  };

  const validateForm = () => {
    let isErr = false;
    const errorList = {};
    for (const field in formRules) {
      const error = isError(field, channelToEdit[field]);
      errorList[field] = error;
      if (error) {
        isErr = true;
      }
    }
    setErrorForm(errorList);
    return isErr;
  };
  return (
    <Modal
      isCentered={true}
      size="full"
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={() => {
        setChannelToEdit({ ...channel });
        setIsDirty({});
        setIsSave(false);
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent
        alignItems="center"
        width="600px"
        maxHeight="80vh!important"
        minHeight="unset!important"
        paddingLeft="80px"
        paddingRight="80px"
      >
        <ModalHeader>
          <Text textAlign="center" fontSize="18px">
            Sửa thông tin kênh
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody width="100%">
          <Box width="100%" position="relative">
            <Grid marginBottom="10px" templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Tên kênh <Text color="red">*</Text>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl isInvalid={errorForm.name && isDirty.name}>
                  <Input
                    value={channelToEdit?.name}
                    onChange={e =>
                      setChannelToEdit({
                        ...channelToEdit,
                        name: e.target.value,
                      })
                    }
                  />
                  {errorForm.name && (
                    <FormErrorMessage>
                      {errorForm.name.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
            </Grid>

            <Grid marginBottom="10px" templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Mô tả
              </GridItem>
              <GridItem colSpan={2} style={{ position: "relative" }}>
                <Textarea
                  value={channelToEdit.description}
                  onChange={e => {
                    setChannelToEdit({
                      ...channelToEdit,
                      description: e.target.value,
                    });
                  }}
                  resize="none"
                />
              </GridItem>
            </Grid>

            <Grid marginBottom="10px" templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Trạng thái <Text color="red">*</Text>
              </GridItem>
              <GridItem colSpan={2} style={{ position: "relative" }}>
                <FormControl isInvalid={errorForm.status && isDirty.status}>
                  <Select
                    placeholder="Chọn trạng thái"
                    onChange={e => {
                      setChannelToEdit({
                        ...channelToEdit,
                        status: e.target.value,
                      });
                    }}
                    value={channelToEdit.status}
                  >
                    {CHANNEL_STATUSES?.map((ch, index) => {
                      return (
                        <option key={index} value={ch.value}>
                          {ch.label}
                        </option>
                      );
                    })}
                  </Select>
                  {errorForm.status && (
                    <FormErrorMessage>
                      {errorForm.status.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
            </Grid>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => {
              setIsSave(true);
            }}
          >
            {loading && <Spinner />}
            Lưu
          </Button>
          <Button
            variant="ghost"
            onClick={() => {
              setChannelToEdit({ ...channel });
              setIsDirty({});
              setIsSave(false);
              onClose();
            }}
          >
            Huỷ
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
