export const REGISTER_TYPES = [
  {
    key: "EMAIL_PASSWORD",
    value: "Email & Password",
  },
  {
    key: "FACEBOOK",
    value: "Facebook",
  },
  {
    key: "GOOGLE",
    value: "Google",
  },
];
