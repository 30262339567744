import { ChevronDownIcon } from "@100mslive/react-icons";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { Tooltip } from "@chakra-ui/tooltip";

export default function ChickenItem({ info, onClick, children }) {
  const styleLineClamp1 = {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    textOverflow: "ellipsis",
    maxWidth: "100%",
    lineClamp: 1,
    whiteSpace: "normal",
    textAlign: "start",
  };
  return (
    <Box
      style={{
        width: "100%",
        height: "40px",
      }}
      onClick={onClick}
    >
      <Flex
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Flex
          align="center"
          gap={5}
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <img
            src={`${process.env.REACT_BASE_IMG_URL}/uploads/images/${info.image}`}
            alt="ảnh đại diện"
            style={{
              borderRadius: "50%",
              width: "30px",
              height: "30px",
            }}
          />
          <Tooltip placement="top-start" label={info.name}>
            <span style={styleLineClamp1}>{info.name}</span>
          </Tooltip>
        </Flex>
      </Flex>
      {/* <Flex align="center">
        <Tooltip placement="top-start" label={info.owner}>
          <span style={{ ...styleLineClamp1, flex: 1 }}>{info.owner}</span>
        </Tooltip>
        <Text style={{ flex: 1 }}>
          {info.weight}
          {"(gram)"}
        </Text>
      </Flex> */}
    </Box>
  );
}
