import { request } from "./request";

export const apiGetChannels = (data = {}, config = {}) =>
  request("get", "/channel", data, config);

export const apiGetChannel = (id, config) =>
  request("get", `/channel/${id}`, {}, config);

export const apiAddChannel = data => request("post", "/channel", data);
export const apiEditChannel = (id, data) =>
  request("patch", `/channel/${id}`, data);
export const apiDeleteChannel = id => request("delete", `/channel/${id}`);
