import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Text,
  Toast,
  useToast,
} from "@chakra-ui/react";
import { data } from "autoprefixer";
import { get, set } from "mobx";
import Pusher from "pusher-js";
import { AddIcon } from "@100mslive/react-icons";
import { apiGetChannels } from "../../api/channels.api";
import { apiGetChickens } from "../../api/chickens.api";
import { apiUpdateMatch } from "../../api/matches.api";

export default function ModalEditMatch({ isOpen, onClose, match, onOkUpdate }) {
  const [chickens, setChickens] = useState([]);
  const [channels, setChannels] = useState([]);

  const [matchToEdit, setMatchToEdit] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [formRules, setFormRules] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [channelPusher, setChannelPusher] = useState(null);
  // const [channel] = useState();
  const matchStatuses = [
    {
      key: "win",
      name: "Thắng",
    },
    {
      key: "lost",
      name: "Thua",
    },
    {
      key: "draw",
      name: "Hòa",
    },
  ];
  // console.log(matchToEdit);
  const dataChicken = async () => {
    await apiGetChickens().then(
      response => {
        // console.log(response.data);
        setChickens(response.data);
        return response.data;
      },
      error => {
        return false;
      }
    );
  };

  const dataChannel = async () => {
    await apiGetChannels().then(
      response => {
        // console.log(response.data);
        setChannels(response.data);
        return response.data;
      },
      error => {
        return false;
      }
    );
  };

  const initPusher = () => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      // appId: process.env.REACT_APP_PUSHER_APP_ID,
      // key: process.env.REACT_APP_PUSHER_KEY,
      // secret: process.env.REACT_APP_PUSHER_SECRET,
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      channelAuthorization: {
        endpoint: process.env.REACT_APP_PUSHER_AUTH,
      },
      // useTLS: true,
    });
    const channel = pusher.subscribe("private-channel");
    // setChannelPusher(channel);
    console.log(channel);
  };

  useEffect(() => {
    console.log("init");
    initPusher();
  }, []);

  useEffect(() => {
    dataChicken();
    dataChannel();
  }, []);

  useEffect(() => {
    if (isUpdate) onSave();
  }, [isUpdate]);

  useEffect(() => {
    setMatchToEdit({
      ...match,
    });
    // console.log(matchToEdit);
  }, [match]);
  const onSave = async () => {
    // console.log(matchToEdit);
    const payload = {
      name: matchToEdit.name,
      chickenRed: matchToEdit.chickenRed_id,
      chickenBlue: matchToEdit.chickenBlue_id,
      timeLive: matchToEdit.timeLive,
      channel: matchToEdit.channel_id,
      status: matchToEdit.status,
      match: matchToEdit.match,
      live: matchToEdit.live,
      title: matchToEdit.title,
    };
    setLoading(true);
    const pusherData = getPusherData();
    await apiUpdateMatch(matchToEdit.id, payload).then(
      response => {
        toast({
          title: "Cập nhật thành công.",
          description: "Cập nhật thành công.",
          status: "success",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        // runPusher(pusherData);
        onOkUpdate();
        setIsUpdate(false);
        onClose();
        return true;
      },
      error => {
        // console.log(error);
        toast({
          title: error?.response?.data?.message || "Đã xảy ra lỗi.",
          position: "top-right",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      }
    );
  };

  const getPusherData = () => {
    if (matchToEdit.live !== match.live) {
      return {
        live: matchToEdit.live,
        id: matchToEdit.id,
      };
    } else {
      if (matchToEdit.live === "LIVE" && matchToEdit.match !== match.match) {
        return {
          id: matchToEdit.id,
          match: matchToEdit.match,
        };
      } else if (
        matchToEdit.live === "ENDED" &&
        matchToEdit.status &&
        !match.status
      ) {
        return {
          id: matchToEdit.id,
          status: matchToEdit.status,
        };
      } else return {};
    }
  };

  const runPusher = data => {
    console.log("runPusher", data);
    const dataSend = { message: "test", data };

    const triggered = channel.trigger(
      "client-new-notification",
      JSON.stringify(dataSend)
    );
    console.log(triggered);
  };
  return (
    <Modal
      size="full"
      display="flex"
      alignItems="center"
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={() => {
        setMatchToEdit({
          ...match,
        });
        setIsUpdate(false);
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent
        alignItems="center"
        width="800px"
        maxHeight="80vh!important"
        minHeight="unset!important"
      >
        <ModalHeader>
          <Text textAlign="center" fontSize="18px">
            Cấu hình trận đấu
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody width="100%">
          <Flex align="center" justify="end" gap="5px">
            {matchToEdit.live === "UPCOMING" && (
              <Button
                colorScheme="orange"
                style={{ color: "#293038", border: "1px solid #293038" }}
                onClick={() => {
                  setMatchToEdit({ ...matchToEdit, live: "LIVE" });
                  setIsUpdate(true);
                }}
              >
                Bắt đầu live
              </Button>
            )}
            {matchToEdit.live === "LIVE" && (
              <Button
                colorScheme="red"
                onClick={() => {
                  setMatchToEdit({ ...matchToEdit, live: "ENDED" });
                  setIsUpdate(true);
                }}
              >
                Kết thúc live
              </Button>
            )}
          </Flex>
          <Box width="100%" position="relative">
            {matchToEdit.live !== "UPCOMING" && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  background: "#fff",
                  cursor: "not-allowed",
                  opacity: "0.6",
                  zIndex: "1",
                }}
              ></div>
            )}
            <Text fontSize="12px" fontWeight="semibold">
              I. Thay đổi thông tin trận đấu
            </Text>

            <Grid marginBottom="10px" templateColumns="repeat(5, 1fr)" gap={6}>
              <GridItem colSpan={1} />
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Tên trận đấu
              </GridItem>
              <GridItem colSpan={2}>
                <Input
                  value={matchToEdit.name}
                  onChange={e =>
                    setMatchToEdit({ ...matchToEdit, name: e.target.value })
                  }
                />
              </GridItem>
              <GridItem colSpan={1} />
            </Grid>

            <Grid marginBottom="10px" templateColumns="repeat(5, 1fr)" gap={6}>
              <GridItem colSpan={1} />
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Đội đỏ
              </GridItem>
              <GridItem colSpan={2}>
                <Select
                  placeholder="Chọn bên đỏ"
                  onChange={e => {
                    setMatchToEdit({
                      ...matchToEdit,
                      chickenRed_id: parseInt(e.target.value),
                    });
                    // console.log(matchToEdit);
                  }}
                  value={matchToEdit.chickenRed_id}
                >
                  {chickens.map((chicken, index) => {
                    return (
                      <option key={index} value={chicken.id}>
                        {chicken.name}
                      </option>
                    );
                  })}
                </Select>
              </GridItem>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                <AddIcon cursor="pointer" />
              </GridItem>
            </Grid>

            <Grid marginBottom="10px" templateColumns="repeat(5, 1fr)" gap={6}>
              <GridItem colSpan={1} />
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Đội xanh
              </GridItem>
              <GridItem colSpan={2}>
                <Select
                  placeholder="Chọn bên xanh"
                  onChange={e => {
                    // console.log(e.target.value);
                    setMatchToEdit({
                      ...matchToEdit,
                      chickenBlue_id: parseInt(e.target.value),
                    });
                  }}
                  value={matchToEdit.chickenBlue_id}
                >
                  {chickens.map((chicken, index) => {
                    return (
                      <option key={index} value={chicken.id}>
                        {chicken.name}
                      </option>
                    );
                  })}
                </Select>
              </GridItem>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                <AddIcon cursor="pointer" />
              </GridItem>
            </Grid>

            <Grid marginBottom="10px" templateColumns="repeat(5, 1fr)" gap={6}>
              <GridItem colSpan={1} />
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Thời gian live
              </GridItem>
              <GridItem colSpan={2}>
                <Input
                  type="datetime-local"
                  value={matchToEdit.timeLive?.substring(0, 19)}
                  onChange={e =>
                    setMatchToEdit({
                      ...matchToEdit,
                      timeLive: e.target.value,
                    })
                  }
                />
              </GridItem>
              {/* <GridItem colSpan={1}>
                <Input
                  type="date"
                  value={new Date(matchToEdit.d_live)}
                  onChange={value => (matchToEdit.d_live = value)}
                />
              </GridItem> */}
              <GridItem colSpan={1} />
            </Grid>

            <Grid marginBottom="10px" templateColumns="repeat(5, 1fr)" gap={6}>
              <GridItem colSpan={1} />
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Kênh
              </GridItem>
              <GridItem colSpan={2}>
                <Select
                  placeholder="Chọn kênh"
                  onChange={e => {
                    // console.log(e.target.value);
                    setMatchToEdit({
                      ...matchToEdit,
                      channel_id: parseInt(e.target.value),
                    });
                  }}
                  value={matchToEdit.channel_id}
                >
                  {channels.map((channel, index) => {
                    return (
                      <option key={index} value={channel.id}>
                        {channel.name}
                      </option>
                    );
                  })}
                </Select>
              </GridItem>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                <AddIcon cursor="pointer" />
              </GridItem>
            </Grid>
          </Box>

          <Box width="100%" position="relative">
            {matchToEdit.live !== "LIVE" && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  background: "#fff",
                  cursor: "not-allowed",
                  opacity: "0.6",
                  zIndex: "1",
                }}
              ></div>
            )}
            <Text fontSize="12px" fontWeight="semibold">
              II. Cấu hình thông số trận đấu
            </Text>

            <Grid marginBottom="10px" templateColumns="repeat(5, 1fr)" gap={6}>
              <GridItem colSpan={1} />
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Trạng thái hiệp
              </GridItem>
              <GridItem colSpan={2}>
                <Select
                  placeholder="Chọn hiệp"
                  onChange={e => {
                    setMatchToEdit({
                      ...matchToEdit,
                      match: parseInt(e.target.value),
                    });
                    // console.log(matchToEdit);
                  }}
                  value={matchToEdit.match}
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((match, index) => {
                    return (
                      <option key={index} value={match}>
                        {`Hiệp ${match}`}
                      </option>
                    );
                  })}
                </Select>
              </GridItem>
              <GridItem colSpan={1} />
            </Grid>
          </Box>
          <Grid
            marginBottom="10px"
            templateColumns="repeat(5, 1fr)"
            gap={6}
            position="relative"
          >
            {((matchToEdit.live === "ENDED" && match.status) ||
              matchToEdit.live !== "ENDED") && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  background: "#fff",
                  cursor: "not-allowed",
                  opacity: "0.6",
                  zIndex: "1",
                }}
              ></div>
            )}
            <GridItem colSpan={1} />
            <GridItem
              style={{ display: "flex", alignItems: "center" }}
              colSpan={1}
            >
              Đội đỏ
            </GridItem>
            <GridItem colSpan={1}>
              <Select
                placeholder="Chọn kết quả"
                onChange={e => {
                  // console.log(e.target.value);
                  setMatchToEdit({
                    ...matchToEdit,
                    status: e.target.value,
                  });
                }}
                value={matchToEdit.status}
              >
                {matchStatuses.map((status, index) => {
                  return (
                    <option key={index} value={status.key}>
                      {status.name}
                    </option>
                  );
                })}
              </Select>
            </GridItem>
            <GridItem
              style={{ display: "flex", alignItems: "center" }}
              colSpan={1}
            >
              Đội xanh
            </GridItem>
            <GridItem colSpan={1} />
          </Grid>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={onSave}
            isDisabled={match.live === "ENDED" && match.status}
          >
            {loading && <Spinner />}
            Lưu
          </Button>
          <Button
            variant="ghost"
            onClick={() => {
              setMatchToEdit({
                ...match,
              });
              setIsUpdate(false);
              onClose();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
