import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import UploadAndPreviewImage from "./UploadAndPreviewImage";
import {
  apiGetImagesDefault,
  apiUpdateChicken,
  apiUploadImage,
} from "../../api/chickens.api";
import MatchPercent from "./MatchPercent";

export default function EditInfoComponent({
  chicken,
  onOkEdit,
  triggerSave,
  isHide,
}) {
  const [loading, setLoading] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [imagesDefaultList, setImagesDefaultList] = useState([]);
  const [isUploadImageSelected, setIsUploadImageSelected] = useState(false);
  const [uploadImage, setUploadImage] = useState(null);
  const [chickenToEdit, setChickenToEdit] = useState({});
  const [previewImage, setPreviewImage] = useState("");
  const formRules = {
    image: [
      {
        key: "required",
        message: "Vui lòng chọn ảnh.",
        validate: value => !value,
      },
    ],
    name: [
      {
        key: "required",
        message: "Vui lòng điền tên gà.",
        validate: value => !value,
      },
      {
        key: "maxLength",
        message: "Tên gà không được quá 100 ký tự.",
        validate: value => value && value.length > 100,
      },
    ],
    owner: [
      {
        key: "required",
        message: "Vui lòng điền tên chủ sở hữu.",
        validate: value => !value,
      },
      {
        key: "maxLength",
        message: "Tên chủ sở hữu không được quá 100 ký tự.",
        validate: value => value && value.length > 100,
      },
    ],
    weight: [
      {
        key: "required",
        message: "Vui lòng điền cân nặng.",
        validate: value => !value,
      },
    ],
    numberOfLoses: [
      {
        key: "required",
        message: "Vui lòng điền số trận thua.",
        validate: value => !value && value !== 0,
      },
    ],
    numberOfWins: [
      {
        key: "required",
        message: "Vui lòng điền số trận thắng.",
        validate: value => !value && value !== 0,
      },
    ],
    numberOfDraws: [
      {
        key: "required",
        message: "Vui lòng điền số trận hoà.",
        validate: value => !value && value !== 0,
      },
    ],
    origin: [
      {
        key: "required",
        message: "Vui lòng điền xuất xứ.",
        validate: value => !value,
      },
      {
        key: "maxLength",
        message: "Xuất xứ không được quá 100 ký tự.",
        validate: value => value && value.length > 100,
      },
    ],
  };
  const [errorForm, setErrorForm] = useState({});
  const toast = useToast();

  useEffect(() => {
    onSave();
  }, [isSave]);
  useEffect(() => {
    if (triggerSave) {
      setIsSave(true);
    }
  }, [triggerSave]);
  useEffect(() => {
    onGetImagesDefault();
  }, []);
  useEffect(() => {
    console.log(chicken);
    if (chicken.image && !imagesDefaultList.includes(chicken.image)) {
      setIsUploadImageSelected(true);
    }
    if (chicken && Object.keys(chicken).length > 0) {
      const temp = {};
      const formRulesKeys = Object.keys(formRules); // ['name', 'owner', 'weight', 'numberOfLoses', 'numberOfWins', 'numberOfDraws', 'origin']
      const currentChickenKeys = Object.keys(chicken);
      formRulesKeys.forEach(key => {
        if (currentChickenKeys.includes(key)) {
          temp[key] = chicken[key];
        } else {
          temp[key] = "";
        }
      });
      setChickenToEdit({ ...temp });
      setPreviewImage(
        `${process.env.REACT_BASE_IMG_URL}/uploads/images/${chicken.image}`
      );
    }
  }, [chicken]);
  useEffect(() => {
    validateForm();
    console.log("chickenToEdit", chickenToEdit.image);
    // depend on chickenToEdit.image, set value for previewImage
  }, [chickenToEdit]);
  const DataURIToBlob = dataURI => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };
  const afterUpdateChicken = async () => {
    if (isUploadImageSelected && uploadImage) {
      console.log("afterUpdateChicken");
      const newChickenId = chicken._id;
      const formData = new FormData();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      console.log(typeof chickenToEdit.image);
      formData.append("image", DataURIToBlob(chickenToEdit.image));
      await apiUploadImage(newChickenId, formData, config)
        .then(
          response => {
            console.log(response);
            return true;
          },
          error => {
            console.log(error);
            toast({
              title: "Đã xảy ra lỗi khi cập nhật ảnh gà!",
              position: "top-right",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            return false;
          }
        )
        .then(response => {
          toast({
            title: "Cập nhật gà thành công!",
            position: "top-right",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setLoading(false);
          onOkEdit();
          setIsSave(false);
          setChickenToEdit({ ...chicken });
        });
    }
  };
  const onSave = async () => {
    console.log("isSave", isSave);
    console.log("!validateForm", !validateForm());
    if (isSave && !validateForm()) {
      setLoading(true);
      const newChicken = { ...chickenToEdit };
      newChicken.weight = parseInt(newChicken.weight);
      console.log(isUploadImageSelected);
      if (
        (isUploadImageSelected && uploadImage) ||
        chickenToEdit.image === chicken.image
      )
        delete newChicken.image;
      await apiUpdateChicken(chicken._id, newChicken).then(
        response => {
          if (
            !isUploadImageSelected ||
            (isUploadImageSelected && !uploadImage)
          ) {
            toast({
              title: "Cập nhật gà thành công!",
              position: "top-right",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            setLoading(false);
            onOkEdit();
            return true;
          }
          afterUpdateChicken();
          return true;
        },
        error => {
          console.log(error);
          toast({
            title: error?.response?.data?.message || "Đã xảy ra lỗi.",
            position: "top-right",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          setIsSave(false);
          setLoading(false);
        }
      );
    } else {
      setIsSave(false);
    }
  };
  const isError = (field, value) => {
    if (Object.keys(formRules).includes(field)) {
      const rules = formRules[field];
      const error = rules.find(rule => rule.validate(value));
      setErrorForm({
        ...errorForm,
        [field]: error,
      });
      return error;
    }
  };

  const onGetImagesDefault = async () => {
    await apiGetImagesDefault().then(
      response => {
        console.log(response.data.data);
        const data =
          response.data.data.length > 5
            ? response.data.data.slice(0, 5)
            : response.data.data
            ? response.data.data
            : [];
        if (chicken.image && !data.includes(chicken.image)) {
          setIsUploadImageSelected(true);
        }
        setImagesDefaultList(data);
      },
      error => {
        console.log(error);
      }
    );
  };

  const validateForm = () => {
    let isErr = false;
    const errorList = {};
    for (const field in formRules) {
      const error = isError(field, chickenToEdit[field]);
      errorList[field] = error;
      if (error) {
        isErr = true;
      }
    }
    setErrorForm(errorList);
    return isErr;
  };

  useEffect(() => {
    if (isHide) {
      setChickenToEdit({ ...chicken });
      setIsUploadImageSelected(!imagesDefaultList.includes(chicken.image));
      setUploadImage(null);
    }
  }, [isHide]);
  return (
    <>
      {!isHide && (
        <Flex
          style={{
            alignItems: "center",
            gap: "30px",
            marginTop: "20px",
          }}
        >
          <Box style={{ width: "400px", padding: "50px" }}>
            <Flex
              align="center"
              justify="center"
              style={{ marginBottom: "20px", width: "100%", gap: "10px" }}
            >
              <img
                src={previewImage}
                style={{
                  width: "200px",
                  height: "200px",
                  borderRadius: "50%",
                  border: "2px solid #f2f2f2",
                }}
              />
            </Flex>
            <Grid marginBottom="10px" templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem colSpan={3}>
                <FormControl isInvalid={errorForm.image}>
                  <Box>
                    <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                      {imagesDefaultList.map((item, index) => {
                        return (
                          <GridItem colSpan={1} key={index}>
                            <img
                              style={{
                                width: "100%",
                                aspectRatio: "1/1",
                                objectFit: "cover",
                                cursor: "pointer",
                                borderRadius: "50%",
                                border:
                                  chickenToEdit.image === item
                                    ? "3px solid orange"
                                    : "",
                              }}
                              src={`${process.env.REACT_BASE_IMG_URL}/uploads/images/${item}`}
                              onClick={() => {
                                setIsUploadImageSelected(false);
                                setChickenToEdit({
                                  ...chickenToEdit,
                                  image: item,
                                });
                              }}
                            />
                          </GridItem>
                        );
                      })}
                      <GridItem colSpan={1}>
                        <UploadAndPreviewImage
                          image={
                            uploadImage
                              ? uploadImage
                              : imagesDefaultList.includes(chicken.image)
                              ? ""
                              : chicken.image
                          }
                          isSelected={isUploadImageSelected}
                          onChangeImage={image => {
                            // image as a base64 string
                            // let img = URL.createObjectURL(image);
                            setChickenToEdit({
                              ...chickenToEdit,
                              image,
                            });
                            setUploadImage(image);
                            setIsUploadImageSelected(true);
                          }}
                        />
                      </GridItem>
                    </Grid>
                  </Box>
                  {errorForm.image && (
                    <FormErrorMessage>
                      {errorForm.image.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
            </Grid>
          </Box>
          <Box style={{ width: "calc(100% - 400px)" }}>
            <Grid marginBottom="10px" templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Tên gà <Text color="red">*</Text>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl isInvalid={errorForm.name}>
                  <Input
                    value={chickenToEdit.name}
                    onChange={e =>
                      setChickenToEdit({
                        ...chickenToEdit,
                        name: e.target.value,
                      })
                    }
                  />
                  {errorForm.name && (
                    <FormErrorMessage>
                      {errorForm.name.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
            </Grid>
            <Grid marginBottom="10px" templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Chủ sở hữu <Text color="red">*</Text>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl isInvalid={errorForm.owner}>
                  <Input
                    value={chickenToEdit.owner}
                    onChange={e =>
                      setChickenToEdit({
                        ...chickenToEdit,
                        owner: e.target.value,
                      })
                    }
                  />
                  {errorForm.owner && (
                    <FormErrorMessage>
                      {errorForm.owner.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
            </Grid>
            <Grid marginBottom="10px" templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Cân nặng {"(gram)"} <Text color="red">*</Text>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl isInvalid={errorForm.weight}>
                  <NumberInput
                    min={0}
                    max={10000}
                    value={chickenToEdit.weight}
                    onChange={value =>
                      setChickenToEdit({ ...chickenToEdit, weight: value })
                    }
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>

                  {errorForm.weight && (
                    <FormErrorMessage>
                      {errorForm.weight.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
            </Grid>
            <Grid marginBottom="10px" templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Xuất xứ <Text color="red">*</Text>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl isInvalid={errorForm.origin}>
                  <Input
                    value={chickenToEdit.origin}
                    onChange={e =>
                      setChickenToEdit({
                        ...chickenToEdit,
                        origin: e.target.value,
                      })
                    }
                  />
                  {errorForm.origin && (
                    <FormErrorMessage>
                      {errorForm.origin.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
            </Grid>
            <Grid
              marginBottom="15px"
              marginTop="5px"
              templateColumns="repeat(3, 1fr)"
              gap={6}
            >
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Tổng số trận đấu
              </GridItem>
              {chickenToEdit.numberOfWins +
                chickenToEdit.numberOfLoses +
                chickenToEdit.numberOfDraws}
            </Grid>
            <Grid marginBottom="10px" templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem colSpan={1} />
              <GridItem colSpan={1} display={"flex"}>
                Thắng <Text color="red">*</Text>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl isInvalid={errorForm.numberOfWins}>
                  <NumberInput
                    min={0}
                    max={10000}
                    value={chickenToEdit.numberOfWins}
                    onChange={value =>
                      setChickenToEdit({
                        ...chickenToEdit,
                        numberOfWins: value ? parseInt(value) : 0,
                      })
                    }
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>

                  {errorForm.numberOfWins && (
                    <FormErrorMessage>
                      {errorForm.numberOfWins.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
            </Grid>
            <Grid marginBottom="10px" templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem colSpan={1} />
              <GridItem colSpan={1} display={"flex"}>
                Hoà <Text color="red">*</Text>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl isInvalid={errorForm.numberOfDraws}>
                  <NumberInput
                    min={0}
                    max={10000}
                    value={chickenToEdit.numberOfDraws}
                    onChange={value =>
                      setChickenToEdit({
                        ...chickenToEdit,
                        numberOfDraws: value ? parseInt(value) : 0,
                      })
                    }
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>

                  {errorForm.numberOfDraws && (
                    <FormErrorMessage>
                      {errorForm.numberOfDraws.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
            </Grid>
            <Grid marginBottom="10px" templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem colSpan={1} />
              <GridItem colSpan={1} display={"flex"}>
                Thua <Text color="red">*</Text>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl isInvalid={errorForm.numberOfLoses}>
                  <NumberInput
                    min={0}
                    max={10000}
                    value={chickenToEdit.numberOfLoses}
                    onChange={value =>
                      setChickenToEdit({
                        ...chickenToEdit,
                        numberOfLoses: value ? parseInt(value) : 0,
                      })
                    }
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
              </GridItem>
            </Grid>
            <Grid
              style={{ marginBottom: "10px" }}
              templateColumns="repeat(3, 1fr)"
              gap={6}
            >
              <GridItem colSpan={3}>
                <MatchPercent
                  win={chicken.numberOfWins}
                  lose={chicken.numberOfLoses}
                  draw={chicken.numberOfDraws}
                />
              </GridItem>
            </Grid>
          </Box>
        </Flex>
      )}
    </>
  );
}
