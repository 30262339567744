import React from "react";
import { useNavigate } from "react-router-dom";
import { selectLocalPeer, useHMSStore } from "@100mslive/react-sdk";
import {
  Flex,
  StyledVideoTile,
  Text,
  textEllipsis,
  useBorderAudioLevel,
  useTheme,
} from "@100mslive/roomkit-react";
import LiveStreamIcon from "../../../images/LiveStreamIcon";

const RoomList = ({ data, error }) => {
  const navigate = useNavigate();
  const localPeer = useHMSStore(selectLocalPeer);
  const borderAudioRef = useBorderAudioLevel(localPeer?.audioTrack);
  // const isVideoOn = useHMSStore(selectIsLocalVideoEnabled);
  // const mirrorLocalVideo = useUISettings(UI_SETTINGS.mirrorLocalVideo);
  // const trackSelector = selectVideoTrackByID(localPeer?.videoTrack);
  // const track = useHMSStore(trackSelector);

  const redirectToJoinPage = () => {
    navigate("/streaming/" + data.id + "/viewer-realtime");
  };

  // const onJoin = async e => {
  //   e.preventDefault();
  //   const { userName = "", roomCode = "" } = inputValues;

  //   // use room code to fetch auth token
  //   // const authToken = await hmsActions.getAuthTokenByRoomCode({ roomCode });

  //   try {
  //     // await hmsActions.join({ userName, authToken });
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  const {
    aspectRatio: { width, height },
  } = useTheme();

  return (
    <StyledVideoTile.Container
      css={{
        bg: "$surface_default",
        aspectRatio: width / height,
        width: "unset",
        height: "min(360px, 60vh)",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "end",
        px: "$10",
        py: "$10",
        mt: "$12",
        "@sm": {
          height: "unset",
          width: "min(360px, 100%)",
          maxWidth: "100%",
        },
      }}
      ref={borderAudioRef}
    >
      <Flex
        align="center"
        justify="center"
        css={{
          "@sm": { width: "100%" },
          flexDirection: "column",
          width: "100%",
          height: "100%",
          cursor: "pointer",
        }}
        onClick={redirectToJoinPage}
      >
        <LiveStreamIcon />
      </Flex>
      <Text css={{ ...textEllipsis("100%") }} variant="body2">
        ROOM ID: {data?.id}
      </Text>
      <Text css={{ ...textEllipsis("100%") }} variant="body2">
        NAME: {data?.name}
      </Text>
      <Text css={{ ...textEllipsis("100%") }} variant="body2">
        STATUS: {data?.status ? "Active" : "Disabled"}
      </Text>
    </StyledVideoTile.Container>
  );
};

export default RoomList;
