import {
  Box,
  Button,
  Flex,
  ListIcon,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import PageContent from "../layouts/core/PageContent";
import { useEffect, useState } from "react";
import TextSearch from "../components/match/TextSearch";
import BaseTable from "../components/BaseTable";
import Pagination from "../components/match/Pagination";
import ModalAddChicken from "../components/chicken/ModalAddChicken";
import { apiDeleteChicken, apiGetChickens } from "../api/chickens.api";
import { useNavigate } from "react-router-dom";

export default function ManageChicken() {
  //   const headerIcon = <ListIcon fontSize="xl" />;
  const [chickenList, setChickenList] = useState([]);
  const [loadingGetListChicken, setLoadingGetListChicken] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [timerSearch, setTimerSearch] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [changePage, setChangePage] = useState(false);
  const [selectedChicken, setSelectedChicken] = useState("");
  const navigate = useNavigate();
  const toast = useToast();
  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();

  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const headers = [
    {
      key: "idReadable",
      valueField: "idReadable",
      label: "ID",
      sortable: false,
      width: "100px",
    },
    {
      key: "name",
      valueField: "name",
      label: "Tên gà",
      sortable: false,
      width: "300px",
      isShowTooltip: true,
      type: {
        type: "avatar_text_with_tooltip",
      },
      link: "manage-team",
    },

    {
      key: "owner",
      valueField: "owner",
      label: "Chủ sở hữu",
      sortable: false,
      link: "manage-team",
      // width: "200px",
    },
    {
      key: "weight",
      valueField: "weight",
      label: "Cân nặng (gram)",
      sortable: false,
      //   width: "200px",
      // type: {
      //   type: "multi_line_text",
      //   maxLine: 3,
      // },
    },
    {
      key: "action",
      valueField: "action",
      label: "Hành động",
      sortable: false,
      width: "100px",
      type: {
        type: "action_btns",
        btns: ["edit", "delete"],
      },
    },
  ];
  const getListChicken = async () => {
    setLoadingGetListChicken(true);
    const params = {
      page: page - 1,
      limit,
      // status: "ACTIVATED",
    };
    if (keyword) {
      params.keyword = keyword;
    }
    await apiGetChickens(
      {},
      {
        params,
      }
    ).then(
      response => {
        setLoadingGetListChicken(false);
        setChickenList(response.data.data);
        setTotal(response.data.total);
        setTotalPages(getTotalPages(response.data.total));
        setChangePage(false);
        return response.data.data;
      },
      error => {
        setChangePage(false);
        setLoadingGetListChicken(false);
        return false;
      }
    );
  };
  const deleteChicken = async id => {
    setLoadingDelete(true);
    await apiDeleteChicken(id).then(
      response => {
        setLoadingDelete(false);
        toast({
          title: "Xóa gà thành công",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        getListChicken();

        // dataMatch();
        return true;
      },
      error => {
        setLoadingDelete(false);
        toast({
          title: "Đã có lỗi xảy ra",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        return false;
      }
    );
  };
  const getTotalPages = total => {
    return Math.ceil(total / limit);
  };

  useEffect(() => {
    getListChicken();
  }, []);

  useEffect(() => {
    console.log("keyword", keyword);
    if (timerSearch) {
      clearTimeout(timerSearch);
    }
    setTimerSearch(
      setTimeout(() => {
        setPage(1);
        setChangePage(true);
      }, 500)
    );
  }, [keyword]);
  // useEffect(() => {
  //   console.log("change page", page);
  //   if (changePage) getListChicken();
  // }, [page]);

  useEffect(() => {
    if (changePage) getListChicken();
  }, [changePage]);
  return (
    <PageContent title="Quản lý gà">
      <Flex justify="end" align="center">
        <TextSearch
          placeholder="Tìm kiếm theo tên gà hoặc chủ sở hữu"
          keyword={keyword}
          onChangeKeyword={e => {
            setKeyword(e.target.value);
          }}
        />
        <Button
          onClick={() => {
            console.log("click");
            onOpenAdd();
          }}
          colorScheme="teal"
          backgroundImage="linear-gradient(to right, #184E68 , #57CA85)"
        >
          Thêm mới
        </Button>
      </Flex>
      <Box
        style={{
          marginTop: "20px",
          width: "100%",
          height: "100%",
        }}
      >
        <BaseTable
          loadingDelete={loadingDelete}
          headers={headers}
          loading={loadingGetListChicken}
          data={chickenList}
          onEditClick={(e, index) => {
            // console.log("edit click", index);
            // setSelectedChicken(chickenList[index]._id);
            // onOpenEdit();
            //   setSelectedMatch(data[index]);
            navigate(`/manage-team/${chickenList[index]._id}?isEdit=true`);
          }}
          onDeleteClick={(e, index) => {
            console.log("delete click", index);
            deleteChicken(chickenList[index]._id);
          }}
        />
        {chickenList.length > 0 && !loadingGetListChicken && (
          <Pagination
            onChangePage={newPage => {
              console.log(newPage, page);
              if (newPage !== page) {
                setPage(newPage);
                setChangePage(true);
              }
            }}
            page={page}
            totalPages={totalPages}
          />
        )}
        <ModalAddChicken
          isOpen={isOpenAdd}
          onOpen={onOpenAdd}
          onClose={onCloseAdd}
          onOkAdd={() => {
            getListChicken();
          }}
        />
      </Box>
    </PageContent>
  );
}
