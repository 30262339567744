// Login.js
import { useEffect, useState } from "react";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import {
  Button,
  ChakraProvider,
  FormControl,
  FormErrorMessage,
  Input,
  Link as ChakraLink,
  useToast,
  IconButton,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { apiLogin } from "../../api/auth.api";
import { apiProfile } from "../../api/profile.api";
import { AuthFormWrapper } from "./AuthFormWrapper";
import { AuthWrapper } from "./AuthWrapper";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Logo } from "../../layouts/core/Logo";
const LoginForm = ({ handleEmailNotVerify }) => {
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate(); // Tạo đối tượng history
  const validateEmail = () => {
    // Simple email validation for illustration purposes
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Địa chỉ email không hợp lệ");
    } else {
      setEmailError("");
    }
  };
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    // Validate email before attempting login
    validateEmail();
    if (!emailError) {
      // Gọi API để đăng nhập và nhận refresh token
      const payload = { email, password };
      setIsLoading(true);
      try {
        const response = await apiLogin(payload);
        const { access_token, refresh_token } = response.data;
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("refresh_token", refresh_token);
        const res = await apiProfile();
        localStorage.setItem("profile", JSON.stringify(res.data));
        navigate("/");
      } catch (error) {
        // Xử lý lỗi ở đây
        toast({
          title: "Đăng nhập thất bại",
          description: "Vui lòng kiểm tra lại thông tin đăng nhập.",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <AuthWrapper>
      <AuthFormWrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ transform: "scale(1.5)" }}>
            <Logo />
          </div>
          <h1
            style={{
              textAlign: "center",
              fontSize: "26px",
              fontWeight: "bold",
              marginBottom: "10px",
              marginTop: "30px",
              color: "#000",
            }}
          >
            Chào mừng đến với GaViet
          </h1>
        </div>
        <FormControl isRequired isInvalid={!!emailError}>
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            onBlur={validateEmail}
            color="black"
          />
          <FormErrorMessage>{emailError}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired>
          <InputGroup>
            <Input
              type={showPassword ? "text" : "password"}
              placeholder="Mật khẩu"
              value={password}
              onChange={e => setPassword(e.target.value)}
              color="black"
            />
            <InputRightElement width="3rem">
              <IconButton
                color="#293038"
                h="1.75rem"
                size="sm"
                onClick={handleTogglePassword}
                icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Button
          isLoading={isLoading}
          colorScheme="teal"
          onClick={handleLogin}
          style={{
            background:
              "linear-gradient(90deg, rgba(24,78,104,1) 25%, rgba(87,202,133,1) 100%)",
          }}
        >
          Đăng nhập
        </Button>
      </AuthFormWrapper>
    </AuthWrapper>
  );
};

export default LoginForm;
