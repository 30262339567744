import {
  ChevronLeftIcon,
  ChevronRightIcon,
  NextIcon,
  PrevIcon,
} from "@100mslive/react-icons";
import { Button, Flex, Input } from "@chakra-ui/react";

export default function RoundSelect({ currentRound, onChangeRound }) {
  const roundList = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <Flex align="center" justify="center">
      <Button
        onClick={() => {
          const index = roundList.indexOf(currentRound);
          if (index > 0) {
            onChangeRound(roundList[index - 1]);
          }
        }}
      >
        <ChevronLeftIcon />
      </Button>
      <Flex
        align="center"
        justify="center"
        width="100%"
        height="40px"
        borderTop="1px solid #f2f2f2"
        borderBottom="1px solid #f2f2f2"
      >
        {`Hiệp ${roundList.indexOf(currentRound) >= 0 ? currentRound : 1}`}
      </Flex>
      <Button
        onClick={() => {
          const index = roundList.indexOf(currentRound);
          if (index < roundList.length - 1) {
            onChangeRound(roundList[index + 1]);
          }
        }}
      >
        <ChevronRightIcon />
      </Button>
    </Flex>
  );
}
