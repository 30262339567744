import { useState, useEffect } from "react";
import { apiAddUser, apiEditUser, apiGetUsers } from "../../api/users.api";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";

import { USER_STATUSES } from "../../constant/user_statuses";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export default function ModalEditUser({
  user,
  isOpen,
  onOpen,
  onClose,
  onOkEdit,
}) {
  const [loading, setLoading] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [isDirty, setIsDirty] = useState({});
  const [userToEdit, setUserToEdit] = useState({});
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowPasswordConfirm, setIsShowPasswordConfirm] = useState(false);
  const formRules = {
    username: [
      {
        key: "required",
        message: "Vui lòng điền tên người dùng.",
        validate: value => !value,
      },
      {
        key: "maxLength",
        message: "Tên người dùng không được quá 100 ký tự.",
        validate: value => value && value.length > 100,
      },
      {
        key: "minLength",
        message: "Tên người dùng tối thiểu 6 ký tự.",
        validate: value => value && value.length < 6,
      },
    ],
    email: [
      {
        key: "required",
        message: "Vui lòng điền email.",
        validate: value => !value,
      },
      {
        key: "format",
        message: "Email phải đúng định dạng.",
        validate: value =>
          value && !value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i),
      },
    ],
    password: [
      //   {
      //     key: "required",
      //     message: "Vui lòng điền mật khẩu.",
      //     validate: value => !value,
      //   },
      {
        key: "format",
        message: "Mật khẩu tối thiểu 7 ký tự.",
        validate: value => value && value.length < 7,
      },
    ],
    confirmpassword: [
      //   {
      //     key: "required",
      //     message: "Vui lòng điền mật khẩu.",
      //     validate: value => !value,
      //   },
      {
        key: "equalPassword",
        message: "Mật khẩu không trùng khớp.",
        validate: value => value !== userToEdit.password,
      },
    ],
    // description: [
    //   {
    //     key: "required",
    //     message: "Vui lòng điền mô tả.",
    //     validate: value => !value,
    //   },
    //   {
    //     key: "maxLength",
    //     message: "Mô tả không được quá 500 ký tự.",
    //     validate: value => value && value.length > 500,
    //   },
    // ],
    status: [
      {
        key: "required",
        message: "Vui lòng chọn trạng thái.",
        validate: value => !value,
      },
    ],
  };
  const [errorForm, setErrorForm] = useState({});
  const toast = useToast();

  useEffect(() => {
    onSave();
  }, [isSave]);
  useEffect(() => {
    setUserToEdit({ ...user });
  }, [user]);
  useEffect(() => {
    validateForm();
    const tempDirty = {};
    Object.keys(formRules).forEach(field => {
      tempDirty[field] = userToEdit[field] || isDirty[field] ? true : false;
    });
    setIsDirty(tempDirty);
  }, [userToEdit]);
  const onSave = async () => {
    console.log(!validateForm());
    console.log(errorForm);
    if (isSave && !validateForm()) {
      setLoading(true);
      const payload = {};
      if (userToEdit.password) payload.password = userToEdit.password;
      if (userToEdit.status !== user.status) payload.status = userToEdit.status;
      if (userToEdit.username !== user.username)
        payload.username = userToEdit.username;
      if (userToEdit.email !== user.email) payload.email = userToEdit.email;

      if (Object.keys(payload).length > 0) {
        await apiEditUser(user._id, payload).then(
          response => {
            toast({
              title: "Sửa thành công.",
              description: "Sửa thành công.",
              status: "success",
              duration: 3000,
              position: "top-right",
              isClosable: true,
            });
            setLoading(false);
            onOkEdit();
            setIsSave(false);
            setUserToEdit({});
            setIsDirty({});
            onClose();
            setIsShowPassword(false);
            setIsShowPasswordConfirm(false);
            return true;
          },
          error => {
            console.log(error);
            toast({
              title: error?.response?.data?.message || "Đã xảy ra lỗi.",
              position: "top-right",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            setIsSave(false);
            setLoading(false);
          }
        );
      } else {
        toast({
          title: "Không có thông tin thay đổi.",
          position: "top-right",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsSave(false);
        setLoading(false);
      }
    } else {
      setIsSave(false);
    }
  };
  const isError = (field, value) => {
    if (Object.keys(formRules).includes(field)) {
      const rules = formRules[field];
      const error = rules.find(rule => rule.validate(value));
      setErrorForm({
        ...errorForm,
        [field]: error,
      });
      return error;
    }
  };

  const validateForm = () => {
    let isErr = false;
    const errorList = {};
    for (const field in formRules) {
      const error = isError(field, userToEdit[field]);
      errorList[field] = error;
      if (error) {
        isErr = true;
      }
    }
    setErrorForm(errorList);
    return isErr;
  };
  return (
    <Modal
      isCentered={true}
      size="full"
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={() => {
        setUserToEdit({ ...user });
        setIsDirty({});
        setIsSave(false);
        setIsShowPassword(false);
        setIsShowPasswordConfirm(false);
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent
        alignItems="center"
        width="600px"
        maxHeight="80vh!important"
        minHeight="unset!important"
        paddingLeft="80px"
        paddingRight="80px"
      >
        <ModalHeader>
          <Text textAlign="center" fontSize="18px">
            Sửa thông tin
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody width="100%">
          <Box width="100%" position="relative">
            <Grid marginBottom="10px" templateColumns="repeat(7, 1fr)" gap={6}>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={3}
              >
                Tên người dùng <Text color="red">*</Text>
              </GridItem>
              <GridItem colSpan={4}>
                <FormControl isInvalid={errorForm.username}>
                  <Input
                    value={userToEdit.username}
                    onChange={e =>
                      setUserToEdit({ ...userToEdit, username: e.target.value })
                    }
                  />
                  {errorForm.username && (
                    <FormErrorMessage>
                      {errorForm.username.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
            </Grid>
            <Grid marginBottom="10px" templateColumns="repeat(7, 1fr)" gap={6}>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={3}
              >
                Email <Text color="red">*</Text>
              </GridItem>
              <GridItem colSpan={4}>
                <FormControl isInvalid={errorForm.email}>
                  <Input
                    value={userToEdit.email}
                    onChange={e =>
                      setUserToEdit({ ...userToEdit, email: e.target.value })
                    }
                  />
                  {errorForm.email && (
                    <FormErrorMessage>
                      {errorForm.email.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
            </Grid>
            <Grid marginBottom="10px" templateColumns="repeat(7, 1fr)" gap={6}>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={3}
              >
                Mật khẩu mới
              </GridItem>
              <GridItem colSpan={4}>
                <FormControl isInvalid={errorForm.password && isDirty.password}>
                  <InputGroup>
                    <Input
                      autocomplete="off"
                      type={isShowPassword ? "text" : "password"}
                      value={userToEdit.password}
                      onChange={e =>
                        setUserToEdit({
                          ...userToEdit,
                          password: e.target.value,
                        })
                      }
                    />
                    <InputRightElement>
                      {isShowPassword && (
                        <FaEye onClick={() => setIsShowPassword(false)} />
                      )}
                      {!isShowPassword && (
                        <FaEyeSlash onClick={() => setIsShowPassword(true)} />
                      )}
                    </InputRightElement>
                  </InputGroup>
                  {errorForm.password && (
                    <FormErrorMessage>
                      {errorForm.password.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
            </Grid>
            <Grid marginBottom="10px" templateColumns="repeat(7, 1fr)" gap={6}>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={3}
              >
                Xác nhận mật khẩu
              </GridItem>
              <GridItem colSpan={4}>
                <FormControl
                  isInvalid={
                    errorForm.confirmpassword && isDirty.confirmpassword
                  }
                >
                  <InputGroup>
                    <Input
                      autocomplete="off"
                      type={isShowPasswordConfirm ? "text" : "password"}
                      value={userToEdit.confirmpassword}
                      onChange={e =>
                        setUserToEdit({
                          ...userToEdit,
                          confirmpassword: e.target.value,
                        })
                      }
                    />
                    <InputRightElement>
                      {isShowPasswordConfirm && (
                        <FaEye
                          onClick={() => setIsShowPasswordConfirm(false)}
                        />
                      )}
                      {!isShowPasswordConfirm && (
                        <FaEyeSlash
                          onClick={() => setIsShowPasswordConfirm(true)}
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                  {errorForm.confirmpassword && (
                    <FormErrorMessage>
                      {errorForm.confirmpassword.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
            </Grid>

            <Grid marginBottom="10px" templateColumns="repeat(7, 1fr)" gap={6}>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={3}
              >
                Trạng thái <Text color="red">*</Text>
              </GridItem>
              <GridItem colSpan={4} style={{ position: "relative" }}>
                <FormControl isInvalid={errorForm.status}>
                  <Select
                    placeholder="Chọn trạng thái"
                    onChange={e => {
                      setUserToEdit({
                        ...userToEdit,
                        status: e.target.value,
                      });
                    }}
                    value={userToEdit.status}
                  >
                    {USER_STATUSES?.map((ch, index) => {
                      return (
                        <option key={index} value={ch.value}>
                          {ch.label}
                        </option>
                      );
                    })}
                  </Select>
                  {errorForm.status && (
                    <FormErrorMessage>
                      {errorForm.status.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
            </Grid>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => {
              setIsSave(true);
            }}
          >
            {loading && <Spinner />}
            Lưu
          </Button>
          <Button
            variant="ghost"
            onClick={() => {
              setUserToEdit({ ...user });
              setIsDirty({});
              setIsSave(false);
              setIsShowPassword(false);
              setIsShowPasswordConfirm(false);
              onClose();
            }}
          >
            Huỷ
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
