import {
  Box,
  Button,
  Flex,
  ListIcon,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import PageContent from "../layouts/core/PageContent";
import { useEffect, useState } from "react";
import { apiDeleteChannel, apiGetChannels } from "../api/channels.api";
import TextSearch from "../components/match/TextSearch";
import BaseTable from "../components/BaseTable";
import Pagination from "../components/match/Pagination";
import ModalAddChannel from "../components/channel/ModalAddChannel";
import ModalEditChannel from "../components/channel/ModalEditChannel";
import { set } from "mobx";

export default function ManageChannel() {
  //   const headerIcon = <ListIcon fontSize="xl" />;
  const [channelList, setChannelList] = useState([]);
  const [loadingGetListChannel, setLoadingGetListChannel] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [timerSearch, setTimerSearch] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [changePage, setChangePage] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState({});
  const toast = useToast();
  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();

  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const headers = [
    {
      key: "idReadable",
      valueField: "idReadable",
      label: "ID",
      sortable: false,
      width: "100px",
    },
    {
      key: "name",
      valueField: "name",
      label: "Kênh",
      sortable: false,
      width: "300px",
      isShowTooltip: true,
    },

    {
      key: "cameramanCode",
      valueField: "cameramanCode",
      label: "Link CAM",
      sortable: false,
      // width: "100px",
      type: {
        type: "link_camp_copy",
      },
    },
    {
      key: "description",
      valueField: "description",
      label: "Mô tả",
      sortable: false,
      width: "300px",
      isShowTooltip: true,
      // type: {
      //   type: "multi_line_text",
      //   maxLine: 3,
      // },
    },
    {
      key: "status",
      valueField: "status",
      label: "Trạng thái",
      sortable: false,
      width: "200px",
      type: {
        type: "colored_status",
        statusOf: "channel",
      },
    },
    {
      key: "action",
      valueField: "action",
      label: "Hành động",
      sortable: false,
      width: "100px",
      type: {
        type: "action_btns",
        btns: ["edit", "delete"],
      },
    },
  ];
  const getListChannel = async () => {
    setLoadingGetListChannel(true);
    const params = {
      page: page - 1,
      limit,
      // status: "ACTIVATED",
    };
    if (keyword) {
      params.keyword = keyword;
    }
    await apiGetChannels(
      {},
      {
        params,
      }
    ).then(
      response => {
        setLoadingGetListChannel(false);
        setChannelList(response.data.data);
        setTotal(response.data.total);
        setTotalPages(getTotalPages(response.data.total));
        setChangePage(false);
        return response.data.data;
      },
      error => {
        setChangePage(false);
        setLoadingGetListChannel(false);
        return false;
      }
    );
  };
  const deleteChannel = async id => {
    setLoadingDelete(true);
    await apiDeleteChannel(id).then(
      response => {
        setLoadingDelete(false);
        toast({
          title: "Xóa kênh thành công",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        getListChannel();

        // dataMatch();
        return true;
      },
      error => {
        setLoadingDelete(false);
        toast({
          title: "Đã có lỗi xảy ra",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        return false;
      }
    );
  };
  const getTotalPages = total => {
    return Math.ceil(total / limit);
  };

  useEffect(() => {
    getListChannel();
  }, []);

  useEffect(() => {
    console.log("keyword", keyword);
    if (timerSearch) {
      clearTimeout(timerSearch);
    }
    setTimerSearch(
      setTimeout(() => {
        setPage(1);
        setChangePage(true);
      }, 1000)
    );
  }, [keyword]);
  // useEffect(() => {
  //   console.log("change page", page);
  //   if (changePage) getListChannel();
  // }, [page]);

  useEffect(() => {
    if (changePage) getListChannel();
  }, [changePage]);
  return (
    <PageContent title="Quản lý kênh">
      <Flex justify="end" align="center">
        <TextSearch
          keyword={keyword}
          onChangeKeyword={e => {
            setKeyword(e.target.value);
          }}
        />
        <Button
          onClick={() => {
            console.log("click");
            onOpenAdd();
          }}
          colorScheme="teal"
          backgroundImage="linear-gradient(to right, #184E68 , #57CA85)"
        >
          Thêm mới
        </Button>
      </Flex>
      <Box
        style={{
          marginTop: "20px",
          width: "100%",
          height: "100%",
        }}
      >
        <BaseTable
          loadingDelete={loadingDelete}
          headers={headers}
          loading={loadingGetListChannel}
          data={channelList}
          onEditClick={(e, index) => {
            console.log("edit click", index);
            setSelectedChannel(channelList[index]);
            onOpenEdit();
            //   setSelectedMatch(data[index]);
            //   navigate(`/manage-match/${data[index].id}/edit`);
          }}
          onDeleteClick={(e, index) => {
            console.log("delete click", index);
            deleteChannel(channelList[index]._id);
          }}
        />
        {channelList.length > 0 && !loadingGetListChannel && (
          <Pagination
            onChangePage={newPage => {
              console.log(newPage, page);
              if (newPage !== page) {
                setPage(newPage);
                setChangePage(true);
              }
            }}
            page={page}
            totalPages={totalPages}
          />
        )}
        <ModalAddChannel
          isOpen={isOpenAdd}
          onOpen={onOpenAdd}
          onClose={onCloseAdd}
          onOkAdd={() => {
            getListChannel();
          }}
        />
        <ModalEditChannel
          channel={selectedChannel}
          isOpen={isOpenEdit}
          onOpen={onOpenEdit}
          onClose={onCloseEdit}
          onOkEdit={() => {
            getListChannel();
          }}
        />
      </Box>
    </PageContent>
  );
}
