import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import MatchPercent from "./MatchPercent";

export default function ViewInfoComponent({ info, isHide }) {
  const imageSize = "150px";
  return (
    <>
      {!isHide && (
        <Flex>
          <Flex
            style={{
              minWidth: imageSize,
              width: " 40%",
              minHeight: imageSize,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                width: imageSize,
                height: imageSize,
                borderRadius: "50%",
              }}
              src={`${process.env.REACT_BASE_IMG_URL}/uploads/images/${info.image}`}
            ></img>
          </Flex>
          <Box width={"100%"}>
            <Grid
              style={{ marginBottom: "10px" }}
              templateColumns="repeat(5, 1fr)"
              gap={6}
            >
              <GridItem colSpan={1}></GridItem>
              <GridItem colSpan={1}>Tên gà:</GridItem>
              <GridItem colSpan={2}>{info.name || ""}</GridItem>
              <GridItem colSpan={1}></GridItem>
            </Grid>
            <Grid
              style={{ marginBottom: "10px" }}
              templateColumns="repeat(5, 1fr)"
              gap={6}
            >
              <GridItem colSpan={1}></GridItem>
              <GridItem colSpan={1}>Chủ sở hữu:</GridItem>
              <GridItem colSpan={2}>{info.owner || ""}</GridItem>
              <GridItem colSpan={1}></GridItem>
            </Grid>
            <Grid
              style={{ marginBottom: "10px" }}
              templateColumns="repeat(5, 1fr)"
              gap={6}
            >
              <GridItem colSpan={1}></GridItem>
              <GridItem colSpan={1}>Cân nặng:</GridItem>
              <GridItem colSpan={2}>
                {info.weight || ""} {"(gram)"}
              </GridItem>
              <GridItem colSpan={1}></GridItem>
            </Grid>

            <Grid
              style={{ marginBottom: "10px" }}
              templateColumns="repeat(5, 1fr)"
              gap={6}
            >
              <GridItem colSpan={1}></GridItem>
              <GridItem colSpan={1}>Xuất xứ:</GridItem>
              <GridItem colSpan={2}>{info.origin || ""}</GridItem>
              <GridItem colSpan={1}></GridItem>
            </Grid>
            <Grid
              style={{ marginBottom: "10px" }}
              templateColumns="repeat(5, 1fr)"
              gap={6}
            >
              <GridItem colSpan={1}></GridItem>
              <GridItem colSpan={1}>Tổng số trận đấu:</GridItem>
              <GridItem colSpan={2}>
                {info.numberOfLoses + info.numberOfWins + info.numberOfDraws}
              </GridItem>
              <GridItem colSpan={1}></GridItem>
            </Grid>
            <Grid
              style={{ marginBottom: "10px" }}
              templateColumns="repeat(5, 1fr)"
              gap={6}
            >
              <GridItem colSpan={1}></GridItem>
              <GridItem colSpan={1}>Thắng:</GridItem>
              <GridItem colSpan={1}>{info.numberOfWins}</GridItem>
            </Grid>
            <Grid
              style={{ marginBottom: "10px" }}
              templateColumns="repeat(5, 1fr)"
              gap={6}
            >
              <GridItem colSpan={1}></GridItem>
              <GridItem colSpan={1}>Thua:</GridItem>
              <GridItem colSpan={1}>{info.numberOfLoses}</GridItem>
            </Grid>
            <Grid
              style={{ marginBottom: "10px" }}
              templateColumns="repeat(5, 1fr)"
              gap={6}
            >
              <GridItem colSpan={1}></GridItem>
              <GridItem colSpan={1}>Hòa:</GridItem>
              <GridItem colSpan={1}>{info.numberOfDraws}</GridItem>
            </Grid>
            <Grid
              style={{ marginBottom: "10px" }}
              templateColumns="repeat(5, 1fr)"
              gap={6}
            >
              <GridItem colSpan={1}></GridItem>
              <GridItem colSpan={3}>
                <MatchPercent
                  win={info.numberOfWins}
                  lose={info.numberOfLoses}
                  draw={info.numberOfDraws}
                />
              </GridItem>
              <GridItem colSpan={1}></GridItem>
            </Grid>
          </Box>
        </Flex>
      )}
    </>
  );
}
