import { request } from "./request";

export const apiGetChickens = (data = {}, config = {}) =>
  request("get", "/chicken", data, config);

export const apiGetChicken = (id, config) =>
  request("get", `/chicken/${id}`, {}, config);
export const apiAddChicken = data => request("post", "/chicken", data);
export const apiUpdateChicken = (id, data) =>
  request("patch", `/chicken/${id}`, data);
export const apiDeleteChicken = id => request("delete", `/chicken/${id}`);

export const apiGetImagesDefault = () =>
  request("get", "/chicken/images-defautl");

export const apiUploadImage = (id, data, config) =>
  request("post", `/chicken/${id}/upload`, data, config);

export const apiGetMatchHistory = (url, config) =>
  request("get", `/chicken/${url}`, {}, config);
