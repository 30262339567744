const LiveStreamIcon = () => (
<svg width="150px" height="150px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">

<title/>

<g fill="white" fillRule="evenodd" id="v-1" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">

<g id="live" stroke="white" strokeWidth="1.5" transform="translate(-102.000000, -15.000000)">

<g id="live1" transform="translate(102.000000, 15.000000)">

<g id="live2" transform="translate(2.500000, 5.000000)">

<path d="M0,0.95 C0,0.42532925 0.42532925,0 0.95,0 L14.25,0 C14.774685,0 15.2,0.42532925 15.2,0.95 L15.2,5.225 L19,2.375 L19,13.3 L15.2,10.45 L15.2,14.25 C15.2,14.774685 14.774685,15.2 14.25,15.2 L0.95,15.2 C0.42532925,15.2 0,14.774685 0,14.25 L0,0.95 Z" id="live9"/>

<line id="live3" x1="2.85" x2="2.85" y1="3.8" y2="5.7"/>

<line id="live4" x1="5.225" x2="5.225" y1="2.85" y2="6.65"/>

<line id="live5" x1="7.6" x2="7.6" y1="3.8" y2="5.7"/>

</g>

</g>

</g>

</g>

</svg>
);

export default LiveStreamIcon;