import { FaUser } from "react-icons/fa";
import { FcAdvertising } from "react-icons/fc";
import { GiCharacter, GiChicken } from "react-icons/gi";
import { RiAdvertisementLine } from "react-icons/ri";
import { GameIcon, RadioIcon } from "@100mslive/react-icons";
const defaultSize = "24px";
export const MENU_SIDE_BAR = [
  {
    to: "/manage-match",
    title: "Trận đấu",
    key: "manage-match",
    icon: <GameIcon fontSize={defaultSize} />,
  },
  {
    to: "/manage-channel",
    title: "Kênh",
    key: "manage-channel",
    icon: <RadioIcon fontSize={defaultSize} />,
  },
  {
    to: "/manage-team",
    title: "Gà",
    key: "manage-team",
    icon: <GiChicken fontSize={defaultSize} />,
  },
  {
    to: "/manage-user",
    title: "Người dùng",
    key: "manage-user",
    icon: <GiCharacter fontSize={defaultSize} />,
  },
  {
    to: "/manage-banner",
    title: "Banner",
    key: "manage-banner",
    icon: <RiAdvertisementLine fontSize={defaultSize} />,
  },
];
