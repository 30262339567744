import { Box, Text } from "@chakra-ui/layout";
import React from "react";

export const Logo = ({ style }) => {
  return (
    <Box
      css={{
        height: "100%",
        w: "100%",
        display: "flex",
        alignItems: "center",
        zIndex: 10,
        ...style,
      }}
    >
      <img
        src={require("../../images/logo.png")}
        className="logo"
        alt=""
        srcset=""
        width="60px"
      />
      <Box
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "2px",
          marginLeft: "8px",
        }}
      >
        <Text
          css={{
            color: "#000",
            fontSize: "24px",
            fontWeight: "700",
          }}
        >
          GaViet
        </Text>
        <Text
          css={{
            textTransform: "uppercase",
            color: "#FCFCFD",
            fontSize: "8px",
            fontWeight: "700",
            padding: "4px 8px",
            lineHeight: 1,
            background:
              "linear-gradient(90deg, rgba(24,78,104,1) 25%, rgba(87,202,133,1) 100%)",
          }}
        >
          Trực tiếp đá gà
        </Text>
      </Box>
    </Box>
  );
};
