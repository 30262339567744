import {
  Box,
  Button,
  Flex,
  ListIcon,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import PageContent from "../layouts/core/PageContent";
import { useEffect, useState } from "react";
import { apiDeleteUser, apiGetUsers } from "../api/users.api";
import TextSearch from "../components/match/TextSearch";
import BaseTable from "../components/BaseTable";
import Pagination from "../components/match/Pagination";
import ModalAddUser from "../components/user/ModalAddUser";
import ModalEditUser from "../components/user/ModalEditUser";
import { set } from "mobx";
import FilterByRegisterType from "../components/user/FilterByRegisterType";
import FilterByStatus from "../components/user/FilterByStatus";

export default function ManageUser() {
  //   const headerIcon = <ListIcon fontSize="xl" />;
  const [userList, setUserList] = useState([]);
  const [loadingGetListUser, setLoadingGetListUser] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState(null);
  const [timerSearch, setTimerSearch] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [changePage, setChangePage] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedRegisterType, setSelectedRegisterType] = useState(null);
  const toast = useToast();
  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();

  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const headers = [
    {
      key: "idReadable",
      valueField: "idReadable",
      label: "ID",
      sortable: false,
      width: "100px",
    },
    {
      key: "username",
      valueField: "username",
      label: "Tên tài khoản",
      sortable: false,
      width: "300px",
      isShowTooltip: true,
    },
    {
      key: "email",
      valueField: "email",
      label: "Email",
      sortable: false,
      //   width: "200px",
      //   isShowTooltip: true,
    },
    {
      key: "registerType",
      valueField: "registerType",
      label: "Phương thức đăng ký",
      sortable: false,
      type: {
        type: "register_type",
      },
    },
    {
      key: "createdAt",
      valueField: "createdAt",
      label: "Ngày tạo",
      sortable: true,
      width: "200px",
      sortDirection,
      // isShowTooltip: true,
      type: {
        type: "date_time",
      },
    },
    {
      key: "status",
      valueField: "status",
      label: "Trạng thái",
      sortable: false,
      width: "200px",
      type: {
        type: "colored_status",
        statusOf: "user",
      },
    },
    {
      key: "action",
      valueField: "action",
      label: "Hành động",
      sortable: false,
      width: "100px",
      type: {
        type: "action_btns",
        btns: ["edit", "delete"],
      },
    },
  ];
  const getListUser = async () => {
    setLoadingGetListUser(true);
    const params = {
      page: page - 1,
      limit,
      // status: "ACTIVATED",
    };
    if (sortBy) {
      params.sortBy = sortBy;
      params.sortDirection = sortDirection || "DESC";
    }
    if (keyword) {
      params.keyword = keyword;
    }
    if (selectedStatus) {
      console.log("selectedStatus", selectedStatus);
      params.status = selectedStatus;
    }
    if (selectedRegisterType) {
      params.registerType = selectedRegisterType;
    }
    await apiGetUsers(
      {},
      {
        params,
      }
    ).then(
      response => {
        setLoadingGetListUser(false);
        setUserList(response.data.data);
        setTotal(response.data.total);
        setTotalPages(getTotalPages(response.data.total));
        setChangePage(false);
        return response.data.data;
      },
      error => {
        setChangePage(false);
        setLoadingGetListUser(false);
        return false;
      }
    );
  };
  const deleteUser = async id => {
    setLoadingDelete(true);
    await apiDeleteUser(id).then(
      response => {
        setLoadingDelete(false);
        toast({
          title: "Xóa người dùng thành công",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        getListUser();

        // dataMatch();
        return true;
      },
      error => {
        setLoadingDelete(false);
        toast({
          title: "Đã có lỗi xảy ra",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        return false;
      }
    );
  };
  const getTotalPages = total => {
    return Math.ceil(total / limit);
  };
  useEffect(() => {
    getListUser();
  }, []);
  //use effect for sortDirection
  useEffect(() => {
    console.log("sortDirection", sortDirection);
    if (sortBy) {
      setPage(1);
      setChangePage(true);
    }
  }, [sortDirection]);
  useEffect(() => {
    console.log("keyword", keyword);
    if (timerSearch) {
      clearTimeout(timerSearch);
    }
    setTimerSearch(
      setTimeout(() => {
        setPage(1);
        setChangePage(true);
      }, 1000)
    );
  }, [keyword]);

  //use effect for selectedRegisterType
  useEffect(() => {
    setPage(1);
    setChangePage(true);
  }, [selectedRegisterType]);
  // use effect for selectedStatus
  useEffect(() => {
    setPage(1);
    setChangePage(true);
  }, [selectedStatus]);

  useEffect(() => {
    console.log("change page", changePage);
    if (changePage && !loadingGetListUser) getListUser();
  }, [changePage]);
  return (
    <>
      <PageContent title="Quản lý người dùng">
        <Flex justify="end" align="center" gap={5}>
          <FilterByRegisterType
            selectedRegisterType={selectedRegisterType}
            onChangeRegisterType={e => {
              // setSelectedStatus(null);
              // setKeyword(null);
              setSortDirection("DESC");
              setSelectedRegisterType(e.target.value);
            }}
          />
          <FilterByStatus
            selectedStatus={selectedStatus}
            onChangeStatus={e => {
              // setSelectedRegisterType(null);
              // setKeyword(null);
              setSortDirection("DESC");
              setSelectedStatus(e.target.value);
            }}
          />
          <TextSearch
            placeholder="Tìm kiếm theo tên tài khoản, email"
            keyword={keyword}
            onChangeKeyword={e => {
              setKeyword(e.target.value);
            }}
          />
          <Button
            onClick={() => {
              console.log("click");
              onOpenAdd();
            }}
            colorScheme="teal"
            backgroundImage="linear-gradient(to right, #184E68 , #57CA85)"
          >
            Thêm mới
          </Button>
        </Flex>
        <Box
          style={{
            marginTop: "20px",
            width: "100%",
            height: "100%",
          }}
        >
          <BaseTable
            onSortChange={(sortBy, direction) => {
              if (sortDirection === direction) setSortDirection("");
              else setSortDirection(direction);
            }}
            loadingDelete={loadingDelete}
            headers={headers}
            loading={loadingGetListUser}
            data={userList}
            onEditClick={(e, index) => {
              console.log("edit click", index);
              setSelectedUser(userList[index]);
              onOpenEdit();
              //   setSelectedMatch(data[index]);
              //   navigate(`/manage-match/${data[index].id}/edit`);
            }}
            onDeleteClick={(e, index) => {
              console.log("delete click", index);
              deleteUser(userList[index]._id);
            }}
          />
          {userList.length > 0 && !loadingGetListUser && (
            <Flex
              justify="space-between"
              align="center"
              gap={5}
              marginTop="20px"
            >
              <Text fontWeight="bold">Tổng số: {total}</Text>
              <Text></Text>
            </Flex>
          )}
          {userList.length > 0 && !loadingGetListUser && (
            <Pagination
              onChangePage={newPage => {
                console.log(newPage, page);
                if (newPage !== page) {
                  setPage(newPage);
                  setChangePage(true);
                }
              }}
              page={page}
              totalPages={totalPages}
            />
          )}
        </Box>
      </PageContent>
      <ModalAddUser
        isOpen={isOpenAdd}
        onOpen={onOpenAdd}
        onClose={onCloseAdd}
        onOkAdd={() => {
          getListUser();
        }}
      />
      <ModalEditUser
        user={selectedUser}
        isOpen={isOpenEdit}
        onOpen={onOpenEdit}
        onClose={onCloseEdit}
        onOkEdit={() => {
          getListUser();
        }}
      />
    </>
  );
}
