import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { usePrevious, useSearchParam } from "react-use";
import Pusher from "pusher-js";
import { v4 as uuid } from "uuid";
import {
  HMSRoomState,
  selectAppData,
  selectIsConnectedToRoom,
  selectRoomState,
  useAVToggle,
  useHMSActions,
  useHMSStore,
  usePreviewJoin,
} from "@100mslive/react-sdk";
import { Box, Flex, Loading, styled, Text } from "@100mslive/roomkit-react";
import { Footer } from "../../components/100ms/Footer";
import { Header } from "../../components/100ms/Header";
import { ParticipantCount } from "../../components/100ms/Header/ParticipantList";
import { StreamActions } from "../../components/100ms/Header/StreamActions";
import PreviewContainer from "../../components/100ms/Preview/PreviewContainer";
// import { MatchCountDown } from "../../components/match/MatchCountDown";
// import { MatchEnd } from "../../components/match/MatchEnd";
import { TitleMatch } from "../../components/match/TitleMatch";
// import { apiGetMatch, apiGetMatches } from "../../api/match.api";
import { ConferenceMainView } from "../../layouts/100ms/ConferenceMainView";
import SidePane from "../../layouts/100ms/SidePane";
// import { Container } from "../../layouts/core/Container";
// import { LayoutHeader } from "../../layouts/core/LayoutHeader";
import { ErrorDialog } from "../../primitives/DialogContent";
// import usePusher from "../../common/usePusher";
import {
  useSetUiSettings,
  useTokenEndpoint,
} from "../../components/100ms/AppData/useUISettings";
import { useNavigation } from "../../components/100ms/hooks/useNavigation";
import {
  defaultPreviewPreference,
  UserPreferencesKeys,
  useUserPreferences,
} from "../../components/100ms/hooks/useUserPreferences";
import {
  isStreamingKit,
  // runPusher,
  // statusChannel,
  // statusLive,
} from "../../common/utils";
// import { useNavigation } from "./hooks/useNavigation";
import getToken from "../../services/tokenService";
import {
  APP_DATA,
  isAndroid,
  isIOS,
  isIPadOS,
  QUERY_PARAM_AUTH_TOKEN,
  QUERY_PARAM_NAME,
  QUERY_PARAM_PREVIEW_AS_ROLE,
  QUERY_PARAM_SKIP_PREVIEW,
  QUERY_PARAM_SKIP_PREVIEW_HEADFUL,
  UI_SETTINGS,
} from "../../common/constants";

/**
 * query params exposed -
 * skip_preview=true => used by recording and streaming service, skips preview and directly joins
 *                      header and footer don't show up in this case
 * skip_preview_headful=true => used by automation testing to skip preview without impacting the UI
 * name=abc => gives the initial name for the peer joining
 * auth_token=123 => uses the passed in token to join instead of fetching from token endpoint
 * ui_mode=activespeaker => lands in active speaker mode after joining the room
 */

const env = process.env.REACT_APP_ENV;
const ViewCamera = React.memo(({ authTokenByRoomCodeEndpoint, urlRoomId }) => {
  const [match, setMatch] = useState({});
  const [channel, setChannel] = useState({});
  const [chickenRed, setChickenRed] = useState({});
  const [chickenBlue, setChickenBlue] = useState({});
  // const [urlRoomId, setUrlRoomId] = useState(null);
  const [channelPusher, setChannelPusher] = useState(false);

  // console.log(urlRoomId);

  /**
   * Step 1: Preview
   */
  const navigate = useNavigation();
  const hmsActions = useHMSActions();
  const tokenEndpoint = useTokenEndpoint();
  const [, setIsHeadless] = useSetUiSettings(UI_SETTINGS.isHeadless);
  const { id: urId } = useParams(); // from the url
  // const urlRoomId = "65704154afd3b2853349050c";
  const userRole = "viewer";
  const [token, setToken] = useState(null);
  const [error, setError] = useState({ title: "", body: "" });
  // way to skip preview for automated tests, beam recording and streaming
  const beamInToken = useSearchParam("token") === "beam_recording"; // old format to remove
  let skipPreview = useSearchParam(QUERY_PARAM_SKIP_PREVIEW) === "true";
  // use this field to join directly for quick testing while in local
  const directJoinHeadfulFromEnv =
    process.env.REACT_APP_HEADLESS_JOIN === "true";
  const directJoinHeadful =
    useSearchParam(QUERY_PARAM_SKIP_PREVIEW_HEADFUL) === "true" ||
    directJoinHeadfulFromEnv;
  skipPreview = skipPreview || beamInToken || directJoinHeadful;
  const initialName =
    useSearchParam(QUERY_PARAM_NAME) || (skipPreview ? "Beam" : "");
  const previewAsRole = useSearchParam(QUERY_PARAM_PREVIEW_AS_ROLE);
  let authToken = useSearchParam(QUERY_PARAM_AUTH_TOKEN);
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const [, setUISettings] = useSetUiSettings();
  // const pusher = usePusher({ nameChannel: "private-channel" });

  // const dataMatch = async () => {
  //   await apiGetMatch(urId).then(
  //     response => {
  //       const tempData = response.data;
  //       if (tempData) {
  //         setMatch(tempData);
  //       }
  //       if (tempData.channel) {
  //         setChannel(tempData.channel);
  //         if (tempData.channel && tempData.channel.idRoom100ms) {
  //           setUrlRoomId(tempData.channel.idRoom100ms);
  //         }
  //       }
  //       if (tempData.chickenRed) {
  //         setChickenRed(tempData.chickenRed);
  //       }
  //       if (tempData.chickenBlue) {
  //         setChickenBlue(tempData.chickenBlue);
  //       }
  //     },
  //     error => {
  //       return false;
  //     }
  //   );
  // };

  // const bindData = data => {
  //   dataMatch();
  // };

  useEffect(() => {
    // dataMatch();
  }, []);

  useEffect(() => {
    console.log(match);
  }, [match]);

  useEffect(() => {
    if (authToken) {
      setToken(authToken);
      return;
    }
    if (!tokenEndpoint || !urlRoomId) {
      return;
    }
    const roomCode = !userRole && urlRoomId;

    const getTokenFn = roomCode
      ? () =>
          hmsActions.getAuthTokenByRoomCode(
            { roomCode },
            { endpoint: authTokenByRoomCodeEndpoint }
          )
      : () => getToken(tokenEndpoint, uuid(), userRole, urlRoomId);

    getTokenFn()
      .then(token => {
        setToken(token);
      })
      .catch(error => {
        // setError(convertPreviewError(error));
      });
  }, [
    hmsActions,
    tokenEndpoint,
    urlRoomId,
    userRole,
    authToken,
    authTokenByRoomCodeEndpoint,
  ]);

  /**
   * Step 2: Show view
   */
  const [showConference, setShowConference] = useState(false);

  const [previewPreference, setPreviewPreference] = useUserPreferences(
    UserPreferencesKeys.PREVIEW,
    defaultPreviewPreference
  );
  const { isLocalAudioEnabled, isLocalVideoEnabled } = useAVToggle();
  const [previewError, setPreviewError] = useState(false);
  const { enableJoin, preview, join } = usePreviewJoin({
    initialName,
    token,
    initEndpoint: env ? `https://${env}-init.100ms.live/init` : undefined,
    initialSettings: {
      isAudioMuted: skipPreview || previewPreference.isAudioMuted,
      isVideoMuted: skipPreview || previewPreference.isVideoMuted,
      speakerAutoSelectionBlacklist: ["Yeti Stereo Microphone"],
    },
    captureNetworkQualityInPreview: true,
    handleError: (_, method) => {
      if (method === "preview") {
        setPreviewError(true);
      }
    },
    previewAsRole,
  });
  const savePreferenceAndJoin = useCallback(() => {
    setPreviewPreference({
      initialName,
      isAudioMuted: !isLocalAudioEnabled,
      isVideoMuted: !isLocalVideoEnabled,
    });
    join();
    // onJoin && onJoin();
    !directJoinHeadful && setIsHeadless(skipPreview);
    // const meetingURL = `/streaming/meeting/${urlRoomId}/${userRole}`;
    // navigate(meetingURL);
    setShowConference(true);
    setUISettings({ [UI_SETTINGS.maxTileCount]: 2 });
    console.log("setShowConference");
  }, [
    join,
    isLocalAudioEnabled,
    isLocalVideoEnabled,
    initialName,
    setPreviewPreference,
    directJoinHeadful,
    setIsHeadless,
    setUISettings,
    // navigate,
    skipPreview,
    // onJoin,
  ]);

  useEffect(() => {
    if (token && match) {
      // if (skipPreview) {
      savePreferenceAndJoin();
      // } else {
      //   preview();
      // }
      // console.log(match);
      // if (match.status && match.status === statusLive.LIVE) {
      //   savePreferenceAndJoin();
      // }
      // if (match.status && match.status === statusLive.ENDED) {
      //   setShowConference(false);
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, match]);

  useEffect(() => {
    // console.log("isConnected");
    // console.log(isConnected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  /***
   * Step 3: Conference
   * */
  const roomState = useHMSStore(selectRoomState);
  const prevState = usePrevious(roomState);
  const isConnectedToRoom = useHMSStore(selectIsConnectedToRoom);
  const [hideControls, setHideControls] = useState(false);
  const dropdownList = useHMSStore(selectAppData(APP_DATA.dropdownList));
  const dropdownListRef = useRef();
  const performAutoHide = hideControls && (isAndroid || isIOS || isIPadOS);

  const toggleControls = e => {
    if (dropdownListRef.current?.length === 0) {
      setHideControls(value => !value);
    }
  };

  useEffect(() => {
    let timeout = null;
    dropdownListRef.current = dropdownList || [];
    if (dropdownListRef.current.length === 0) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (dropdownListRef.current.length === 0) {
          setHideControls(true);
        }
      }, 5000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [dropdownList, hideControls]);

  useEffect(() => {
    // Thực hiện các thao tác khi component được mount
    return () => {
      // Thực hiện các thao tác khi component sẽ unmount
      hmsActions.leave();
      console.log("Component will unmount");
    };
  }, []);

  if (error.title) {
    return <ErrorDialog title={error.title}>{error.body}</ErrorDialog>;
  }
  return (
    <Flex
      direction="column"
      css={{
        w: "100%",
        height: "500px",
      }}
    >
      <Flex
        css={{
          flex: "1 1 0",
          position: "relative",
          overflowY: "auto",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <ParticipantCount />
        {token ? (
          <>
            <Box
              css={{
                w: "100%",
                flex: "1 1 0",
                minHeight: 0,
                height: "400px",
                paddingBottom: "env(safe-area-inset-bottom)",
                marginBottom: "20px",
              }}
              id="conferencing"
              data-testid="conferencing"
              onClick={toggleControls}
            >
              <ConferenceMainView urlRoomId={urlRoomId} />
            </Box>
          </>
        ) : (
          <Box
            css={{
              w: "100%",
              flex: "1 1 0",
              minHeight: 0,
              height: "400px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: "env(safe-area-inset-bottom)",
            }}
            id="loading"
            data-testid="loading"
          >
            <Loading size={100} />
          </Box>
        )}
        {/* <SidePane
          css={{
            position: "unset",
            mr: "$10",
            "@lg": { position: "fixed", mr: "$0" },
          }}
        /> */}
      </Flex>
    </Flex>
  );
});

export default ViewCamera;
