import { Text } from "@100mslive/roomkit-react";
import { Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export default function MatchPercent({ win, lose, draw }) {
  const [percentArr, setPercentArr] = useState([1, 1, 1]);

  useEffect(() => {
    let temp = [];
    const total = win + lose + draw;
    if (total === 0) {
      temp = [0, 0, 0];
    } else {
      total;
      temp = [win / total, draw / total, lose / total];
    }
    setPercentArr(temp);
  }, [win, draw, lose]);
  return (
    <>
      {(percentArr[0] !== 0 || percentArr[1] !== 0 || percentArr[2] !== 0) && (
        <Flex
          style={{
            width: "100%",
            height: "40px",
          }}
        >
          {percentArr[0] !== 0 && (
            <Flex
              style={{
                width: `${percentArr[0] * 100}%`,
                height: "100%",
                backgroundColor: "green",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontWeight: "bold", color: "black" }}>
                {Math.round(percentArr[0] * 100)}%
              </Text>
            </Flex>
          )}
          {/* {percentArr[0] === 0 && (
            <Flex
              style={{
                backgroundColor: "green",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontWeight: "bold", color: "black" }}>0%</Text>
            </Flex>
          )} */}
          {percentArr[1] !== 0 && (
            <Flex
              style={{
                width: `${percentArr[1] * 100}%`,
                height: "100%",
                backgroundColor: "orange",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontWeight: "bold", color: "black" }}>
                {Math.round(percentArr[1] * 100)}%
              </Text>
            </Flex>
          )}
          {/* {percentArr[1] === 0 && (
            <Flex
              style={{
                backgroundColor: "orange",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontWeight: "bold", color: "black" }}>0%</Text>
            </Flex>
          )} */}
          {percentArr[2] !== 0 && (
            <Flex
              style={{
                width: `${percentArr[2] * 100}%`,
                height: "100%",
                backgroundColor: "red",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontWeight: "bold", color: "black" }}>
                {Math.round(percentArr[2] * 100)}%
              </Text>
            </Flex>
          )}
          {/* {percentArr[2] === 0 && (
            <Flex
              style={{
                backgroundColor: "red",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontWeight: "bold", color: "black" }}>0%</Text>
            </Flex>
          )} */}
        </Flex>
      )}
      {percentArr[0] === 0 && percentArr[1] === 0 && percentArr[2] === 0 && (
        <Flex
          style={{
            width: "100%",
            height: "40px",
          }}
        >
          <Flex
            style={{
              width: `${100 / 3}%`,
              height: "100%",
              backgroundColor: "green",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontWeight: "bold", color: "black" }}>---%</Text>
          </Flex>

          <Flex
            style={{
              width: `${100 / 3}%`,
              height: "100%",
              backgroundColor: "orange",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontWeight: "bold", color: "black" }}>---%</Text>
          </Flex>
          <Flex
            style={{
              width: `${100 / 3}%`,
              height: "100%",
              backgroundColor: "red",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontWeight: "bold", color: "black" }}>---%</Text>
          </Flex>
        </Flex>
      )}
    </>
  );
}
