import { Checkbox, CheckboxGroup, Select } from "@chakra-ui/react";
import { USER_STATUSES } from "../../constant/user_statuses";
import StatusType from "../TableCellType/StatusType";

export default function FilterByStatus({ selectedStatus, onChangeStatus }) {
  const statusList = USER_STATUSES;
  return (
    <div style={{ width: "200px", color: "#293038", background: "#ffffff" }}>
      <Select placeholder="Trạng thái" onChange={onChangeStatus}>
        {statusList.map(status => {
          return (
            <option
              onClick={() => onChangeStatus(status.value)}
              selected={status.value === selectedStatus}
              style={{ background: "#ffffff" }}
              value={status.value}
            >
              <StatusType value={status.value} statusOf="user" />
            </option>
          );
        })}
      </Select>
    </div>
  );
}
