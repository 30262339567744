import { useEffect, useRef, useState } from "react";
import PageContent from "../layouts/core/PageContent";
import { Box, Button, Flex, Spinner } from "@chakra-ui/react";
import ViewInfoComponent from "../components/chicken/ViewInfoComponent";
import EditInfoComponent from "../components/chicken/EditInfoComponent";
import MatchPercent from "../components/chicken/MatchPercent";
import { apiGetChicken } from "../api/chickens.api";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TableResult from "../components/chicken/TableResult";
import { ChevronLeftIcon } from "@100mslive/react-icons";

export default function TeamInfoPage() {
  const [loading, setLoading] = useState(false);
  const [chicken, setChicken] = useState({});
  const params = useParams();
  const [params2, setParams2] = useSearchParams();
  const navigate = useNavigate();
  const [triggerSave, setTriggerSave] = useState(false); // [1
  const refEdit = useRef(null);
  console.log(params2.get("isEdit"));
  const [isEdit, setIsEdit] = useState(
    params2.get("isEdit") === "true" ? true : false
  );
  const chickenId = params.teamId;
  const onSave = () => {
    setTriggerSave(true);
  };
  const getChickenDetail = async () => {
    setLoading(true);
    await apiGetChicken(chickenId).then(
      response => {
        // console.log(response.data);
        setLoading(false);
        setChicken(response.data);
      },
      error => {
        setLoading(false);
        // console.log(error);
      }
    );
  };
  useEffect(() => {
    getChickenDetail();
  }, []);
  return (
    <PageContent
      title={"Chi tiết gà"}
      childrenHeader={
        <ChevronLeftIcon
          cursor="pointer"
          onClick={() => {
            navigate("/manage-team");
          }}
        />
      }
    >
      {!loading && chicken && (
        <>
          <Flex
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            {!isEdit && (
              <Flex align={"center"} gap={5}>
                <Button
                  colorScheme="blue"
                  onClick={() => {
                    setIsEdit(true);
                  }}
                >
                  Sửa
                </Button>
                <Button
                  onClick={() => {
                    navigate("/manage-team");
                  }}
                >
                  Huỷ
                </Button>
              </Flex>
            )}
            {isEdit && (
              <Flex align={"center"} gap={5}>
                <Button
                  colorScheme="blue"
                  onClick={() => {
                    // setIsEdit(false);
                    onSave();
                  }}
                >
                  Lưu
                </Button>
                <Button
                  onClick={() => {
                    setIsEdit(false);
                  }}
                >
                  Hủy
                </Button>
              </Flex>
            )}
          </Flex>
          <Box
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "between",
            }}
          >
            <ViewInfoComponent isHide={isEdit} info={chicken} />
            <EditInfoComponent
              isHide={!isEdit}
              ref={refEdit}
              chicken={chicken}
              triggerSave={triggerSave}
              onOkEdit={() => {
                window.location.reload();
              }}
            />

            <TableResult chickenId={chicken._id} status="LIVE" />
            <TableResult chickenId={chicken._id} status="UPCOMING" />
            <TableResult chickenId={chicken._id} status="ENDED" />
          </Box>
        </>
      )}
      {loading && (
        <Flex
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
          }}
        >
          <Spinner />
        </Flex>
      )}
    </PageContent>
  );
}
