import { set } from "lodash";
import { useEffect, useState } from "react";

export default function Pagination({ totalPages, page, onChangePage }) {
  const getFontSize = page => {
    if (page < 10) return "12px";
    if (page < 100) return "10px";
    if (page < 1000) return "8px";
    return "6px";
  };
  const [arrPages, setArrPages] = useState([]);
  useEffect(() => {
    let temp = [];
    for (let i = 0; i < totalPages; i++) {
      temp.push(i);
    }
    setArrPages(temp);
    // console.log(arrPages);
  }, [totalPages]);
  const myPageItem = {
    cursor: "pointer",
    minWidth: "30px",
    padding: "6px 8px",
    height: "30px",
    textAlign: "center",
    borderRadius: "5px",
    border: "1px solid #b9bcbe",
    color: "#b9bcbe",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const activeItem = {
    color: "#ffffff",
    backgroundColor: "#184E68",
  };
  return (
    <>
      <div
        className="news-pagination"
        style={{
          display: "flex",
          gap: "5px",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
          width: "100%",
        }}
      >
        <div style={myPageItem} onClick={() => onChangePage(1)}>
          &lt;&lt;
        </div>
        <div
          style={myPageItem}
          onClick={() => {
            if (page > 1) onChangePage(page - 1);
          }}
        >
          &lt;{" "}
        </div>
        {totalPages <= 5 && (
          <div
            style={{
              display: "flex",
              gap: "5px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {arrPages.map((e, i) => (
              <div
                key={i}
                style={{
                  ...myPageItem,
                  ...(i + 1 === page ? activeItem : {}),
                }}
                onClick={() => onChangePage(i + 1)}
              >
                <span>{i + 1}</span>
              </div>
            ))}
          </div>
        )}
        {totalPages > 5 && (
          <div
            style={{
              display: "flex",
              gap: "5px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                ...myPageItem,
                ...(page === 1 ? activeItem : {}),
              }}
              onClick={() => onChangePage(1)}
            >
              <span>1</span>
            </div>
            <div
              style={{
                ...myPageItem,
                ...(page === 2 ? activeItem : {}),
              }}
              onClick={() => onChangePage(2)}
            >
              <span>2</span>
            </div>
            {(page === 1 || page === totalPages) && (
              <span style={{ color: "#184E68 !important" }}>...</span>
            )}
            {page > 2 && page < totalPages - 1 && (
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                }}
              >
                <span style={{ color: "#184E68 !important" }}>...</span>
                <div
                  fontSize={getFontSize(page)}
                  style={{
                    ...myPageItem,
                    color: "#ffffff",
                    backgroundColor: "#184E68",
                  }}
                >
                  <span>{page}</span>
                </div>
                <span style={{ color: "#184E68 !important" }}>...</span>
              </div>
            )}
            {page === 2 && (
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={myPageItem} onClick={() => onChangePage(3)}>
                  <span>3</span>
                </div>
                <span style={{ color: "#184E68 !important" }}>...</span>
              </div>
            )}
            {page === totalPages - 1 && (
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span style={{ color: "#184E68 !important" }}>...</span>
                <div
                  fontSize={getFontSize(totalPages - 2)}
                  style={myPageItem}
                  onClick={() => onChangePage(totalPages - 2)}
                >
                  <span>{totalPages - 2}</span>
                </div>
              </div>
            )}
            <div
              fontSize={getFontSize(totalPages - 1)}
              style={{
                ...myPageItem,
                ...(page === totalPages - 1 ? activeItem : {}),
              }}
              onClick={() => onChangePage(totalPages - 1)}
            >
              <span>{totalPages - 1}</span>
            </div>
            <div
              fontSize={getFontSize(totalPages)}
              style={{
                ...myPageItem,
                ...(page === totalPages ? activeItem : {}),
              }}
              onClick={() => onChangePage(totalPages)}
            >
              <span>{totalPages}</span>
            </div>
          </div>
        )}
        <div
          style={myPageItem}
          onClick={() => {
            if (page < totalPages) onChangePage(page + 1);
          }}
        >
          {">"}
        </div>
        <div
          style={myPageItem}
          onClick={() => {
            if (page < totalPages) onChangePage(totalPages);
          }}
        >
          {">>"}
        </div>
      </div>
    </>
  );
}
