import { Checkbox, CheckboxGroup, Select } from "@chakra-ui/react";

export default function StatusFilter({ selectedStatus, onChangeStatus }) {
  const statusList = [
    {
      id: 2,
      name: "Đã kết thúc",
      value: "ENDED",
    },
    {
      id: 3,
      name: "Đang diễn ra",
      value: "LIVE",
    },
    {
      id: 4,
      name: "Sắp diễn ra",
      value: "UPCOMING",
    },
    {
      id: 5,
      name: "Hot",
      value: "HOT",
    },
    {
      id: 6,
      name: "Nổi bật",
      value: "HIGHLIGHT",
    },
  ];
  return (
    <div style={{ width: "200px", color: "#293038", background: "#ffffff" }}>
      <Select
        placeholder="Trạng thái"
        onChange={onChangeStatus}
        background="#fffff"
      >
        {statusList.map(status => {
          return (
            <option
              onClick={() => onChangeStatus(status.value)}
              selected={status.value === selectedStatus}
              style={{ background: "#ffffff" }}
              value={status.value}
            >
              {status.name}
            </option>
          );
        })}
      </Select>
    </div>
  );
}
