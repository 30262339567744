import { Checkbox, CheckboxGroup, Spinner } from "@chakra-ui/react";
import { apiGetChannels } from "../../api/channels.api";
import { useEffect, useState } from "react";
import Pagination from "./Pagination";

export default function ChannelFilter({ selectedChannels, onChangeChannel }) {
  const [channelList, setChannelList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [limit, setLimit] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const getTotalPages = total => {
    return Math.ceil(total / limit);
  };
  const dataChannel = async () => {
    setLoading(true);
    await apiGetChannels(
      {},
      {
        params: {
          // page: page - 1,
          // limit,
          status: "ACTIVATED",
        },
      }
    ).then(
      response => {
        setLoading(false);
        setChannelList(response.data.data);
        setTotal(response.data.total);
        setTotalPages(getTotalPages(response.data.total));

        return response.data.data;
      },
      error => {
        setLoading(false);
        return false;
      }
    );
  };

  useEffect(() => {
    dataChannel();
  }, []);

  useEffect(() => {
    dataChannel();
  }, [page]);
  return (
    <div style={{ width: "100%" }}>
      Kênh:
      {!loading && (
        <div style={{ maxHeight: "200px", overflow: "auto", width: "100%" }}>
          <CheckboxGroup
            onChange={value => {
              console.log("onChange", value);
              onChangeChannel(value);
            }}
            value={selectedChannels}
          >
            {channelList.map((channel, index) => {
              return (
                <div key={index}>
                  <Checkbox value={channel._id} colorScheme="green">
                    {channel.name}
                  </Checkbox>
                </div>
              );
            })}
          </CheckboxGroup>
        </div>
      )}
      {loading && (
        <div>
          <Spinner />
        </div>
      )}
    </div>
  );
}
