import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { set } from "mobx";
import { FilterIcon } from "@100mslive/react-icons";
import { Flex } from "@100mslive/roomkit-react";
import BaseTable from "../components/BaseTable";
import ChannelFilter from "../components/match/ChannelFilter";
import ModalAddMatch from "../components/match/ModalAddMatch";
import ModalEditMatch from "../components/match/ModalEditMatch";
import Pagination from "../components/match/Pagination";
import StatusFilter from "../components/match/StatusFilter";
import TextSearch from "../components/match/TextSearch";
import {
  apiDeleteMatch,
  apiGetMatches,
  apiUpdateMatch,
} from "../api/matches.api";
import { Header } from "../layouts/core/Header";
import PageContent from "../layouts/core/PageContent";
import SideBar from "../layouts/core/SideBar";
import ModalAddChicken from "../components/chicken/ModalAddChicken";
import ModalAddChannel from "../components/channel/ModalAddChannel";

const ManageMatch = () => {
  // state
  const [matches, setMatches] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [sortDirection, setSortDirection] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total: 0,
    totalPages: 0,
  });
  const [data, setData] = useState([]);
  const [changePagination, setChangePagination] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingHotMatch, setLoadingHotMatch] = useState(false);
  const [loadingHighlightMatch, setLoadingHighlightMatch] = useState(false);
  const [isHot, setIsHot] = useState(false);
  const [isHighlight, setIsHighlight] = useState(false);
  const [triggerGetChicken, setTriggerGetChicken] = useState(false);
  const [triggerGetChannel, setTriggerGetChannel] = useState(false);
  const navigate = useNavigate();
  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();
  const {
    isOpen: isOpenAddTeam,
    onOpen: onOpenAddTeam,
    onClose: onCloseAddTeam,
  } = useDisclosure();

  const {
    isOpen: isOpenAddChannel,
    onOpen: onOpenAddChannel,
    onClose: onCloseAddChannel,
  } = useDisclosure();
  const [selectedMatch, setSelectedMatch] = useState({});
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [timerSearch, setTimerSearch] = useState(null);
  const toast = useToast();
  const headers = [
    {
      key: "idReadable",
      valueField: "idReadable",
      label: "ID",
      sortable: false,
      width: "100px",
    },

    {
      key: "channel",
      valueField: "channel",
      label: "Kênh",
      sortable: false,
      width: "200px",
      isShowTooltip: true,
    },
    {
      key: "name",
      valueField: "name",
      label: "Tên trận đấu",
      sortable: false,
      width: "200px",
      isShowTooltip: true,
    },
    {
      key: "chickenRed",
      valueField: "chickenRed",
      label: "Đội đỏ",
      sortable: false,
      // width: "200px",
    },
    {
      key: "chickenBlue",
      valueField: "chickenBlue",
      label: "Đội xanh",
      sortable: false,
      // width: "200px",
    },
    // {
    //   key: "title",
    //   valueField: "title",
    //   label: "Tiêu đề phiên",
    //   sortable: false,
    //   width: "200px",
    // },
    {
      key: "timeLive",
      valueField: "timeLive",
      label: "Thời gian live",
      sortable: true,
      // width: "200px",
      sortDirection,
      type: {
        type: "date_time",
      },
    },
    {
      key: "status",
      valueField: "status",
      label: "Trạng thái",
      sortable: false,
      width: "200px",
      type: {
        type: "colored_status",
        statusOf: "match",
      },
    },
    {
      key: "action",
      valueField: "action",
      label: "Hành động",
      sortable: false,
      width: "200px",
      type: {
        type: "action_btns",
        btns: ["hot_match", "highlight_match", "edit", "delete"],
      },
    },
  ];

  //call api
  const dataMatch = async () => {
    setLoading(true);
    const params = {
      page: pagination.page - 1,
      limit: pagination.limit,
    };
    if (sortDirection) {
      (params.sortBy = "timeLive"), (params.sortDirection = sortDirection);
    }
    if (selectedChannels.length > 0) {
      params.channels = selectedChannels.join(",");
    }
    if (keyword) {
      params.keyword = keyword;
    }
    if (selectedStatus) {
      params.status = selectedStatus;
    }
    if (isHot) {
      params.isHot = isHot;
    }
    if (isHighlight) {
      params.isHighlight = isHighlight;
    }
    await apiGetMatches(
      {},
      {
        params,
      }
    ).then(
      response => {
        setMatches(response.data.data);
        if (
          pagination.total !== response.data.total ||
          pagination.totalPages !==
            Math.ceil(response.data.total / pagination.limit)
        ) {
          setPagination({
            ...pagination,
            total: response.data.total,
            totalPages: Math.ceil(response.data.total / pagination.limit),
          });
          setChangePagination(false);
        }

        return response.data.data;
      },
      error => {
        setLoading(false);
        return false;
      }
    );
  };

  const deleteMatch = async id => {
    setLoadingDelete(true);
    await apiDeleteMatch(id).then(
      response => {
        setLoadingDelete(false);
        toast({
          title: "Xóa trận đấu thành công",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        setChangePagination(true);
        setPagination({
          ...pagination,
          page:
            matches.length === 1 && pagination.page > 1
              ? pagination.page - 1
              : pagination.page,
        });
        // dataMatch();
        return true;
      },
      error => {
        setLoadingDelete(false);
        toast({
          title: "Đã có lỗi xảy ra",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        return false;
      }
    );
  };

  const hotMatch = async (id, newIsHot) => {
    setLoadingHotMatch(true);
    await apiUpdateMatch(`${id}/hot`, { isHot: newIsHot }).then(
      response => {
        setLoadingHotMatch(false);
        toast({
          title: "Cập nhật trận đấu Hot thành công",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        setChangePagination(true);
        setPagination({
          ...pagination,
        });
        // dataMatch();
        return true;
      },
      error => {
        setLoadingHotMatch(false);
        toast({
          title: "Đã có lỗi xảy ra",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        return false;
      }
    );
  };

  const highlightMatch = async (id, newIsHighlight) => {
    setLoadingHighlightMatch(true);
    console.log(id);
    await apiUpdateMatch(`${id}/highlight`, {
      isHighlight: newIsHighlight,
    }).then(
      response => {
        setLoadingHighlightMatch(false);
        toast({
          title: "Cập nhật trận đấu nổi bật thành công",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        setChangePagination(true);
        setPagination({
          ...pagination,
        });
        // dataMatch();
        return true;
      },
      error => {
        setLoadingHighlightMatch(false);
        toast({
          title: "Đã có lỗi xảy ra",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        return false;
      }
    );
  };

  // useEffect
  useEffect(() => {
    dataMatch();
  }, []);

  useEffect(() => {
    console.log("pagination: ", pagination);
    if (!loading && changePagination) {
      dataMatch();
    }
  }, [pagination, changePagination]);

  useEffect(() => {
    // console.log(matches);
    assignData();
  }, [matches]);

  useEffect(() => {
    // console.log(sortDirection);
    console.log("sortDirection: ", sortDirection);
    setPagination({
      ...pagination,
      page: 1,
    });
    setChangePagination(true);
    if (!loading) dataMatch();
  }, [sortDirection]);
  useEffect(() => {
    if (!loading && isHot) dataMatch();
  }, [isHot]);
  useEffect(() => {
    if (!loading && isHighlight) dataMatch();
  }, [isHighlight]);
  useEffect(() => {
    console.log("keyword: ", keyword);
    clearTimeout(timerSearch);
    setTimerSearch(
      setTimeout(function () {
        setChangePagination(true);
        setPagination({
          ...pagination,
          page: 1,
        });
      }, 500)
    );

    // setInterval(() => {
    // if (!loading) dataMatch();
    // }, 500);
  }, [keyword]);

  useEffect(() => {
    // console.log(data);
    setLoading(false);
  }, [data]);

  useEffect(() => {
    console.log("selectedChannels: ", selectedChannels);
    if (!loading) dataMatch();
  }, [selectedChannels]);

  // useEffect(() => {
  //   console.log("selectedStatus: ", selectedStatus);
  //   setChangePagination(true);
  //   setPagination({
  //     ...pagination,
  //     page: 1,
  //   });
  //   // if (!loading) dataMatch();
  // }, [selectedStatus]);

  // function
  function handleClickAddnew() {}
  function assignData() {
    // console.log(matches);
    setData(
      matches?.map((match, index) => {
        return {
          id: match._id,
          idReadable: match.idReadable,
          channel: match.channel?.name,
          channel_id: match.channel?._id,
          name: match.name,
          chickenRed: match.chickenRed?.name,
          chickenRed_id: match.chickenRed?._id,
          chickenBlue: match.chickenBlue?.name,
          chickenBlue_id: match.chickenBlue?._id,
          title: match.title,
          timeLive: formatTimeLive(match.timeLive),
          status: match.status,
          live: match.live,
          match: match.match,
          isHighlight: match.isHighlight,
          isHot: match.isHot,
        };
      })
    );
  }
  const formatTimeLive = liveTimeStr => {
    const pieces = liveTimeStr.split(" ");
    console.log(pieces);
    if (pieces.length < 2) return liveTimeStr;
    const dMYYYY = pieces[1];
    const dPieces = dMYYYY.split("/");
    console.log(dPieces);
    const d = dPieces[0];
    const m = dPieces[1];
    const y = dPieces[2];
    if (d.length === 1) d = "0" + d;
    if (m.length === 1) m = "0" + m;
    return `${d}/${m}/${y} ${pieces[0]}`;
  };
  return (
    <div>
      <Header title="Danh sách trận đấu" />
      <SideBar />
      <Flex>
        <Box
          style={{
            marginLeft: "200px",
            backgroundColor: "#f2f2f2",
            width: "100%",
            minHeight: "100vh",
            height: "fit-content",
            padding: "20px",
            overflowX: "auto",
          }}
        >
          <Box
            style={{
              width: "100%",
              height: "fit-content",
              backgroundColor: "#ffffff",
              borderRadius: "10px",
              padding: "20px",
              filter:
                "drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08))",
            }}
          >
            <Flex justify="end" align="center" style={{ gap: "10px" }}>
              <Menu closeOnSelect={false}>
                <MenuButton
                  bgColor="#20639b"
                  as={Button}
                  leftIcon={<FilterIcon />}
                  color="#ffffff"
                  fontWeight="semibold"
                >
                  Kênh
                </MenuButton>
                <MenuList style={{ color: "#293038", background: "#ffffff" }}>
                  <MenuItem style={{ color: "#293038", background: "#ffffff" }}>
                    <ChannelFilter
                      selectedChannels={selectedChannels}
                      onChangeChannel={value => {
                        console.log(value);

                        setSelectedChannels(value);
                      }}
                    />
                  </MenuItem>
                </MenuList>
              </Menu>
              <StatusFilter
                style={{ width: "200px" }}
                selectedStatus={selectedStatus}
                onChangeStatus={e => {
                  console.log(e.target.value);
                  let status = e.target.value;
                  if (status === "HOT") {
                    setIsHot(true);
                    setIsHighlight(false);
                    setSelectedStatus("");
                    setChangePagination(true);
                    setPagination({
                      ...pagination,
                      page: 1,
                    });
                  }
                  if (status === "HIGHLIGHT") {
                    setIsHot(false);
                    setIsHighlight(true);
                    setSelectedStatus("");
                    setChangePagination(true);
                    setPagination({
                      ...pagination,
                      page: 1,
                    });
                  }
                  if (status !== "HOT" && status !== "HIGHLIGHT") {
                    setIsHot(false);
                    setIsHighlight(false);
                    setSelectedStatus(e.target.value);
                    setChangePagination(true);
                    setPagination({
                      ...pagination,
                      page: 1,
                    });
                  }
                }}
              />
              <TextSearch
                keyword={keyword}
                onChangeKeyword={e => setKeyword(e.target.value)}
                placeholder="Tìm kiếm bằng tên trận đấu hoặc tên đội"
              />
              <Button
                onClick={onOpenAdd}
                colorScheme="teal"
                backgroundImage="linear-gradient(to right, #184E68 , #57CA85)"
              >
                Thêm mới
              </Button>
            </Flex>
            <Box
              style={{
                marginTop: "20px",
                width: "100%",
                height: "100%",
              }}
            >
              <BaseTable
                headers={headers}
                loading={loading}
                data={data}
                loadingDelete={loadingDelete}
                loadingHighlightMatch={loadingHighlightMatch}
                loadingHotMatch={loadingHotMatch}
                onEditClick={(e, index) => {
                  // console.log(index);
                  setSelectedMatch(data[index]);
                  navigate(`/manage-match/${data[index].id}/edit`);
                }}
                onSortChange={(key, direction) => {
                  if (direction === sortDirection) setSortDirection("");
                  else setSortDirection(direction);
                }}
                onDeleteClick={(e, index) => {
                  deleteMatch(data[index].id);
                }}
                onHighlightMatch={(e, index) => {
                  highlightMatch(data[index].id, !data[index].isHighlight);
                }}
                onHotMatch={(e, index) => {
                  hotMatch(data[index].id, !data[index].isHot);
                }}
              />
              {data.length > 0 && !loading && (
                <Pagination
                  onChangePage={newPage => {
                    console.log(newPage, pagination.page);
                    if (newPage !== pagination.page) {
                      setPagination({
                        ...pagination,
                        page: newPage,
                      });
                      setChangePagination(true);
                    }
                  }}
                  page={pagination.page}
                  totalPages={pagination.totalPages}
                />
              )}
              {/* <ModalEditMatch
                isOpen={isOpen}
                onClose={onClose}
                match={selectedMatch}
                onOkUpdate={dataMatch}
              /> */}
              <ModalAddMatch
                isOpen={isOpenAdd}
                onClose={onCloseAdd}
                triggerGetChannel={triggerGetChannel}
                triggerGetChicken={triggerGetChicken}
                onOkAdd={() => {
                  setSelectedChannels([]);
                  setSelectedStatus("");
                  setKeyword("");
                  setPagination({
                    ...pagination,
                    page: 1,
                  });
                }}
                onAddNewChannel={onOpenAddChannel}
                onAddNewChicken={onOpenAddTeam}
                onAfterTriggerGetChickenDone={() => {
                  setTriggerGetChicken(false);
                }}
                onAfterTriggerGetChannelDone={() => {
                  setTriggerGetChannel(false);
                }}
              />
              <ModalAddChicken
                isOpen={isOpenAddTeam}
                onClose={onCloseAddTeam}
                onOkAdd={() => {
                  // trigger to reload chicken list
                  setTriggerGetChicken(true);
                  // setTriggerGetChicken(false);
                }}
              />
              <ModalAddChannel
                isOpen={isOpenAddChannel}
                onClose={onCloseAddChannel}
                onOkAdd={() => {
                  // trigger to reload channel list
                  setTriggerGetChannel(true);
                  // setTriggerGetChannel(false);
                }}
              />
            </Box>
          </Box>
        </Box>
      </Flex>
    </div>
  );
};

export default ManageMatch;
