import axios from "axios";

const axiosInstance = axios.create({
  timeout: 6000,
});

axiosInstance.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  config => {
    return config;
  },
  async error => {
    // console.log(error.response.status);
    if (error?.response?.status === 401) {
      const errorCode = error?.response?.data?.errorCode;
      console.log("errorCode", errorCode);
      if (
        errorCode === "INVALID_REFRESH_TOKEN" ||
        errorCode === "VERIFY_REFRESH_TOKEN_INVALID" ||
        errorCode === "VERIFY_REFRESH_TOKEN_EXPIRED"
      ) {
        localStorage.clear();
        window.location.href = "/login";
        return Promise.reject(refreshError);
      }
      try {
        const refreshToken = localStorage.getItem("refresh_token");
        const url = process.env.REACT_APP_API + "/auth-cms/refresh";
        const response = await axiosInstance.post(url, null, {
          headers: {
            "refresh-token": refreshToken,
          },
        });
        console.log("response", response);
        const newAccessToken = response.data.access_token;
        const newRefreshToken = response.data?.refresh_token;

        // Update the access token in the local storage
        localStorage.setItem("access_token", newAccessToken);
        localStorage.setItem("refresh_token", newRefreshToken);

        // Retry the original request with the new access token
        error.config._isRetry = true;
        return axiosInstance(error.config);
      } catch (refreshError) {
        // Refresh token request failed, log out the user
        localStorage.clear();
        window.location.href = "/login";
        return Promise.reject(refreshError);
      }
    }
    if ([2003].includes(error?.response?.data?.status?.code)) {
      // localStorage.clear();
      // window.location.reload();
    }
    return Promise.reject(error);
  }
);

/**
 *
 * @param method - request methods
 * @param url - request url
 * @param data - request data or params
 */
export const request = (method, url, data = {}, config = {}) => {
  const prefix = process.env.REACT_APP_API ? process.env.REACT_APP_API : "";
  url = prefix + url;
  if (method === "post") {
    return axiosInstance.post(url, data, config);
  } else if (method === "put") {
    return axiosInstance.put(url, data, config);
  } else if (method === "patch") {
    return axiosInstance.patch(url, data, config);
  } else if (method === "delete") {
    return axiosInstance.delete(url, { data: data }, config);
  } else if (method === "get") {
    return axiosInstance.get(url, {
      params: data,
      ...config,
    });
  }
};
