import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import PageContent from "../layouts/core/PageContent";
import BannerUploadAndPreview from "../components/banner/BannerUploadAndPreview";
import { useEffect, useState } from "react";
import {
  apiGetConfig,
  apiUpdateConfig,
  apiUploadImage,
} from "../api/configs.api";
import React from "react";

export default function ManageBanner() {
  const [loadingGetBannerConfig, setLoadingGetBannerConfig] = useState(false);
  const [loadingUpdateBannerTop, setLoadingUpdateBannerTop] = useState(false);
  const [loadingUpdateBannerBottom, setLoadingUpdateBannerBottom] =
    useState(false);
  const [loadingUpdateBannerLeft, setLoadingUpdateBannerLeft] = useState(false);
  const [loadingUpdateBannerRight, setLoadingUpdateBannerRight] =
    useState(false);
  const [bannerConfig, setBannerConfig] = useState(null);
  const toast = useToast();
  const cancelRef = React.useRef();
  const [currentBannerData, setCurrentBannerData] = useState({});
  const [currentBannerPosition, setCurrentBannerPosition] = useState(null);

  const {
    isOpen: isOpenSaveConfirm,
    onOpen: onOpenSaveConfirm,
    onClose: onCloseSaveConfirm,
  } = useDisclosure();
  const getBannerConfig = async () => {
    setLoadingGetBannerConfig(true);
    try {
      await apiGetConfig("banners").then(
        res => {
          console.log(res.data);
          setBannerConfig(res.data);
          setLoadingGetBannerConfig(false);
        },
        error => {
          console.log(error);
          setLoadingGetBannerConfig(false);
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
  const onPreSave = (data, position) => {
    setCurrentBannerData(data);
    setCurrentBannerPosition(position);
    onOpenSaveConfirm();
  };
  const onSave = () => {
    console.log(currentBannerData);
    const processes = [];
    const payloadNormalData = {};
    if (currentBannerData.link) {
      payloadNormalData.link = currentBannerData.link;
    }
    if (currentBannerData.isShow || currentBannerData.isShow === false) {
      payloadNormalData.isShow = currentBannerData.isShow;
    }
    if (Object.keys(payloadNormalData).length > 0) {
      processes.push(
        apiUpdateConfig(
          `banners/${currentBannerPosition}`,
          payloadNormalData
        ).then(
          res => true,
          error => false
        )
      );
    }
    if (currentBannerData.image) {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const formData = new FormData();
      formData.append("image", currentBannerData.image);
      processes.push(
        apiUploadImage(
          `banners/${currentBannerPosition}`,
          formData,
          config
        ).then(
          res => true,
          error => false
        )
      );
    }
    if (processes.length > 0) {
      if (currentBannerPosition === "BANNER_TOP") {
        setLoadingUpdateBannerTop(true);
      } else if (currentBannerPosition === "BANNER_BOTTOM") {
        setLoadingUpdateBannerBottom(true);
      } else if (currentBannerPosition === "BANNER_LEFT") {
        setLoadingUpdateBannerLeft(true);
      } else if (currentBannerPosition === "BANNER_RIGHT") {
        setLoadingUpdateBannerRight(true);
      }
      Promise.all(processes).then(
        res => {
          console.log(res);

          toast({
            title: "Cập nhật banner thành công!",
            description: "",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
          getBannerConfig();
          setLoadingUpdateBannerTop(false);
          setLoadingUpdateBannerBottom(false);
          setLoadingUpdateBannerLeft(false);
          setLoadingUpdateBannerRight(false);
        },
        error => {
          console.log(error);
          toast({
            title: "Đã xảy ra lỗi!",
            description: "",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
          setLoadingUpdateBannerTop(false);
          setLoadingUpdateBannerBottom(false);
          setLoadingUpdateBannerLeft(false);
          setLoadingUpdateBannerRight(false);
        }
      );
    }
  };

  useEffect(() => {
    getBannerConfig();
  }, []);

  return (
    <PageContent title="Quản lý banner">
      <Box style={{ width: "100%" }}>
        {loadingGetBannerConfig ? (
          <Box style={{ width: "100%", height: "50vh" }}>
            {" "}
            <Flex
              style={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spinner />
            </Flex>{" "}
          </Box>
        ) : (
          <>
            <BannerUploadAndPreview
              loading={loadingUpdateBannerTop}
              position="TOP"
              link={bannerConfig?.BANNER_TOP?.link}
              defaultImage={bannerConfig?.BANNER_TOP.image}
              isShow={bannerConfig?.BANNER_TOP?.isShow}
              onSave={data => onPreSave(data, "BANNER_TOP")}
            />
            <BannerUploadAndPreview
              loading={loadingUpdateBannerBottom}
              position="BOTTOM"
              link={bannerConfig?.BANNER_BOTTOM?.link}
              defaultImage={bannerConfig?.BANNER_BOTTOM.image}
              isShow={bannerConfig?.BANNER_BOTTOM?.isShow}
              onSave={data => onPreSave(data, "BANNER_BOTTOM")}
            />
            <Flex>
              <BannerUploadAndPreview
                loading={loadingUpdateBannerLeft}
                position="LEFT"
                link={bannerConfig?.BANNER_LEFT?.link}
                defaultImage={bannerConfig?.BANNER_LEFT.image}
                isShow={bannerConfig?.BANNER_LEFT?.isShow}
                onSave={data => onPreSave(data, "BANNER_LEFT")}
              />
              <BannerUploadAndPreview
                loading={loadingUpdateBannerRight}
                position="RIGHT"
                link={bannerConfig?.BANNER_RIGHT?.link}
                defaultImage={bannerConfig?.BANNER_RIGHT.image}
                isShow={bannerConfig?.BANNER_RIGHT?.isShow}
                onSave={data => onPreSave(data, "BANNER_RIGHT")}
              />
            </Flex>
          </>
        )}
      </Box>
      <AlertDialog
        isCentered
        isOpen={isOpenSaveConfirm}
        leastDestructiveRef={cancelRef}
        onClose={onCloseSaveConfirm}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Xác nhận lưu thay đổi ?
            </AlertDialogHeader>

            <AlertDialogBody></AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseSaveConfirm}>
                Huỷ
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onCloseSaveConfirm();
                  onSave();
                }}
                ml={3}
              >
                Xác nhận
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </PageContent>
  );
}
