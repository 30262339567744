import { FilterIcon } from "@100mslive/react-icons";
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { Header } from "./Header";
import SideBar from "./SideBar";

export default function PageContent({ title, children, childrenHeader }) {
  return (
    <div>
      <Header title={title}>{childrenHeader}</Header>
      <SideBar />
      <Flex>
        <Box
          style={{
            marginLeft: "200px",
            backgroundColor: "#f2f2f2",
            width: "100%",
            minHeight: "100vh",
            height: "fit-content",
            padding: "20px",
            overflowX: "auto",
          }}
        >
          <Box
            style={{
              width: "100%",
              height: "fit-content",
              backgroundColor: "#ffffff",
              borderRadius: "10px",
              padding: "20px",
              filter:
                "drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08))",
            }}
          >
            {children}
          </Box>
        </Box>
      </Flex>
    </div>
  );
}
