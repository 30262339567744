import React from "react";
import { Avatar, Box, Flex, Text } from "@100mslive/roomkit-react";

export const TitleMatch = () => {
  return (
    <Flex
      align="center"
      justify="center"
      css={{
        w: "100%",
      }}
    >
      <Box
        id="title_match"
        data-testid="title_match"
        css={{
          display: "flex",
          flex: "1 1 0",
          maxHeight: "150px",
          gap: "$12",
          paddingBottom: "env(safe-area-inset-bottom)",
        }}
      >
        <Box
          id="team_1"
          data-testid="title_match"
          css={{
            flex: "1",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            gap: "$12",
          }}
        >
          <InformationMatch />
          <Avatar
            name="AA"
            data-testid="preview_avatar_tile"
            css={{
              w: "75px",
            }}
          />
        </Box>
        <Box
          id="content"
          data-testid="title_match"
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            maxWidth: "100%",
            wordWrap: "break-word",
          }}
        >
          <Text
            css={{
              textAlign: "center",
              maxWidth: "100%",
              wordWrap: "break-word",
            }}
            variant="body1"
          >
            VS
          </Text>
        </Box>
        <Box
          id="team_2"
          data-testid="title_match"
          css={{
            flex: "1",
            display: "flex",
            alignItems: "center",
            gap: "$12",
          }}
        >
          <Avatar
            name="BB"
            data-testid="preview_avatar_tile"
            css={{
              w: "75px",
            }}
          />
          <InformationMatch />
        </Box>
      </Box>
    </Flex>
  );
};

export const InformationMatch = () => {
  return (
    <Box
      css={{
        display: "flex",
        alignItems: "start",
        flexDirection: "column",
      }}
    >
      <Text
        css={{
          textAlign: "center",
          maxWidth: "100%",
          wordWrap: "break-word",
        }}
        variant="body1"
      >
        Tên đội: Naruto
      </Text>
      <Text
        css={{
          textAlign: "center",
          maxWidth: "100%",
          wordWrap: "break-word",
        }}
        variant="body1"
      >
        Chủ sở hữu: Naruto
      </Text>
      <Text
        css={{
          textAlign: "center",
          maxWidth: "100%",
          wordWrap: "break-word",
        }}
        variant="body1"
      >
        Trọng lượng: 1000
      </Text>
    </Box>
  );
};
