import { Box, Text, useDisclosure } from "@chakra-ui/react";
import { FaCamera } from "react-icons/fa";
import { useEffect, useState } from "react";
import ModalUploadAndPreviewImage from "./ModalUploadAndPreviewImage";
export default function UploadAndPreviewImage({
  image,
  isSelected,
  onChangeImage,
}) {
  const [imageSrc, setImageSrc] = useState("");
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  useEffect(() => {
    const res =
      typeof image === "string" && !image.includes("data:image")
        ? `${process.env.REACT_BASE_IMG_URL}/uploads/images/${image}`
        : image;

    setImageSrc(res);
    console.log("imageSrc", res);
    console.log("imageSrc2", typeof image, image);
  }, [image]);

  return (
    <Box
      id="upload-image-comp"
      style={{
        width: "100%",
        aspectRatio: "1/1",
        cursor: "pointer",
        borderRadius: "50%",
        position: "relative",
        border: "1px solid #e2e8f0",
      }}
    >
      {!image && (
        <Text
          style={{
            position: "absolute",
            top: "0",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            fontSize: "13px",
          }}
        >
          Tải ảnh lên
        </Text>
      )}
      {image && (
        <img
          id="preview-image"
          style={{
            zIndex: "10",
            width: "100%",
            height: "100%",
            border: isSelected ? "3px solid orange" : "",
            borderRadius: "50%",
          }}
          src={imageSrc}
          // alt="Tải ảnh lên"
        />
      )}
      <div
        id="camera-upload-image"
        style={{
          position: "absolute",
          zIndex: "12",
          top: "0",
          width: "100%",
          height: "100%",
          visibility: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onFocus={() => {}}
        onClick={() => {
          // document.getElementById("uploadImg").click();
          onOpenModal();
        }}
      >
        <FaCamera size="30px" />
      </div>
      {/* <input
        accept="image/png, image/jpeg, image/bmp"
        onChange={e => {
          console.log(e.target.files);
          if (e.target.files && e.target.files[0]) {
            onChangeImage(e.target.files[0]);
          }
        }}
        type="file"
        id="uploadImg"
        style={{ zIndex: "1", visibility: "hidden", position: "absolute" }}
      /> */}
      <ModalUploadAndPreviewImage
        isOpenModal={isOpenModal}
        onCloseModal={onCloseModal}
        onSave={preview => {
          // onChangeImage(preview);
          console.log("preview", typeof preview, preview);
          // setImageSrc(preview);
          onChangeImage(preview);
        }}
      />
    </Box>
  );
}
