import { request } from "./request";

export const apiGetUsers = (data = {}, config = {}) =>
  request("get", "/user", data, config);

export const apiGetUser = (id, config) =>
  request("get", `/user/${id}`, {}, config);

export const apiAddUser = data => request("post", "/user", data);
export const apiEditUser = (id, data) => request("patch", `/user/${id}`, data);
export const apiDeleteUser = id => request("delete", `/user/${id}`);
