import { Text } from "@chakra-ui/react";

export default function StatusType({ value, statusOf = "match" }) {
  return (
    <Text
      color={
        value === "UPCOMING" || value === "ACTIVATED" || value === "WIN"
          ? "green"
          : value === "LIVE" || value === "DEACTIVATED" || value === "LOSE"
          ? "red"
          : value === "NOT_ACTIVATED"
          ? "blue"
          : value === "DRAW"
          ? "orange"
          : "black"
      }
    >
      {statusOf === "match" && (
        <>
          {value === "UPCOMING"
            ? "Sắp diễn ra"
            : value === "LIVE"
            ? "Đang diễn ra"
            : "Đã kết thúc"}
        </>
      )}
      {statusOf === "channel" && (
        <>
          {value === "ACTIVATED" ? "ON" : value === "DEACTIVATED" ? "OFF" : ""}
        </>
      )}
      {statusOf === "match_result" && (
        <>
          {value === "WIN"
            ? "Thắng"
            : value === "LOSE"
            ? "Thua"
            : value === "DRAW"
            ? "Hoà"
            : "-"}
        </>
      )}
      {statusOf === "user" && (
        <>
          {value === "NOT_ACTIVATED"
            ? "Chưa kích hoạt"
            : value === "ACTIVATED"
            ? "Đã kích hoạt"
            : value === "DEACTIVATED"
            ? "Bị cấm"
            : ""}
        </>
      )}
    </Text>
  );
}
