import { SearchIcon } from "@100mslive/react-icons";
import { Input, InputGroup, InputLeftAddon } from "@chakra-ui/react";

export default function TextSearch({
  keyword,
  onChangeKeyword,
  placeholder = "Tìm kiếm",
  size = "md",
  width = "300px",
}) {
  return (
    <InputGroup width="fit-content">
      <InputLeftAddon>
        <SearchIcon />
      </InputLeftAddon>
      <Input
        _placeholder={{ opacity: 0.5, fontSize: "14px" }}
        onChange={onChangeKeyword}
        size={size}
        placeholder={placeholder}
        width={width}
        value={keyword}
      />
    </InputGroup>
  );
}
