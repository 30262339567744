import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { GiFlame, GiHearts } from "react-icons/gi";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  EditIcon,
  TrashIcon,
} from "@100mslive/react-icons";
import { Flex } from "@100mslive/roomkit-react";
import React from "react";
import StatusType from "./TableCellType/StatusType";
import LinkCamCopy from "./TableCellType/LinkCamCopy";
import RegisterMethodType from "./TableCellType/RegisterMethodType";

export default function BaseTable({
  headers,
  data,
  loading,
  loadingDelete,
  onEditClick,
  onSortChange,
  onDeleteClick,
  loadingHighlightMatch,
  onHighlightMatch,
  loadingHotMatch,
  onHotMatch,
}) {
  // delete dialog
  const {
    isOpen: isOpenConfirmDelete,
    onOpen: onOpenConfirmDialog,
    onClose: onCloseDialogConfirmDelete,
  } = useDisclosure();
  const dialogConfirmDeleteCancelRef = React.useRef();
  // hot match dialog
  const {
    isOpen: isOpenConfirmHotMatch,
    onOpen: onOpenConfirmHotMatch,
    onClose: onCloseDialogConfirmHotMatch,
  } = useDisclosure();
  const dialogConfirmHotMatchCancelRef = React.useRef();
  // highlight match dialog
  const {
    isOpen: isOpenConfirmHighlightMatch,
    onOpen: onOpenConfirmHighlightMatch,
    onClose: onCloseDialogConfirmHighlightMatch,
  } = useDisclosure();
  const dialogConfirmHighlightMatchCancelRef = React.useRef();

  const [selectedRowId, setSelectedRowId] = React.useState(null);
  const formatDateTime = dateTimeStr => {
    const dateTime = new Date(dateTimeStr);
    console.log(dateTime);
    const d = dateTime.getDate();
    const m = dateTime.getMonth() + 1;
    const y = dateTime.getFullYear();
    const h = dateTime.getHours();
    const min = dateTime.getMinutes();
    const s = dateTime.getSeconds();
    return `${d < 10 ? "0" + d : d}/${m < 10 ? "0" + m : m}/${y} ${
      h < 10 ? "0" + h : h
    }:${min < 10 ? "0" + min : min}:${s < 10 ? "0" + s : s}`;
  };
  const styleLineClamp1 = {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    textOverflow: "ellipsis",
    maxWidth: "100%",
    lineClamp: 1,
    whiteSpace: "normal",
  };
  if (loading)
    return (
      <Flex
        align="center"
        justify="center"
        style={{ width: "100%", height: "50vh" }}
      >
        <Spinner />
      </Flex>
    );
  return (
    <>
      <TableContainer>
        <Table size="lg" variant="simple">
          <Thead>
            <Tr>
              {headers.map((header, index) => {
                return (
                  <Th
                    key={index}
                    style={{
                      borderBottom: "1px solid #ededed",
                      paddingLeft: "10px",
                      textTransform: "capitalize",
                      fontWeight: "bold",
                      color: "#293038",
                      fontSize: "17px",
                      width: header.width ? header.width : "",
                      visibility: header.hidden ? "hidden" : "visible",
                      minWidth: header.minWidth ? header.minWidth : "",
                    }}
                  >
                    <Flex align="center" justify={header.align || "left"}>
                      {header.label}{" "}
                      {header.sortable && (
                        <ChevronUpIcon
                          onClick={e => onSortChange(header.key, "ASC")}
                          style={{
                            cursor: "pointer",
                            fontSize: "10px",
                            opacity:
                              header.sortDirection &&
                              header.sortDirection === "ASC"
                                ? 1
                                : 0.2,
                          }}
                        />
                      )}
                      {header.sortable && (
                        <ChevronDownIcon
                          onClick={e => onSortChange(header.key, "DESC")}
                          style={{
                            cursor: "pointer",
                            fontSize: "10px",
                            opacity:
                              header.sortDirection &&
                              header.sortDirection === "DESC"
                                ? 1
                                : 0.2,
                          }}
                        />
                      )}
                    </Flex>
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          {data.length > 0 && (
            <>
              <Tbody>
                {data?.map((row, rowId) => {
                  return (
                    <Tr key={`row_${rowId}`}>
                      {headers?.map((col, colId) => {
                        if (col.type && col.type.type === "colored_status")
                          return (
                            <Td
                              key={`col_${rowId}_${colId}`}
                              style={{
                                align: col.align | "center",
                                borderTop: "1px solid #ededed",
                                borderBottom: "1px solid #ededed",
                                padding: "5px 10px",
                                visibility: col.hidden ? "hidden" : "visible",
                              }}
                            >
                              <StatusType
                                value={row[col.valueField]}
                                statusOf={col.type.statusOf}
                              />
                            </Td>
                          );
                        if (col.type && col.type.type === "action_btns")
                          return (
                            <Td
                              style={{
                                borderTop: "1px solid #ededed",
                                borderBottom: "1px solid #ededed",
                                padding: "5px 10px",
                                color: "#293038",
                              }}
                              key={`col_${rowId}_${colId}`}
                            >
                              <Flex align="center" gap={3}>
                                {col.type.btns.map((btn, index) => {
                                  if (btn === "edit")
                                    return (
                                      <Button
                                        onClick={e => onEditClick(e, rowId)}
                                      >
                                        <EditIcon />
                                      </Button>
                                    );
                                  if (btn === "delete")
                                    return (
                                      <Button>
                                        <TrashIcon
                                          onClick={() => {
                                            setSelectedRowId(rowId);
                                            onOpenConfirmDialog();
                                          }}
                                          color="red"
                                        />
                                      </Button>
                                    );
                                  if (btn == "hot_match") {
                                    return (
                                      <GiFlame
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "27px",
                                          color: row.isHot ? "red" : "black",
                                        }}
                                        onClick={e => {
                                          setSelectedRowId(rowId);
                                          onOpenConfirmHotMatch();
                                        }}
                                      />
                                    );
                                  }
                                  if (btn == "highlight_match") {
                                    return (
                                      <GiHearts
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "27px",
                                          color: row.isHighlight
                                            ? "red"
                                            : "black",
                                        }}
                                        onClick={e => {
                                          setSelectedRowId(rowId);
                                          onOpenConfirmHighlightMatch();
                                        }}
                                      />
                                    );
                                  }
                                })}
                              </Flex>
                            </Td>
                          );
                        else if (col.type && col.type.type === "date_time")
                          return (
                            <Td
                              key={`col_${rowId}_${colId}`}
                              style={{
                                align: col.align | "center",
                                borderTop: "1px solid #ededed",
                                borderBottom: "1px solid #ededed",
                                padding: "5px 10px",
                                color: "#293038",
                                visibility: col.hidden ? "hidden" : "visible",
                              }}
                            >
                              {formatDateTime(row[col.valueField])}
                            </Td>
                          );
                        else if (col.type && col.type.type === "register_type")
                          return (
                            <Td
                              key={`col_${rowId}_${colId}`}
                              style={{
                                align: col.align | "center",
                                borderTop: "1px solid #ededed",
                                borderBottom: "1px solid #ededed",
                                padding: "5px 10px",
                                color: "#293038",
                              }}
                            >
                              <RegisterMethodType type={row[col.valueField]} />
                            </Td>
                          );
                        else if (col.type && col.type.type === "link_camp_copy")
                          return (
                            <Td
                              key={`col_${rowId}_${colId}`}
                              style={{
                                align: col.align | "center",
                                borderTop: "1px solid #ededed",
                                borderBottom: "1px solid #ededed",
                                padding: "5px 10px",
                                color: "#293038",
                              }}
                            >
                              <LinkCamCopy value={row[col.valueField]} />
                            </Td>
                          );

                        return (
                          <Td
                            key={`col_${rowId}_${colId}`}
                            style={{
                              align: col.align | "center",
                              borderTop: "1px solid #ededed",
                              borderBottom: "1px solid #ededed",
                              padding: "5px 10px",
                              color: "#293038",
                              width: col.width ? col.width : "",
                            }}
                          >
                            {col.width && col.isShowTooltip && (
                              <Tooltip
                                placement="top-start"
                                label={row[col.valueField]}
                                style={{
                                  ...styleLineClamp1,
                                  maxWidth: col.width,
                                }}
                              >
                                <Flex align="center" gap={3}>
                                  {col.type &&
                                    col.type.type ===
                                      "avatar_text_with_tooltip" && (
                                      <img
                                        style={{
                                          borderRadius: "50%",
                                          flexShrink: 0,
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        src={`${process.env.REACT_BASE_IMG_URL}/uploads/images/${row.image}`}
                                      />
                                    )}
                                  <span
                                    style={{
                                      ...styleLineClamp1,
                                      maxWidth: col.width,
                                    }}
                                  >
                                    {!col.link && <>{row[col.valueField]}</>}
                                    {col.link && (
                                      <Link href={`/${col.link}/${row._id}`}>
                                        {row[col.valueField]}
                                      </Link>
                                    )}
                                  </span>
                                </Flex>
                              </Tooltip>
                            )}
                            {!col.isShowTooltip && (
                              <Flex
                                align="center"
                                gap={3}
                                style={col.width ? styleLineClamp1 : {}}
                              >
                                {row[col.valueField] && !col.link && (
                                  <>{row[col.valueField]}</>
                                )}
                                {row[col.valueField] && col.link && (
                                  <Link href={`/${col.link}/${row._id}`}>
                                    {row[col.valueField]}
                                  </Link>
                                )}
                                {!row[col.valueField] && <Text>-</Text>}
                              </Flex>
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </>
          )}
          {data.length === 0 && (
            <Tbody>
              <Tr>
                <Td
                  colSpan={headers.length}
                  style={{ textAlign: "center", color: "#293038" }}
                >
                  Không có dữ liệu
                </Td>
              </Tr>
            </Tbody>
          )}
          {/* <Tfoot>
          <Tr>
            <Th>To convert</Th>
            <Th>into</Th>
            <Th isNumeric>multiply by</Th>
          </Tr>
        </Tfoot> */}
        </Table>
      </TableContainer>
      <AlertDialog
        isCentered
        isOpen={isOpenConfirmDelete}
        leastDestructiveRef={dialogConfirmDeleteCancelRef}
        onClose={onCloseDialogConfirmDelete}
      >
        <AlertDialogOverlay style={{ opacity: 0.5 }}>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Xác nhận xoá{" "}
              {data[selectedRowId]?.name || data[selectedRowId]?.username || ""}
              ?
            </AlertDialogHeader>

            {/* <AlertDialogBody>
          Xác nhận xoá?
        </AlertDialogBody> */}

            <AlertDialogFooter>
              <Button
                ref={dialogConfirmDeleteCancelRef}
                onClick={onCloseDialogConfirmDelete}
              >
                Huỷ
              </Button>
              <Button
                colorScheme="red"
                onClick={e => {
                  console.log(selectedRowId);
                  onCloseDialogConfirmDelete();
                  onDeleteClick(e, selectedRowId);
                }}
                ml={3}
              >
                {loadingDelete && <Spinner />}Xoá
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isCentered
        isOpen={isOpenConfirmHotMatch}
        leastDestructiveRef={dialogConfirmHotMatchCancelRef}
        onClose={onCloseDialogConfirmHotMatch}
      >
        <AlertDialogOverlay style={{ opacity: 0.5 }}>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {data[selectedRowId]?.isHot
                ? "Bạn có muốn BỎ trận đấu Hot không ?"
                : "Bạn có muốn chọn trận đấu này thành trận đấu Hot không?"}
            </AlertDialogHeader>

            {/* <AlertDialogBody>
          Xác nhận xoá?
        </AlertDialogBody> */}

            <AlertDialogFooter>
              <Button
                ref={dialogConfirmHotMatchCancelRef}
                onClick={onCloseDialogConfirmHotMatch}
              >
                Huỷ
              </Button>
              <Button
                colorScheme="red"
                onClick={e => {
                  console.log(selectedRowId);
                  onCloseDialogConfirmHotMatch();
                  onHotMatch(e, selectedRowId);
                }}
                ml={3}
              >
                {loadingHotMatch && <Spinner />}Xác nhận
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isCentered
        isOpen={isOpenConfirmHighlightMatch}
        leastDestructiveRef={dialogConfirmHighlightMatchCancelRef}
        onClose={onCloseDialogConfirmHighlightMatch}
      >
        <AlertDialogOverlay style={{ opacity: 0.5 }}>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {data[selectedRowId]?.isHighlight
                ? "Bạn có muốn huỷ trận đấu nổi bật này không ?"
                : ` Xác nhận đổi trận đấu nổi bật thành
              ${data[selectedRowId]?.name || ""} ?`}
            </AlertDialogHeader>

            {/* <AlertDialogBody>
          Xác nhận xoá?
        </AlertDialogBody> */}

            <AlertDialogFooter>
              <Button
                ref={dialogConfirmHighlightMatchCancelRef}
                onClick={onCloseDialogConfirmHighlightMatch}
              >
                Huỷ
              </Button>
              <Button
                colorScheme="red"
                onClick={e => {
                  console.log(selectedRowId);
                  onCloseDialogConfirmHighlightMatch();
                  onHighlightMatch(
                    e,
                    selectedRowId,
                    data[selectedRowId]?.isHighlight
                  );
                }}
                ml={3}
              >
                {loadingHighlightMatch && <Spinner />}Xác nhận
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
