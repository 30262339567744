import { request } from "./request";

export const apiGetConfig = (id, config) =>
  request("get", `/configs/${id}`, {}, config);
export const apiAddConfig = data => request("post", "/configs", data);
export const apiUpdateConfig = (id, data) =>
  request("patch", `/configs/${id}`, data);

export const apiUploadImage = (id, data, config) =>
  request("post", `/configs/${id}/upload`, data, config);
