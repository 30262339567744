import {
  Box,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { apiGetMatchHistory } from "../../api/chickens.api";
import BaseTable from "../BaseTable";
import Pagination from "../match/Pagination";

export default function TableResult({ status, chickenId }) {
  const [title, setTitle] = useState("Kết quả trận đấu");
  useEffect(() => {
    if (status === "LIVE") {
      setTitle("Trận đấu đang diễn ra");
    } else if (status === "UPCOMING") {
      setTitle("Trận đấu sắp diễn ra");
    } else if (status === "ENDED") {
      setTitle("Trận đấu đã diễn ra");
    }
  }, [status]);
  const headers = [
    {
      key: "idReadable",
      valueField: "idReadable",
      label: "ID",
      width: "100px",
      minWidth: "100px",
    },
    {
      key: "channelName",
      valueField: "channelName",
      label: "Kênh",
      width: "400px",
      isShowTooltip: true,
    },
    {
      key: "rivalChickenName",
      valueField: "rivalChickenName",
      label: "Đối thủ",
      width: "400px",
      isShowTooltip: true,
    },
    {
      key: "timeLive",
      valueField: "timeLive",
      label: "Thời gian",
      type: {
        type: "date_time",
      },
      minWidth: "167px",
      // width: "200px",
    },
  ];
  if (status === "ENDED") {
    headers.push({
      key: "result",
      valueField: "result",
      label: "Kết quả",
      type: {
        type: "colored_status",
        statusOf: "match_result",
      },
      width: "100px",
    });
  } else {
    headers.push({
      key: "result",
      valueField: "result",
      label: "Kết quả",
      type: {
        type: "colored_status",
        statusOf: "match_result",
      },
      width: "100px",
      hidden: true,
    });
  }
  const getMatchHistory = async () => {
    setLoading(true);
    await apiGetMatchHistory(
      `${chickenId}/match-history?page=${page - 1}&limit=10&status=${status}`,
      page
    ).then(
      response => {
        // console.log(response.data);
        setLoading(false);
        setData(
          response.data.data.map(item => {
            console.log(item.channel);
            return {
              ...item,
              channelName: item.channel?.name,
              rivalChickenName: item.rivalChicken?.name,
              timeLive: item.timeLive,
            };
          })
        );
        setTotal(response.data.total);
        setTotalPages(Math.ceil(response.data.total / limit));
      },
      error => {
        setLoading(false);
        console.log(error);
      }
    );
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (chickenId && status) {
      getMatchHistory();
    }
  }, []);

  useEffect(() => {
    if (chickenId && status && !loading) {
      getMatchHistory();
    }
  }, [page, chickenId, status]);
  return (
    <>
      <Box>
        <Text
          fontWeight="bold"
          style={{
            marginBottom: "10px",
            fontSize: "16px",
          }}
        >
          {title}
        </Text>
        <BaseTable data={data} loading={loading} headers={headers} />
        <Pagination
          onChangePage={newPage => {
            if (newPage !== page) {
              setPage(newPage);
              // setChangePagination(true);
            }
          }}
          page={page}
          totalPages={totalPages}
        />
      </Box>
    </>
  );
}
