import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  ChakraProvider,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ChangePasswordPopup } from "../../components/auth/ChangePasswordPopup";
import Pusher from "pusher-js";
export const AvatarMenu = () => {
  const navigate = useNavigate(); // Tạo đối tượng history
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (
      !localStorage.getItem("profile") ||
      localStorage.getItem("profile") === "undefined" ||
      localStorage.getItem("profile") === "null" ||
      !localStorage.getItem("access_token") ||
      !localStorage.getItem("refresh_token") ||
      localStorage.getItem("access_token") === "undefined" ||
      localStorage.getItem("refresh_token") === "undefined" ||
      localStorage.getItem("access_token") === "null" ||
      localStorage.getItem("refresh_token") === "null"
    ) {
      localStorage.clear();
      navigate("/login");
    }
  });
  const cn = () => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      // key: process.env.REACT_PUSHER_PUBLIC_KEY,
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      channelAuthorization: {
        endpoint: process.env.REACT_APP_PUSHER_AUTH,
      },
    });
    const channel = pusher.subscribe("zone10-channel");
    channel.bind("zone10-event", data => {
      if (data.action === "CHANGE_PASSWORD_CMS") {
        const profile =
          localStorage.getItem("profile") &&
          JSON.parse(localStorage.getItem("profile"));
        if (
          profile &&
          data?.payload?.userId &&
          profile?.idReadable === data?.payload?.userId
        ) {
          if (localStorage.getItem("passwordResetter") !== "me") {
            localStorage.clear();
            navigate("/login");
          }
          localStorage.removeItem("passwordResetter");
        }
      }
    });
  };

  useEffect(() => {
    cn();
  }, []);
  return (
    <ChakraProvider>
      <Menu>
        <MenuButton>
          <div style={{ display: "flex", gap: "10px" }}>
            <Stack gap="0">
              <Text
                style={{ textAlign: "right" }}
                fontSize="sm"
                fontWeight="semibold"
                color="#293038"
              >
                {localStorage.getItem("profile") &&
                  JSON.parse(localStorage.getItem("profile"))?.fullName}
              </Text>
              <Text
                fontSize="sm"
                color="#293038"
                style={{ textAlign: "right" }}
              >
                {localStorage.getItem("profile") &&
                  JSON.parse(localStorage.getItem("profile"))?.email}
              </Text>
            </Stack>
            <Avatar
              name={
                (localStorage.getItem("profile") &&
                  JSON.parse(localStorage.getItem("profile"))?.fullName) ||
                "Admin"
              }
              src="https://bit.ly/dan-abramov"
            />
          </div>
        </MenuButton>
        <MenuList
          style={{
            minWidth: "150px",
          }}
        >
          <MenuItem
            style={{
              justifyContent: "center",
            }}
            onClick={onOpen}
          >
            Đổi mật khẩu
            <ChangePasswordPopup
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
            />
          </MenuItem>
          <MenuItem
            style={{
              justifyContent: "center",
            }}
            onClick={handleLogout}
          >
            Đăng xuất
          </MenuItem>
        </MenuList>
      </Menu>
    </ChakraProvider>
  );
};
