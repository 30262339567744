import { useState, useEffect } from "react";
import { apiGetChannels } from "../../api/channels.api";
import { apiGetChickens } from "../../api/chickens.api";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { AddIcon } from "@100mslive/react-icons";
import { apiAddMatch } from "../../api/matches.api";
import { Form, Navigate, useNavigate } from "react-router-dom";
import { set } from "mobx";
import SelectChicken from "../chicken/SelectChicken";

export default function ModalAddMatch({
  isOpen,
  onOpen,
  onClose,
  onOkAdd,
  triggerGetChannel,
  triggerGetChicken,
  onAddNewChannel,
  onAddNewChicken,
  onAfterTriggerGetChickenDone,
  onAfterTriggerGetChannelDone,
}) {
  const [chickens, setChickens] = useState([]);
  const [channels, setChannels] = useState([]);
  const [match, setMatch] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [isDirty, setIsDirty] = useState({});

  const navigate = useNavigate();
  const formRules = {
    name: [
      {
        key: "required",
        message: "Vui lòng điền tên trận đấu.",
        validate: value => !value,
      },
      {
        key: "maxLength",
        message: "Tên trận đấu không được quá 100 ký tự.",
        validate: value => value && value.length > 100,
      },
    ],
    chickenRed_id: [
      {
        key: "required",
        message: "Vui lòng chọn đội đỏ.",
        validate: value => !value,
      },
    ],
    chickenBlue_id: [
      {
        key: "required",
        message: "Vui lòng chọn đội xanh.",
        validate: value => !value,
      },
    ],
    channel_id: [
      {
        key: "required",
        message: "Vui lòng chọn kênh.",
        validate: value => !value,
      },
    ],
    timeLive: [
      {
        key: "required",
        message: "Vui lòng chọn thời gian live.",
        validate: value => !value,
      },
      {
        key: "invalid",
        message: "Vui lòng không chọn thời gian quá khứ.",
        validate: value => {
          const dateTime = new Date(value);
          const now = new Date();
          return dateTime.getTime() < now.getTime();
        },
      },
    ],
  };
  const [errorForm, setErrorForm] = useState({});
  const toast = useToast();
  const dataChicken = async () => {
    await apiGetChickens().then(
      response => {
        // console.log(response.data);
        setChickens(response.data.data);
        if (onAfterTriggerGetChickenDone) onAfterTriggerGetChickenDone();
        return response.data.data;
      },
      error => {
        if (onAfterTriggerGetChickenDone) onAfterTriggerGetChickenDone();
        return false;
      }
    );
  };

  const dataChannel = async () => {
    await apiGetChannels(
      {},
      {
        params: {
          status: "ACTIVATED",
        },
      }
    ).then(
      response => {
        // console.log(response.data);
        setChannels(response.data.data);
        if (onAfterTriggerGetChannelDone) onAfterTriggerGetChannelDone();
        return response.data.data;
      },
      error => {
        if (onAfterTriggerGetChannelDone) onAfterTriggerGetChannelDone();
        return false;
      }
    );
  };

  useEffect(() => {
    dataChicken();
    dataChannel();
  }, []);
  useEffect(() => {
    console.log("triggerGetChicken:", triggerGetChicken);
    if (triggerGetChicken) {
      dataChicken();
    }
  }, [triggerGetChicken]);
  useEffect(() => {
    console.log("triggerGetChannel: ", triggerGetChannel);
    if (triggerGetChannel) {
      dataChannel();
    }
  }, [triggerGetChannel]);
  useEffect(() => {
    if (isSave) {
      const formRulesKeys = Object.keys(formRules);
      const tempDirty = {};
      formRulesKeys.forEach(field => {
        tempDirty[field] = true;
      });
      setIsDirty(tempDirty);
      onSave();
    }
  }, [isSave]);

  useEffect(() => {
    validateForm();
    const tempDirty = {};
    Object.keys(formRules).forEach(field => {
      tempDirty[field] = match[field] || isDirty[field] ? true : false;
    });
    setIsDirty(tempDirty);
  }, [match]);
  const onSave = async () => {
    if (isSave && !validateForm()) {
      setLoading(true);
      const payload = {
        name: match.name,
        chickenRed: match.chickenRed_id,
        chickenBlue: match.chickenBlue_id,
        timeLive: new Date(match.timeLive).toISOString(),
        title: "default",
        channel: match.channel_id,
      };

      await apiAddMatch(payload).then(
        response => {
          toast({
            title: "Thêm mới thành công.",
            description: "Thêm mới thành công.",
            status: "success",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
          setLoading(false);
          onOkAdd();
          setIsSave(false);
          setMatch({});
          setIsDirty({});
          onClose();
          return true;
        },
        error => {
          console.log(error);
          toast({
            title: error?.response?.data?.message || "Đã xảy ra lỗi.",
            position: "top-right",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          setIsSave(false);
          setLoading(false);
        }
      );
    }
  };
  const isError = (field, value) => {
    if (Object.keys(formRules).includes(field)) {
      const rules = formRules[field];
      const error = rules.find(rule => rule.validate(value));
      setErrorForm({
        ...errorForm,
        [field]: error,
      });
      return error;
    }
  };

  const validateForm = () => {
    let isErr = false;
    const errorList = {};
    for (const field in formRules) {
      const error = isError(field, match[field]);
      errorList[field] = error;
      if (error) {
        isErr = true;
      }
    }
    setErrorForm(errorList);
    return isErr;
  };
  return (
    <Modal
      isCentered={true}
      size="full"
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={() => {
        setMatch({});
        setIsDirty({});
        setIsSave(false);
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent
        alignItems="center"
        width="600px"
        maxHeight="80vh!important"
        minHeight="unset!important"
        paddingLeft="80px"
        paddingRight="80px"
      >
        <ModalHeader>
          <Text textAlign="center" fontSize="18px">
            Thêm mới trận đấu
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody width="100%">
          <Box width="100%" position="relative">
            <Grid marginBottom="10px" templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Tên trận đấu <Text color="red">*</Text>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl isInvalid={errorForm.name && isDirty.name}>
                  <Input
                    value={match.name}
                    onChange={e => setMatch({ ...match, name: e.target.value })}
                  />
                  {errorForm.name && (
                    <FormErrorMessage>
                      {errorForm.name.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
            </Grid>

            <Grid marginBottom="10px" templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Đội đỏ <Text color="red">*</Text>
              </GridItem>
              <GridItem colSpan={2} style={{ position: "relative" }}>
                <FormControl
                  isInvalid={errorForm.chickenRed_id && isDirty.chickenRed_id}
                >
                  {/* <Select
                    placeholder="Chọn bên đỏ"
                    onChange={e => {
                      if (e.target.value === match.chickenBlue_id) {
                        setMatch({
                          ...match,
                          chickenBlue_id: "",
                          chickenRed_id: e.target.value,
                        });
                      } else
                        setMatch({
                          ...match,
                          chickenRed_id: e.target.value,
                        });
                      // console.log(match);
                    }}
                    value={match.chickenRed_id}
                  >
                    {chickens?.map((chicken, index) => {
                      return (
                        <option key={index} value={chicken._id}>
                          {chicken.name}
                        </option>
                      );
                    })}
                  </Select> */}
                  <SelectChicken
                    placeholder="Chọn bên đỏ"
                    isModal={true}
                    value={match.chickenRed_id}
                    onChange={value => {
                      if (value === match.chickenBlue_id) {
                        setMatch({
                          ...match,
                          chickenBlue_id: "",
                          chickenRed_id: value,
                        });
                      } else
                        setMatch({
                          ...match,
                          chickenRed_id: value,
                        });
                    }}
                    teamList={chickens}
                  />
                  {errorForm.chickenRed_id && (
                    <FormErrorMessage>
                      {errorForm.chickenRed_id.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <AddIcon
                  cursor="pointer"
                  style={{ top: "10px", right: "-40px", position: "absolute" }}
                  onClick={onAddNewChicken}
                />
              </GridItem>
            </Grid>

            <Grid marginBottom="10px" templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Đội xanh <Text color="red">*</Text>
              </GridItem>
              <GridItem colSpan={2} style={{ position: "relative" }}>
                <FormControl
                  isInvalid={errorForm.chickenBlue_id && isDirty.chickenBlue_id}
                >
                  {/* <Select
                    placeholder="Chọn bên xanh"
                    onChange={e => {
                      // console.log(e.target.value);
                      if (e.target.value === match.chickenRed_id) {
                        setMatch({
                          ...match,
                          chickenRed_id: "",
                          chickenBlue_id: e.target.value,
                        });
                      } else
                        setMatch({
                          ...match,
                          chickenBlue_id: e.target.value,
                        });
                    }}
                    value={match.chickenBlue_id}
                  >
                    {chickens.map((chicken, index) => {
                      return (
                        <option key={index} value={chicken._id}>
                          {chicken.name}
                        </option>
                      );
                    })}
                  </Select> */}
                  <SelectChicken
                    placeholder="Chọn bên xanh"
                    isModal={true}
                    value={match.chickenBlue_id}
                    onChange={value => {
                      if (value === match.chickenRed_id) {
                        setMatch({
                          ...match,
                          chickenRed_id: "",
                          chickenBlue_id: value,
                        });
                      } else
                        setMatch({
                          ...match,
                          chickenBlue_id: value,
                        });
                    }}
                    teamList={chickens}
                  />
                  {errorForm.chickenBlue_id && (
                    <FormErrorMessage>
                      {errorForm.chickenBlue_id.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <AddIcon
                  cursor="pointer"
                  style={{ top: "10px", right: "-40px", position: "absolute" }}
                  onClick={onAddNewChicken}
                />
              </GridItem>
            </Grid>

            <Grid marginBottom="10px" templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Thời gian live <Text color="red">*</Text>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl isInvalid={errorForm.timeLive && isDirty.timeLive}>
                  <Input
                    type="datetime-local"
                    value={match.timeLive}
                    onChange={e => {
                      console.log(e.target.value);
                      setMatch({
                        ...match,
                        timeLive: e.target.value,
                      });
                    }}
                  />
                  {errorForm.timeLive && (
                    <FormErrorMessage>
                      {errorForm.timeLive.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
              {/* <GridItem colSpan={1}>
                <Input
                  type="date"
                  value={new Date(match.d_live)}
                  onChange={value => (match.d_live = value)}
                />
              </GridItem> */}
            </Grid>
            {/* <Grid marginBottom="10px" templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Tiêu đề phiên
              </GridItem>
              <GridItem colSpan={2}>
                <Input
                  value={match.title}
                  onChange={e => setMatch({ ...match, title: e.target.value })}
                />
              </GridItem>
            </Grid> */}
            <Grid marginBottom="10px" templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Kênh <Text color="red">*</Text>
              </GridItem>
              <GridItem colSpan={2} style={{ position: "relative" }}>
                <FormControl
                  isInvalid={errorForm.channel_id && isDirty.channel_id}
                >
                  <Select
                    placeholder="Chọn kênh"
                    onChange={e => {
                      // console.log(e.target.value);
                      setMatch({
                        ...match,
                        channel_id: e.target.value,
                      });
                    }}
                    value={match.channel_id}
                  >
                    {channels.map((channel, index) => {
                      return (
                        <option key={index} value={channel._id}>
                          {channel.name}
                        </option>
                      );
                    })}
                  </Select>
                  {errorForm.channel_id && (
                    <FormErrorMessage>
                      {errorForm.channel_id.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <AddIcon
                  cursor="pointer"
                  style={{ top: "10px", right: "-40px", position: "absolute" }}
                  onClick={onAddNewChannel}
                />
              </GridItem>
            </Grid>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => {
              setIsSave(true);
            }}
          >
            {loading && <Spinner />}
            Tạo mới
          </Button>
          <Button
            variant="ghost"
            onClick={() => {
              setMatch({});
              setIsDirty({});
              setIsSave(false);
              onClose();
            }}
          >
            Huỷ
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
