import { Box } from "@100mslive/roomkit-react";
import { MENU_SIDE_BAR } from "../../constant/menu_side_bar";
import { Center, Flex, StackDivider, Text, VStack } from "@chakra-ui/react";
import { useNavigate, useParams, useRoutes } from "react-router-dom";
import logo from "../../images/logo.png";
import subtitle from "../../images/sub-title.png";
export default function SideBar() {
  const navigate = useNavigate();
  const path = window.location.pathname
    ? window.location.pathname.substring(1)
    : "";
  // console.log(path);
  const commonStyles = {
    position: "fixed",
    width: "200px",
    height: "100%",
    top: 0,
    backgroundColor: "#ffffff",
  };
  const menuToRender = MENU_SIDE_BAR.map(item => {
    return {
      ...item,
      isSelected: false,
    };
  });
  return (
    <VStack className="side-bar" gap={0} align="stretch" {...commonStyles}>
      <Box
        style={{
          padding: "10px 20px",
          filter:
            "drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08))",
          marginBottom: "20px",
          backgroundColor: "#ffffff",
          width: "100%",
          color: "#293038",
        }}
      >
        <Flex alignItems="center" gap={1}>
          <img src={logo} alt="logo" style={{ width: "60px" }} />
          <Box>
            <Text fontSize="20px" fontWeight="bold">
              GaViet
            </Text>
            <img src={subtitle} alt="subtitle" />
          </Box>
        </Flex>
      </Box>
      <Box
        style={{ backgroundColor: "#ffffff", width: "100%", height: "100%" }}
      >
        {MENU_SIDE_BAR.map((item, index) => {
          return (
            <Box
              onClick={() => {
                if (path !== item.key) {
                  navigate(item.to);
                }
              }}
              className={`${path.includes(item.key) ? "active" : ""}`}
              style={{
                padding: "15px",
                cursor: "pointer",
                color: path.includes(item.key) ? "#ffffff" : "#293038",
                backgroundImage: path.includes(item.key)
                  ? "linear-gradient(to right, #184E68 , #57CA85)"
                  : "",
              }}
              key={index}
            >
              <Flex alignItems="center" gap={1}>
                {item.icon}
                <Text fontWeight="semibold">{item.title}</Text>
              </Flex>
            </Box>
          );
        })}
      </Box>
    </VStack>
  );
}
