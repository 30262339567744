import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { set } from "mobx";
import React from "react";
import { useEffect, useState } from "react";
import Avatar from "react-avatar-edit";

export default function ModalUploadAndPreviewImage({
  isOpenModal,
  onCloseModal,
  onSave,
}) {
  const [cropedImage, setCropedImage] = useState(null);
  const [newUploadedImage, setNewUploadedImage] = useState(null);
  const editorRef = React.useRef(null);
  const toast = useToast();
  const onClose = () => {
    setCropedImage(null);
    setNewUploadedImage(null);
  };

  const onCrop = preview => {
    setCropedImage(preview);
  };

  const onBeforeFileLoad = elem => {
    if (elem.target.files[0].size > 2097152) {
      toast({
        title: "Lỗi",
        description: "Ảnh tải lên không được lớn hơn 2MB",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      elem.target.value = "";
    }
  };
  return (
    <>
      <Modal isCentered isOpen={isOpenModal} onClose={onCloseModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Chọn ảnh đại diện</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex align="center" justify="center" gap={10}>
              <Avatar
                ref={editorRef}
                width={390}
                height={295}
                onCrop={onCrop}
                onClose={onClose}
                onBeforeFileLoad={onBeforeFileLoad}
                label="Chọn ảnh từ máy tính"
                src={newUploadedImage}
                style={{
                  overflow: "hidden",
                }}
              />
              <Box>
                <Flex align="center" justify="center">
                  Xem trước
                </Flex>
                <img
                  src={cropedImage}
                  style={{
                    border: "2px solid #f2f2f2",
                    borderRadius: "50%",
                  }}
                />
              </Box>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                onSave(cropedImage);
                onCloseModal();
              }}
            >
              Xác nhận
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                onCloseModal();
                onClose();
              }}
            >
              Huỷ
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
