import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  selectLocalPeerID,
  useHMSStore,
  useVideoList,
} from "@100mslive/react-sdk";
import { Box, StyledVideoList, Text, useTheme } from "@100mslive/roomkit-react";
import { apiDeleteCamera } from "../../api/matches.api";
import { Pagination } from "./Pagination";
import ScreenshareTile from "./ScreenshareTile";
import VideoTile from "./VideoTile";
import useSortedPeers from "../../common/useSortedPeers";
import { useAppConfig } from "./AppData/useAppConfig";
import { useIsHeadless, useUISettings } from "./AppData/useUISettings";
import { UI_SETTINGS } from "../../common/constants";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { on } from "screenfull";

const List = ({
  maxTileCount,
  peers,
  maxColCount,
  maxRowCount,
  includeScreenShareForPeer,
  urlRoomId,
}) => {
  const { aspectRatio } = useTheme();
  const tileOffset = useAppConfig("headlessConfig", "tileOffset");
  const isHeadless = useIsHeadless();
  const hideLocalVideo = useUISettings(UI_SETTINGS.hideLocalVideo);
  const localPeerId = useHMSStore(selectLocalPeerID);
  const [selectedTile, setSelectedTile] = useState(null);
  let sortedPeers = useSortedPeers({ peers, maxTileCount });
  const {
    isOpen: isConfirmDeleteCameraOpen,
    onOpen: onConfirmDeleteCameraOpen,
    onClose: onConfirmDeleteCameraClose,
  } = useDisclosure();
  const cancelRef = React.useRef();
  if (hideLocalVideo && sortedPeers.length > 1) {
    sortedPeers = filterPeerId(sortedPeers, localPeerId);
  }
  const { ref, pagesWithTiles } = useVideoList({
    peers: sortedPeers,
    maxTileCount,
    maxColCount,
    maxRowCount,
    includeScreenShareForPeer,
    aspectRatio,
    offsetY: getOffset({ isHeadless, tileOffset }),
  });
  const [page, setPage] = useState(0);

  console.log(pagesWithTiles);

  const callApiDeleteCamera = async peerID => {
    const payload = {
      cameramanId: peerID,
    };
    await apiDeleteCamera(urlRoomId, payload).then(
      response => {
        console.log(response);
      },
      error => {
        return false;
      }
    );
  };

  useEffect(() => {
    // currentPageIndex should not exceed pages length
    if (page >= pagesWithTiles.length) {
      setPage(0);
    }
  }, [pagesWithTiles.length, page]);
  return (
    <>
      <StyledVideoList.Root ref={ref}>
        <StyledVideoList.Container
          css={{ flexWrap: "wrap", placeContent: "center" }}
        >
          {pagesWithTiles && pagesWithTiles.length > 0
            ? pagesWithTiles[page]?.map(tile => {
                if (tile.width === 0 || tile.height === 0) {
                  return null;
                }
                return (
                  <Fragment key={tile.track?.id || tile.peer.id}>
                    <Box
                      css={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {tile.track?.source === "screen" ? (
                        <ScreenshareTile
                          width={tile.width}
                          height={tile.height}
                          peerId={tile.peer.id}
                        />
                      ) : (
                        <VideoTile
                          width={tile.width}
                          height={tile.height}
                          peerId={tile.peer?.id}
                          trackId={tile.track?.id}
                        />
                      )}

                      <Text
                        variant="sm"
                        css={{
                          mx: "$4",
                          c: "#000",
                          background: "#e2e8f0",
                          w: "max-content",
                          borderRadius: "8px",
                          padding: "8px 16px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSelectedTile(tile);
                          onConfirmDeleteCameraOpen();
                        }}
                      >
                        Turn off
                      </Text>
                    </Box>
                  </Fragment>
                );
              })
            : null}
        </StyledVideoList.Container>
        {!isHeadless && pagesWithTiles.length > 1 ? (
          <Pagination
            page={page}
            setPage={setPage}
            numPages={pagesWithTiles.length}
          />
        ) : null}
      </StyledVideoList.Root>
      <AlertDialog
        isCentered
        isOpen={isConfirmDeleteCameraOpen}
        leastDestructiveRef={cancelRef}
        onClose={onConfirmDeleteCameraClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Tắt camera
            </AlertDialogHeader>

            <AlertDialogBody>Bạn có chắc chắn muốn tắt camera?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onConfirmDeleteCameraClose}>
                Huỷ
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  callApiDeleteCamera(selectedTile.peer.id);
                  onConfirmDeleteCameraClose();
                }}
                ml={3}
              >
                Xác nhận
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const VideoList = React.memo(List);

/**
 * returns a new array of peers with the peer with peerId removed,
 * keeps the reference same if peer is not found
 */
function filterPeerId(peers, peerId) {
  const oldPeers = peers; // to keep the reference same if peer is not found
  let foundPeerToFilterOut = false;
  peers = [];
  for (let i = 0; i < oldPeers.length; i++) {
    if (oldPeers[i].id === peerId) {
      foundPeerToFilterOut = true;
    } else {
      peers.push(oldPeers[i]);
    }
  }
  if (!foundPeerToFilterOut) {
    peers = oldPeers;
  }
  return peers;
}

const getOffset = ({ tileOffset, isHeadless }) => {
  if (!isHeadless || isNaN(Number(tileOffset))) {
    return 32;
  }
  return Number(tileOffset);
};

export default VideoList;
