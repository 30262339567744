// Login.js
import React from "react";
export const AuthWrapper = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        // backgroundImage: `url(${require("../../images/bg-login.png")})`,
        backgroundColor: "#e7e7e7",
      }}
    >
      {children}
    </div>
  );
};
