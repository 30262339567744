// import { useState } from "react";
// import { useHMSActions } from "@100mslive/react-sdk";
import {
  Flex,
  // flexCenter,
  styled,
  Text,
  // textEllipsis,
} from "@100mslive/roomkit-react";
import RoomList from "./Room/RoomList";

const Container = styled("div", {
  width: "100%",
  // ...flexCenter,
  flexDirection: "column",
  px: "$10",
  py: "$10",
});

const Join = () => {
  // const [inputValues, setInputValues] = useState([{}]);

  // const onJoin = async e => {
  //   e.preventDefault();
  //   // const { userName = "", roomCode = "" } = inputValues;

  //   // use room code to fetch auth token
  //   // const authToken = await hmsActions.getAuthTokenByRoomCode({ roomCode });

  //   try {
  //     // await hmsActions.join({ userName, authToken });
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  const data = [
    {
      id: "654089996c4fc3aa925cb76c",
      name: "Room 1",
      status: true,
    },
    {
      id: "6540ea9c6c4fc3aa925cb778",
      name: "Room 2",
      status: true,
    },
  ];

  return (
    <Container>
      <Text
        variant="h4"
        css={{
          wordBreak: "break-word",
          textAlign: "left",
          color: "$on_surface_high",
        }}
      >
        Rooms
      </Text>
      <Flex
        align="center"
        justify="start"
        css={{
          "@sm": { width: "100%" },
          flexDirection: "row",
          gap: "$8",
        }}
      >
        {data.map((item, i) => {
          return <RoomList key={i} data={item} />;
        })}
      </Flex>
    </Container>
  );
};

export default Join;
