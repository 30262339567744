import { CopyIcon } from "@100mslive/react-icons";
import { Flex, Link, useToast } from "@chakra-ui/react";

export default function LinkCamCopy({ value }) {
  const toast = useToast();
  const getCopyLivecode = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_LIVE_CODE_BASE_URL}${value}`
    );
    toast({
      title: "Đã sao chép",
      status: "success",
      duration: 2000,
      position: "top-right",
    });
  };
  return (
    <Flex align="center" gap={5}>
      <Link
        style={{ textDecoration: "underline", color: "#0000ee" }}
        isExternal={true}
        href={`${process.env.REACT_LIVE_CODE_BASE_URL}${value}`}
      >
        Link
      </Link>
      <CopyIcon cursor="pointer" onClick={() => getCopyLivecode()} />
    </Flex>
  );
}
