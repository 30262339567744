// Login.js
import { Box, ChakraProvider } from "@chakra-ui/react";
import LoginForm from "../components/auth/LoginForm";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const navigate = useNavigate();
  const handleEmailNotVerify = email => {
    setIsNotVerifyEmail(true);
    setEmail(email);
  };

  // Check login
  useEffect(() => {
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("refresh_token") &&
      localStorage.getItem("profile") &&
      localStorage.getItem("profile") !== "undefined" &&
      localStorage.getItem("profile") !== "null" &&
      localStorage.getItem("access_token") !== "undefined" &&
      localStorage.getItem("refresh_token") !== "undefined" &&
      localStorage.getItem("access_token") !== "null" &&
      localStorage.getItem("refresh_token") !== "null"
    ) {
      navigate("/");
    } else {
      localStorage.clear();
    }
  }, []);

  return <LoginForm handleEmailNotVerify={handleEmailNotVerify} />;
};

export default Login;
