import { Avatar, Flex, Image, Stack, Text, Button } from "@chakra-ui/react";
import { AvatarMenu } from "./AvatarMenu";

export const Header = ({ title = "Home", children }) => {
  // const isMobile = useMedia(cssConfig.media.md);
  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="10px"
      bg="#ffffff"
      marginLeft="200px"
      color="#293038"
    >
      <Flex align="center" justify="space-between" width="100%">
        <Text fontSize="xl" fontWeight="semibold">
          <Flex align="center" gap={5}>
            {children}
            {title}
          </Flex>
        </Text>
        <Flex align="center" gap="5px">
          <AvatarMenu />
        </Flex>
      </Flex>
    </Flex>
  );
};
