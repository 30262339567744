import { PenIcon, SaveIcon } from "@100mslive/react-icons";
import { EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  Input,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { set } from "mobx";
import { useEffect, useState } from "react";
import { GiCancel } from "react-icons/gi";

export default function BannerUploadAndPreview({
  position,
  defaultImage,
  isShow,
  link,
  onSave,
  loading,
}) {
  const orientation =
    position === "TOP" || position === "BOTTOM" ? "horizontal" : "vertical";
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const toast = useToast();
  const [isEdit, setIsEdit] = useState(false);
  const [isShowToEdit, setIsShowToEdit] = useState(isShow);
  const inputRef = React.useRef(null);
  const [linkToEdit, setLinkToEdit] = useState(link);
  useEffect(() => {
    if (image) {
      if (typeof image === "string") {
        setPreviewImage(
          `${process.env.REACT_BASE_IMG_URL}/uploads/images/${image}`
        );
      } else if (image instanceof File) {
        setPreviewImage(URL.createObjectURL(image));
      }
    } else {
      setPreviewImage(null);
    }
  }, [image]);

  useEffect(() => {
    if (defaultImage) {
      setPreviewImage(
        typeof defaultImage === "string"
          ? `${process.env.REACT_BASE_IMG_URL}/uploads/images/${defaultImage}`
          : null
      );
    }
  }, [defaultImage]);
  return (
    <Box
      style={{
        width: orientation === "horizontal" ? "100%" : "50%",
        padding: "10px",
        border: "1px dashed #ccc",
        borderRadius: "10px",
      }}
    >
      <Flex align="center" justify="space-between" style={{ width: "100%" }}>
        <Text fontWeight="bold">
          {position === "TOP"
            ? "Banner trên"
            : position === "BOTTOM"
            ? "Banner dưới"
            : position === "LEFT"
            ? "Banner trái"
            : "Banner phải"}
        </Text>
        {isEdit ? (
          <Flex align="center" gap="10px">
            <Button
              colorScheme="green"
              onClick={() => {
                const dataToSave = {};
                if (image && typeof image !== "string") {
                  dataToSave.image = image;
                }
                if (linkToEdit !== link) {
                  dataToSave.link = linkToEdit;
                }
                if (isShowToEdit !== isShow) {
                  dataToSave.isShow = isShowToEdit;
                }
                onSave(dataToSave);
                setIsEdit(false);
              }}
            >
              <SaveIcon />
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                setImage(defaultImage);
                setLinkToEdit(link);
                setIsShowToEdit(isShow);
                setIsEdit(false);
              }}
            >
              <GiCancel />
            </Button>
          </Flex>
        ) : (
          <Button
            colorScheme="blue"
            onClick={() => {
              setIsEdit(true);
            }}
          >
            <EditIcon />
          </Button>
        )}
      </Flex>

      {loading ? (
        <Box style={{ width: "100%", height: "100%" }}>
          <Flex
            style={{
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner />
          </Flex>
        </Box>
      ) : (
        <Box
          style={{
            width: "100%",
            position: "relative",
          }}
        >
          {!isEdit && (
            <Box
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 1,
                cursor: "not-allowed",
              }}
            />
          )}
          <Flex
            align="center"
            gap={10}
            style={{ width: "100%", marginBottom: "10px", marginTop: "10px" }}
          >
            <Text fontWeight="bold">Link: </Text>
            <Input
              style={{ width: "400px" }}
              value={linkToEdit}
              onChange={e => {
                console.log(e.target.value);
                setLinkToEdit(e.target.value);
              }}
            />
          </Flex>
          <Flex
            align="center"
            justify="space-between"
            style={{ marginBottom: "10px" }}
          >
            <input
              ref={inputRef}
              type="file"
              accept="image/*"
              onChange={e => {
                console.log(e.target.files[0]);
                const file = e.target.files[0];
                if (file) {
                  const img = document.createElement("img");
                  img.src = URL.createObjectURL(file);
                  // if image's orientation and orientation variable is the same, assign new image to image variable, else show toast error with message "Image's orientation must be {orientation}"
                  img.onload = () => {
                    // console log image size
                    console.log(img.width, img.height);
                    if (
                      (orientation === "horizontal" &&
                        img.width >= img.height) ||
                      (orientation === "vertical" && img.width < img.height)
                    ) {
                      setImage(file);
                    } else {
                      setImage(defaultImage);
                      toast({
                        title: "Lỗi",
                        description: `Ảnh tải lên phải là ${
                          orientation === "horizontal" ? "ảnh ngang" : "ảnh dọc"
                        }!`,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                        position: "top-right",
                      });
                      e.target.value = "";
                      return false;
                    }
                  };
                }
              }}
            />

            <Checkbox
              isChecked={isShowToEdit}
              onChange={e => {
                setIsShowToEdit(e.target.checked);
              }}
            >
              Hiển thị
            </Checkbox>
          </Flex>
          <Image
            src={previewImage}
            style={{
              maxWidth: orientation === "horizontal" ? "100%" : "300px",
              maxHeight: orientation === "horizontal" ? "200px" : "600px",
            }}
          />
        </Box>
      )}
    </Box>
  );
}
