// Login.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalContent,
  Text,
  useToast,
  Box,
  ModalOverlay,
  IconButton,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { apiChangePassword } from "../../api/profile.api";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

export const ChangePasswordPopup = ({ isOpen, onOpen, onClose }) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const [passwordOld, setPasswordOld] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // State error
  const [passwordOldError, setPasswordOldError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [isShowNewPassword, setIsShowNewPassword] = useState(false);

  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };
  const handleShowNewPassword = () => {
    setIsShowNewPassword(!isShowNewPassword);
  };
  const handleShowConfirmPassword = () => {
    setIsShowConfirmPassword(!isShowConfirmPassword);
  };
  const validatePasswordOld = () => {
    console.log("passwordOld", passwordOld);
    if (passwordOld === "") {
      setPasswordOldError("Mật khẩu hiện tại là bắt buộc");
    } else {
      setPasswordOldError("");
    }
  };
  const validatePassword = () => {
    // Password validation for at least 8 characters, including lowercase, uppercase, and a number
    const regex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;
    if (!regex.test(password)) {
      setPasswordError(
        "Mật khẩu phải có ít nhất 8 ký tự, bao gồm chữ thường, chữ in hoa và chữ số"
      );
    } else {
      setPasswordError("");
    }
  };

  const validateConfirmPassword = () => {
    if (password !== confirmPassword) {
      setConfirmPasswordError("Mật khẩu nhập lại không khớp");
    } else {
      setConfirmPasswordError("");
    }
  };

  const resetForm = () => {
    setPasswordOld("");
    setPassword("");
    setConfirmPassword("");
    setPasswordOldError("");
    setPasswordError("");
    setConfirmPasswordError("");
    setIsShowPassword(false);
    setIsShowConfirmPassword(false);
    setIsShowNewPassword(false);
  };

  const handleOnClose = () => {
    resetForm();
    onClose();
  };

  const handleResetPassword = async () => {
    validatePassword();
    validatePasswordOld();
    validateConfirmPassword();
    if (!passwordOldError && !passwordError && !confirmPasswordError) {
      // Gọi API để đăng nhập và nhận refresh token
      const payload = {
        currentPassword: passwordOld,
        newPassword: password,
      };
      setIsLoading(true);
      try {
        localStorage.setItem("passwordResetter", "me");
        await apiChangePassword(payload);
        toast({
          title: "Đổi mật khẩu thành công",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        resetForm();
        onClose();
      } catch (error) {
        // Xử lý lỗi ở đây
        toast({
          title: "Đổi mật khẩu thất bại",
          description: error?.response?.data?.message || "Đã có lỗi xảy ra.",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        localStorage.removeItem("passwordResetter");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <Modal isOpen={isOpen} onClose={handleOnClose} isCentered={true}>
        <ModalOverlay />
        <ModalContent className="change-password">
          <Box
            css={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              padding: "35px 35px",
              flex: 1,
            }}
          >
            <Text
              css={{
                wordWrap: "break-word",
                fontSize: "26px",
                fontWeight: "700",
                lineHeight: "48px",
              }}
              variant="body1"
            >
              Đổi mật khẩu
            </Text>
            <Box
              css={{
                display: "flex",
                flexDirection: "column",
                minWidth: "100%",
                borderRadius: "18px",
                gap: "24px",
                marginTop: "24px",
                flex: 1,
              }}
            >
              {/* Fix lỗi vừa mở popup validate luôn trường đâu tiên */}
              <Input
                type={"password"}
                placeholder="1231"
                value={"passwordOld"}
                color="black"
                style={{
                  position: "absolute",
                  top: "-10000px",
                }}
              />
              <FormControl isRequired isInvalid={!!passwordOldError}>
                <InputGroup>
                  <Input
                    type={isShowPassword ? "text" : "password"}
                    placeholder="Mật khẩu hiện tại"
                    value={passwordOld}
                    onChange={e => setPasswordOld(e.target.value)}
                    onBlur={validatePasswordOld}
                    color="black"
                  />
                  <InputRightElement width="3rem">
                    <IconButton
                      h="1.75rem"
                      size="sm"
                      onClick={handleShowPassword}
                      icon={isShowPassword ? <ViewOffIcon /> : <ViewIcon />}
                    />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{passwordOldError}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!passwordError}>
                <InputGroup>
                  <Input
                    type={isShowNewPassword ? "text" : "password"}
                    placeholder="Mật khẩu"
                    value={password}
                    onChange={e => {
                      console.log("e.target.value", e.target.value);
                      setPassword(e.target.value);
                    }}
                    onBlur={validatePassword}
                    color="black"
                  />
                  <InputRightElement width="3rem">
                    <IconButton
                      h="1.75rem"
                      size="sm"
                      onClick={handleShowNewPassword}
                      icon={isShowNewPassword ? <ViewOffIcon /> : <ViewIcon />}
                    />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{passwordError}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!confirmPasswordError}>
                <InputGroup>
                  <Input
                    type={isShowConfirmPassword ? "text" : "password"}
                    placeholder="Xác nhận mật khẩu"
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                    onBlur={validateConfirmPassword}
                    color="black"
                  />
                  <InputRightElement width="3rem">
                    <IconButton
                      h="1.75rem"
                      size="sm"
                      onClick={handleShowConfirmPassword}
                      icon={
                        isShowConfirmPassword ? <ViewOffIcon /> : <ViewIcon />
                      }
                    />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{confirmPasswordError}</FormErrorMessage>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={!!confirmPasswordError}
                css={{
                  display: "flex",
                  alignItems: "center",
                  gap: "24px",
                }}
              >
                <Button
                  colorScheme="teal"
                  onClick={handleOnClose}
                  style={{
                    flex: 1,
                    background: "#5E5F6A",
                  }}
                >
                  Hủy
                </Button>
                <Button
                  isLoading={isLoading}
                  colorScheme="teal"
                  onClick={handleResetPassword}
                  style={{
                    flex: 1,
                    background:
                      "linear-gradient(90deg, rgba(24,78,104,1) 25%, rgba(87,202,133,1) 100%)",
                  }}
                >
                  Xác nhận
                </Button>
              </FormControl>
            </Box>
          </Box>
        </ModalContent>
      </Modal>
    </div>
  );
};
