import { request } from "./request";

export const apiGetMatches = (data = {}, config = {}) =>
  request("get", "/match", data, config);

export const apiGetMatch = (id, config) =>
  request("get", `/match/${id}`, {}, config);

export const apiDeleteMatch = id => request("delete", `/match/${id}`);

export const apiUpdateMatch = (id, data) =>
  request("patch", `/match/${id}`, data);

export const apiAddMatch = data => request("post", "/match", data);

export const apiDeleteCamera = (idRoom100ms, data = {}, config = {}) =>
  request("delete", `/match/${idRoom100ms}/remove-cameraman`, data, config);
