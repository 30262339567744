import { useState, useEffect } from "react";
import {
  apiAddChicken,
  apiGetChickens,
  apiGetImagesDefault,
  apiUploadImage,
} from "../../api/chickens.api";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spinner,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { set } from "mobx";
import UploadAndPreviewImage from "./UploadAndPreviewImage";

export default function ModalAddChicken({ isOpen, onOpen, onClose, onOkAdd }) {
  const [chicken, setChicken] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [isDirty, setIsDirty] = useState({});
  const [imagesDefaultList, setImagesDefaultList] = useState([]);
  const [isUploadImageSelected, setIsUploadImageSelected] = useState(false);
  const [uploadImage, setUploadImage] = useState(null);
  const [createResponse, setCreateResponse] = useState(null);
  const formRules = {
    image: [
      {
        key: "required",
        message: "Vui lòng chọn ảnh.",
        validate: value => !value,
      },
    ],
    name: [
      {
        key: "required",
        message: "Vui lòng điền tên gà.",
        validate: value => !value,
      },
      {
        key: "maxLength",
        message: "Tên gà không được quá 100 ký tự.",
        validate: value => value && value.length > 100,
      },
    ],
    owner: [
      {
        key: "required",
        message: "Vui lòng điền tên chủ sở hữu.",
        validate: value => !value,
      },
      {
        key: "maxLength",
        message: "Tên chủ sở hữu không được quá 100 ký tự.",
        validate: value => value && value.length > 100,
      },
    ],
    weight: [
      {
        key: "required",
        message: "Vui lòng điền cân nặng.",
        validate: value => !value,
      },
    ],
    origin: [
      {
        key: "required",
        message: "Vui lòng điền xuất xứ.",
        validate: value => !value,
      },
      {
        key: "maxLength",
        message: "Xuất xứ không được quá 100 ký tự.",
        validate: value => value && value.length > 100,
      },
    ],
  };
  const [errorForm, setErrorForm] = useState({});
  const toast = useToast();

  useEffect(() => {
    if (isSave) {
      const formRulesKeys = Object.keys(formRules);
      const tempDirty = {};
      formRulesKeys.forEach(field => {
        tempDirty[field] = true;
      });
      setIsDirty(tempDirty);
      onSave();
    }
  }, [isSave]);

  useEffect(() => {
    onGetImagesDefault();
  }, []);
  const DataURIToBlob = dataURI => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };
  const uploadAvatarChicken = async preResponse => {
    if (isUploadImageSelected) {
      const newChickenId = preResponse.data._id;
      const formData = new FormData();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      console.log(typeof chicken.image);
      formData.append("image", DataURIToBlob(chicken.image));
      await apiUploadImage(newChickenId, formData, config).then(
        response => {
          console.log(response);
          return true;
        },
        error => {
          console.log(error);
          toast({
            title: "Đã xảy ra lỗi khi cập nhật ảnh gà!",
            position: "top-right",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return false;
        }
      );
    }
  };
  useEffect(() => {
    console.log(chicken);
    validateForm();
    const tempDirty = {};
    Object.keys(formRules).forEach(field => {
      tempDirty[field] = chicken[field] || isDirty[field] ? true : false;
    });
    setIsDirty(tempDirty);
  }, [chicken]);

  useEffect(() => {
    if (createResponse) {
      async function afterCreate() {
        await uploadAvatarChicken(createResponse);
        toast({
          title: "Thêm mới gà thành công!",
          position: "top-right",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
        onOkAdd();
        setIsSave(false);
        setChicken({});
        setIsDirty({});
        setIsUploadImageSelected(false);
        setUploadImage(null);
        onClose();
      }
      afterCreate();
    }
  }, [createResponse]);
  const onSave = async () => {
    console.log("isSave", isSave);
    console.log("validateForm", !validateForm());
    if (isSave && !validateForm()) {
      setLoading(true);
      const newChicken = { ...chicken };
      newChicken.weight = parseInt(newChicken.weight);
      if (isUploadImageSelected) delete newChicken.image;
      await apiAddChicken(newChicken).then(
        response => {
          setCreateResponse(response);
          return true;
        },
        error => {
          console.log(error);
          toast({
            title: error?.response?.data?.message || "Đã xảy ra lỗi.",
            position: "top-right",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          setIsSave(false);
          setLoading(false);
        }
      );
    } else {
      setIsSave(false);
    }
  };
  const isError = (field, value) => {
    if (Object.keys(formRules).includes(field)) {
      const rules = formRules[field];
      const error = rules.find(rule => rule.validate(value));
      setErrorForm({
        ...errorForm,
        [field]: error,
      });
      return error;
    }
  };

  const onGetImagesDefault = async () => {
    await apiGetImagesDefault().then(
      response => {
        console.log(response.data.data);
        const data =
          response.data.data.length > 5
            ? response.data.data.slice(0, 5)
            : response.data.data
            ? response.data.data
            : [];

        setImagesDefaultList(data);
      },
      error => {
        console.log(error);
      }
    );
  };

  const validateForm = () => {
    let isErr = false;
    const errorList = {};
    const formRulesKeys = Object.keys(formRules);
    const tempDirty = {};
    formRulesKeys.forEach(field => {
      tempDirty[field] = true;
    });
    setIsDirty(tempDirty);
    for (const field in formRules) {
      const error = isError(field, chicken[field]);
      errorList[field] = error;
      if (error) {
        isErr = true;
      }
    }
    setErrorForm(errorList);
    return isErr;
  };
  return (
    <Modal
      isCentered={true}
      size="full"
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={() => {
        setChicken({});
        setIsDirty({});
        setIsSave(false);
        setIsUploadImageSelected(false);
        setUploadImage(null);
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent
        alignItems="center"
        width="600px"
        maxHeight="80vh!important"
        minHeight="unset!important"
        paddingLeft="80px"
        paddingRight="80px"
      >
        <ModalHeader>
          <Text textAlign="center" fontSize="18px">
            Thêm mới gà
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody width="100%">
          <Box width="100%" position="relative">
            <Grid marginBottom="10px" templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Ảnh gà <Text color="red">*</Text>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl isInvalid={errorForm.image && isDirty.image}>
                  <Box>
                    {/* <Text textAlign="center">Chọn ảnh gà</Text> */}
                    <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                      {imagesDefaultList.map((item, index) => {
                        return (
                          <GridItem colSpan={1} key={index}>
                            <img
                              style={{
                                width: "100%",
                                aspectRatio: "1/1",
                                objectFit: "cover",
                                cursor: "pointer",
                                borderRadius: "50%",
                                border:
                                  chicken.image === item
                                    ? "3px solid orange"
                                    : "",
                              }}
                              src={`${process.env.REACT_BASE_IMG_URL}/uploads/images/${item}`}
                              onClick={() => {
                                setIsUploadImageSelected(false);
                                setChicken({ ...chicken, image: item });
                              }}
                            />
                          </GridItem>
                        );
                      })}
                      <GridItem colSpan={1}>
                        <UploadAndPreviewImage
                          image={uploadImage}
                          isSelected={isUploadImageSelected}
                          onChangeImage={image => {
                            //image as a base64 string
                            // let img = URL.createObjectURL(image);
                            setChicken({
                              ...chicken,
                              image,
                            });
                            setUploadImage(image);
                            setIsUploadImageSelected(true);
                          }}
                        />
                      </GridItem>
                    </Grid>
                  </Box>
                  {errorForm.image && (
                    <FormErrorMessage>
                      {errorForm.image.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
            </Grid>
            <Grid marginBottom="10px" templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Tên gà <Text color="red">*</Text>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl isInvalid={errorForm.name && isDirty.name}>
                  <Input
                    value={chicken.name}
                    onChange={e =>
                      setChicken({ ...chicken, name: e.target.value })
                    }
                  />
                  {errorForm.name && (
                    <FormErrorMessage>
                      {errorForm.name.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
            </Grid>
            <Grid marginBottom="10px" templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Chủ sở hữu <Text color="red">*</Text>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl isInvalid={errorForm.owner && isDirty.owner}>
                  <Input
                    value={chicken.owner}
                    onChange={e =>
                      setChicken({ ...chicken, owner: e.target.value })
                    }
                  />
                  {errorForm.owner && (
                    <FormErrorMessage>
                      {errorForm.owner.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
            </Grid>
            <Grid marginBottom="10px" templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Cân nặng {"(gram)"} <Text color="red">*</Text>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl isInvalid={errorForm.weight && isDirty.weight}>
                  <NumberInput
                    min={1}
                    max={10000}
                    value={chicken.weight}
                    onChange={value =>
                      setChicken({ ...chicken, weight: value })
                    }
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>

                  {errorForm.weight && (
                    <FormErrorMessage>
                      {errorForm.weight.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
            </Grid>
            <Grid marginBottom="10px" templateColumns="repeat(3, 1fr)" gap={6}>
              <GridItem
                style={{ display: "flex", alignItems: "center" }}
                colSpan={1}
              >
                Xuất xứ <Text color="red">*</Text>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl isInvalid={errorForm.origin && isDirty.origin}>
                  <Input
                    value={chicken.origin}
                    onChange={e =>
                      setChicken({ ...chicken, origin: e.target.value })
                    }
                  />
                  {errorForm.origin && (
                    <FormErrorMessage>
                      {errorForm.origin.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
            </Grid>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => {
              setIsSave(true);
            }}
          >
            {loading && <Spinner />}
            Tạo mới
          </Button>
          <Button
            variant="ghost"
            onClick={() => {
              setChicken({});
              setIsDirty({});
              setIsSave(false);
              setIsUploadImageSelected(false);
              setUploadImage(null);
              onClose();
            }}
          >
            Huỷ
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
