export const CHANNEL_STATUSES = [
  {
    value: "ACTIVATED",
    label: "ON",
  },
  {
    value: "DEACTIVATED",
    label: "OFF",
  },
];
