// Login.js
import React from "react";
import { Stack } from "@chakra-ui/react";
export const AuthFormWrapper = ({ children }) => {
  return (
    <Stack
      spacing={6}
      width="600px"
      margin="auto"
      padding="60px"
      boxShadow="md"
      borderRadius="md"
      color="#000"
      style={{
        borderRadius: "18px",
        background: "rgba(255, 255, 255)",
        backdropFilter: "blur(40px)",
        color: "#fff",
        boxShadow:
          "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
      }}
    >
      {children}
    </Stack>
  );
};
