import { REGISTER_TYPES } from "../../constant/register_type";

export default function RegisterMethodType({ type }) {
  const typeList = REGISTER_TYPES;
  return (
    <>
      {typeList.find(item => {
        return item.key === type;
      }).value || "-"}
    </>
  );
}
