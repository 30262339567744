import { useNavigate } from "react-router-dom";
import { useMedia } from "react-use";
import {
  selectDominantSpeaker,
  selectIsConnectedToRoom,
  useHMSStore,
} from "@100mslive/react-sdk";
import { VolumeOneIcon } from "@100mslive/react-icons";
import {
  config as cssConfig,
  Flex,
  styled,
  Text,
  textEllipsis,
  useTheme,
} from "@100mslive/roomkit-react";
import { useLogo } from "../AppData/useUISettings";
import { isStreamingKit } from "../../../common/utils";

export const SpeakerTag = () => {
  const dominantSpeaker = useHMSStore(selectDominantSpeaker);
  return dominantSpeaker && dominantSpeaker.name ? (
    <Flex
      align="center"
      justify="center"
      css={{
        flex: "1 1 0",
        color: "$on_surface_high",
        "@md": { display: "none" },
      }}
    >
      <VolumeOneIcon />
      <Text
        variant="md"
        css={{ ...textEllipsis(200), ml: "$2" }}
        title={dominantSpeaker.name}
      >
        {dominantSpeaker.name}
      </Text>
    </Flex>
  ) : (
    <></>
  );
};

const LogoImg = styled("img", {
  maxHeight: "$14",
  p: "$2",
  w: "auto",
  "@md": {
    maxHeight: "$12",
  },
});

export const Logo = () => {
  const { themeType } = useTheme();
  const navigate = useNavigate();
  const logo = useLogo();
  const isMobile = useMedia(cssConfig.media.md);
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  // Hide logo for now as there is not enough space
  if (isConnected && isMobile && isStreamingKit()) {
    return null;
  }

  const redirectToHomePage = () => {
    navigate("/");
  };

  return (
    <LogoImg
      src={
        logo ||
        (themeType === "dark"
          ? require("../../../images/logo.png")
          : require("../../../images/logo-dark.svg"))
      }
      alt="LiveStream demo"
      width={132}
      height={40}
      css={{
        cursor: "pointer",
      }}
      onClick={redirectToHomePage}
    />
    // <>
    //   <Text
    //     variant="h4"
    //     css={{
    //       wordBreak: "break-word",
    //       textAlign: "left",
    //       color: "$on_surface_high",
    //     }}
    //     onClick={redirectToHomePage}
    //   >
    //     LiveStream
    //   </Text>
    // </>
  );
};
