import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Input,
  Select,
  Spinner,
  Text,
  Toast,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { AddIcon, ChevronLeftIcon, CopyIcon } from "@100mslive/react-icons";
import RoundSelect from "../components/match/RoundSelect";
import ViewCamera from "../components/match/ViewCamera";
import { apiGetChannel, apiGetChannels } from "../api/channels.api";
import { apiGetChickens } from "../api/chickens.api";
import { apiGetMatch, apiUpdateMatch } from "../api/matches.api";
import { Header } from "../layouts/core/Header";
import SideBar from "../layouts/core/SideBar";
import { validate } from "uuid";
import SelectChicken from "../components/chicken/SelectChicken";
import ModalAddChicken from "../components/chicken/ModalAddChicken";
import ModalAddChannel from "../components/channel/ModalAddChannel";

const EditMatchPage = React.memo(
  ({
    authTokenByRoomCodeEndpoint,
    triggerGetChannel,
    triggerGetChicken,
    onAfterTriggerGetChickenDone,
    onAfterTriggerGetChannelDone,
  }) => {
    const [chickens, setChickens] = useState([]);
    const [channels, setChannels] = useState([]);
    const [match, setMatch] = useState({});
    const [matchToEdit, setMatchToEdit] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingSaveCommon, setLoadingSaveCommon] = useState(false);
    const [loadingChicken, setLoadingChicken] = useState(false);
    const [loadingMatch, setLoadingMatch] = useState(false);
    const [loadingChannel, setLoadingChannel] = useState(false);
    const [loadingSaveContents, setLoadingSaveContents] = useState(false);
    // const [isDirty, setIsDirty] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();
    const {
      isOpen: isOpenModalAddChicken,
      onOpen: onOpenModalAddChicken,
      onClose: onCloseModalAddChicken,
    } = useDisclosure();

    const {
      isOpen: isOpenModalAddChannel,
      onOpen: onOpenModalAddChannel,
      onClose: onCloseModalAddChannel,
    } = useDisclosure();
    const formRules = {
      name: [
        {
          key: "required",
          message: "Vui lòng nhập tên trận đấu.",
          validate: value => !value,
        },
        {
          key: "maxLength",
          message: "Tên trận đấu không được quá 100 ký tự.",
          validate: value => value && value.length > 100,
        },
      ],
      chickenRed_id: [
        {
          key: "required",
          message: "Vui lòng chọn đội đỏ",
          validate: value => !value,
        },
      ],
      chickenBlue_id: [
        {
          key: "required",
          message: "Vui lòng chọn đội xanh.",
          validate: value => !value,
        },
      ],
      channel_id: [
        {
          key: "required",
          message: "Vui lòng chọn kênh.",
          validate: value => !value,
        },
      ],
      timeLive: [
        {
          key: "required",
          message: "Vui lòng chọn thời gian live.",
          validate: value => !value,
        },
        {
          key: "invalid",
          message: "Vui lòng không chọn thời gian quá khứ",
          validate: value => {
            const dateTime = new Date(value);
            const now = new Date();
            return (
              dateTime.getTime() < now.getTime() &&
              dateTime.getTime() !== new Date(match.timeLive).getTime()
            );
          },
        },
      ],
      contents_red: [
        {
          key: "maxLength",
          message: "Nội dung không được quá 10 ký tự.",
          validate: value => value && value.length > 10,
        },
      ],
      contents_green: [
        {
          key: "maxLength",
          message: "Nội dung không được quá 10 ký tự.",
          validate: value => value && value.length > 10,
        },
      ],
      contents_blue: [
        {
          key: "maxLength",
          message: "Nội dung không được quá 10 ký tự.",
          validate: value => value && value.length > 10,
        },
      ],
    };
    const [errorForm, setErrorForm] = useState({});

    // const [channel] = useState();
    const params = useParams();
    const matchStatuses = [
      {
        key: "WIN",
        name: "Thắng",
      },
      {
        key: "LOSE",
        name: "Thua",
      },
      {
        key: "DRAW",
        name: "Hòa",
      },
    ];
    // console.log(matchToEdit);
    const dataChicken = async () => {
      setLoadingChicken(true);
      await apiGetChickens().then(
        response => {
          console.log(response.data);
          setChickens(response.data.data);
          setLoadingChicken(false);
          return response.data.data;
        },
        error => {
          setLoadingChicken(false);
          return false;
        }
      );
    };

    const dataChannel = async () => {
      setLoadingChannel(true);
      await apiGetChannels(
        {},
        {
          params: {
            status: "ACTIVATED",
          },
        }
      ).then(
        response => {
          // console.log(response.data);
          setChannels(response.data.data);
          setLoadingChannel(false);
          return response.data.data;
        },
        error => {
          setLoadingChannel(false);
          return false;
        }
      );
    };
    const dataMatch = async () => {
      setLoadingMatch(true);
      await apiGetMatch(params.matchId).then(
        response => {
          // console.log(response.data);
          setMatch(response.data);
          setLoadingMatch(false);
          return response.data;
        },
        error => {
          setLoadingMatch(false);
          return false;
        }
      );
    };

    const hasError = () => {
      if (
        !match.chickenBlue ||
        !match.chickenRed ||
        !match.channel ||
        !match.timeLive ||
        !match.name
      )
        return true;
      return false;
    };

    useEffect(() => {
      console.log("init");
      // initPusher();
    }, []);

    useEffect(() => {
      dataChicken();
      dataChannel();
      dataMatch();
    }, []);

    useEffect(() => {
      if (!loadingMatch && !loadingChannel && !loadingChicken) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    }, [loadingMatch, loadingChannel, loadingChicken]);

    useEffect(() => {
      console.log(matchToEdit);
      setMatchToEdit({
        ...match,
        chickenRed_id: match.chickenRed?._id,
        chickenBlue_id: match.chickenBlue?._id,
        channel_id: match.channel?._id,
        contents_red:
          match.contents && match.contents.length >= 3 ? match.contents[0] : "",
        contents_green:
          match.contents && match.contents.length >= 3 ? match.contents[1] : "",
        contents_blue:
          match.contents && match.contents.length >= 3 ? match.contents[2] : "",
      });
      console.log(matchToEdit);
    }, [match]);
    const onSave = async () => {
      console.log(matchToEdit);
      if (!validateForm()) {
        const link =
          matchToEdit._id +
          (matchToEdit.status === "UPCOMING" ? "" : "/result");
        const payload =
          matchToEdit.status === "UPCOMING"
            ? {
                name: matchToEdit.name,
                chickenRed: matchToEdit.chickenRed_id,
                chickenBlue: matchToEdit.chickenBlue_id,
                channel: matchToEdit.channel_id,
                timeLive: new Date(matchToEdit.timeLive).toISOString(),
                title: matchToEdit.title,
              }
            : {
                round: matchToEdit.round,
                isBreakRound: matchToEdit.isBreakRound,
                resultChickenRed: matchToEdit.resultChickenRed,
              };

        setLoadingSaveCommon(true);
        await apiUpdateMatch(link, payload).then(
          response => {
            onAfterUpdateOk(true);
            return true;
          },
          error => {
            // console.log(error);
            onAfterUpdaterError(error, true);
            return false;
          }
        );
      }
    };
    const saveContents = async () => {
      const payload2 = {
        contents: [
          matchToEdit.contents_red,
          matchToEdit.contents_green,
          matchToEdit.contents_blue,
        ],
        isShowContents: matchToEdit.isShowContents,
      };
      setLoadingSaveContents(true);
      await apiUpdateMatch(`${matchToEdit._id}/result`, payload2).then(
        response => {
          onAfterUpdateOk(true);
          setLoadingSaveContents(false);
          return true;
        },
        error => {
          // console.log(error);
          onAfterUpdaterError(error, true);
          setLoadingSaveContents(false);
          return false;
        }
      );
    };
    const onAfterUpdateOk = (isParial = false) => {
      toast({
        title: "Cập nhật thành công.",
        description: "Cập nhật thành công.",
        status: "success",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
      setLoadingSave(false);
      // setLoading(false);
      setLoadingSaveContents(false);
      setLoadingSaveCommon(false);
      // runPusher(pusherData);
      if (!isParial) window.location.reload();
    };

    const onAfterUpdaterError = (error, isParial = false) => {
      toast({
        title: error?.response?.data?.message || "Đã xảy ra lỗi.",
        position: "top-right",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoadingSave(false);
      setLoading(false);
      setLoadingSaveContents(false);
      setLoadingSaveCommon(false);
    };
    const onStartLive = async () => {
      if (!validateForm()) {
        setLoading(true);
        await apiUpdateMatch(`${matchToEdit._id}/start-live`).then(
          response => {
            onAfterUpdateOk();
            return true;
          },
          error => {
            // console.log(error);
            onAfterUpdaterError(error);
          }
        );
      }
    };

    const onEndLive = async () => {
      setLoading(true);
      await apiUpdateMatch(`${matchToEdit._id}/end-live`).then(
        response => {
          onAfterUpdateOk();
          return true;
        },
        error => {
          // console.log(error);
          onAfterUpdaterError(error);
        }
      );
    };

    useEffect(() => {
      console.log(matchToEdit);
      validateForm();
      console.log(errorForm);
    }, [matchToEdit]);

    const getInputForDatetimeLocalTypeFromUTC0 = dateTimeStrInUTC0 => {
      console.log(dateTimeStrInUTC0);
      if (!dateTimeStrInUTC0) return "";
      const dateTimeInUTC0 = new Date(dateTimeStrInUTC0);
      console.log(dateTimeInUTC0);
      const dateTimeInLocal = new Date(
        dateTimeInUTC0.getTime() - dateTimeInUTC0.getTimezoneOffset() * 60000
      );
      console.log(dateTimeInLocal);
      const dateTimeInLocalStr = dateTimeInLocal.toISOString().slice(0, -1);
      return dateTimeInLocalStr;
    };

    const getCopyLivecode = async () => {
      await apiGetChannel(`${match.channel._id}/code-live`).then(
        response => {
          const code = response.data.code || "";
          console.log(code);
          navigator.clipboard.writeText(
            process.env.REACT_LIVE_CODE_BASE_URL + code
          );
          toast({
            title: "Đã sao chép",
            status: "success",
            duration: 2000,
            position: "top-right",
          });
          return true;
        },
        error => {
          return false;
        }
      );
    };

    const isError = (field, value) => {
      if (Object.keys(formRules).includes(field)) {
        const rules = formRules[field];
        const error = rules.find(rule => rule.validate(value));
        if (error) {
          setErrorForm({
            ...errorForm,
            [field]: error,
          });
        }

        return error;
      }
    };

    const validateForm = () => {
      let isErr = false;
      const errorList = {};
      for (const field in formRules) {
        const error = isError(field, matchToEdit[field]);
        errorList[field] = error;
        if (error) {
          isErr = true;
        }
      }
      setErrorForm(errorList);
      return isErr;
    };

    const navigateToManageTeamPage = () => {
      console.log("click");
      setNavigateUrl("/manage-team");
    };
    return (
      <>
        <Header title="Cập nhật trận đấu">
          <ChevronLeftIcon
            cursor="pointer"
            onClick={() => {
              navigate("/manage-match");
            }}
          />
        </Header>
        <SideBar />

        <Box
          style={{
            marginLeft: "200px",
            backgroundColor: "#f2f2f2",
            width: "calc(100% - 200px)",
            maxHeight: "calc(100vh - 70px)",
            height: "100%",
            padding: "20px",
            color: "#293038",
            overflowY: "auto",
          }}
        >
          <Box
            style={{
              width: "100%",
              // height: "100%",
              background: "#ffffff",
              borderRadius: "10px",
              padding: "20px",
              filter:
                "drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08))",
            }}
          >
            {!loading && (
              <>
                <Flex
                  align="center"
                  justify="space-between"
                  gap="5px"
                  marginBottom="10px"
                >
                  <Flex align="center" gap="5px">
                    Sao chép liên kết phát sóng
                    <CopyIcon
                      cursor="pointer"
                      onClick={() => getCopyLivecode()}
                    />
                  </Flex>
                  <Flex align="center" gap="5px">
                    {matchToEdit.status === "UPCOMING" && (
                      <Button
                        isDisabled={match.channel?.status === "DEACTIVATED"}
                        colorScheme="orange"
                        style={{
                          color: "#293038",
                          border: "1px solid #293038",
                        }}
                        onClick={() => {
                          onStartLive();
                        }}
                      >
                        {loadingSave && <Spinner />}
                        Bắt đầu live
                      </Button>
                    )}
                    {matchToEdit.status === "LIVE" && (
                      <Button
                        isDisabled={match.channel?.status === "DEACTIVATED"}
                        colorScheme="red"
                        onClick={() => {
                          onEndLive();
                        }}
                      >
                        {loadingSave && <Spinner />}
                        Kết thúc live
                      </Button>
                    )}
                    <Button
                      variant="ghost"
                      onClick={() => {
                        navigate("/manage-match");
                      }}
                    >
                      Huỷ
                    </Button>
                  </Flex>
                </Flex>
                <Box
                  width="100%"
                  style={{ border: "1px solid #f2f2f2", padding: "10px" }}
                >
                  <Flex
                    align="center"
                    justify="end"
                    gap="5px"
                    marginBottom="10px"
                  >
                    <Button colorScheme="blue" onClick={onSave}>
                      {loadingSaveCommon && <Spinner />}
                      Lưu
                    </Button>
                  </Flex>
                  <Flex>
                    <Box
                      width="50%"
                      position="relative"
                      style={{ border: "1px solid #f2f2f2", padding: "10px" }}
                    >
                      {matchToEdit.status !== "UPCOMING" && (
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            // background: "#fff",
                            cursor: "not-allowed",
                            opacity: "0.6",
                            zIndex: "1",
                          }}
                        ></div>
                      )}
                      <Text
                        fontSize="16px"
                        textTransform="uppercase"
                        opacity="0.7"
                      >
                        I. Thay đổi thông tin trận đấu
                      </Text>

                      <Grid
                        marginBottom="10px"
                        templateColumns="repeat(5, 1fr)"
                        gap={6}
                      >
                        <GridItem colSpan={1} />
                        <GridItem
                          style={{ display: "flex", alignItems: "center" }}
                          colSpan={1}
                        >
                          Tên trận đấu <Text color="red">*</Text>
                        </GridItem>
                        <GridItem colSpan={2}>
                          <FormControl isInvalid={errorForm.name}>
                            <Input
                              value={matchToEdit.name}
                              onChange={e => {
                                setMatchToEdit({
                                  ...matchToEdit,
                                  name: e.target.value,
                                });
                              }}
                            />
                            {errorForm.name && (
                              <FormErrorMessage>
                                {errorForm.name.message}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        </GridItem>
                        <GridItem colSpan={1} />
                      </Grid>

                      <Grid
                        marginBottom="10px"
                        templateColumns="repeat(5, 1fr)"
                        gap={6}
                      >
                        <GridItem colSpan={1} />
                        <GridItem
                          style={{ display: "flex", alignItems: "center" }}
                          colSpan={1}
                        >
                          Đội đỏ <Text color="red">*</Text>
                        </GridItem>
                        <GridItem colSpan={2}>
                          <FormControl isInvalid={errorForm.chickenRed_id}>
                            {/* <Select
                            placeholder="Chọn bên đỏ"
                            onChange={e => {
                              if (
                                e.target.value === matchToEdit.chickenBlue_id
                              ) {
                                setMatchToEdit({
                                  ...matchToEdit,
                                  chickenRed_id: e.target.value,
                                  chickenBlue_id: "",
                                });
                              } else {
                                setMatchToEdit({
                                  ...matchToEdit,
                                  chickenRed_id: e.target.value,
                                });
                              }
                            }}
                            value={matchToEdit.chickenRed_id}
                          >
                            {chickens.map((chicken, index) => {
                              return (
                                <option key={index} value={chicken._id}>
                                  {chicken.name}
                                </option>
                              );
                            })}
                          </Select> */}
                            <SelectChicken
                              placeholder="Chọn bên đỏ"
                              value={matchToEdit.chickenRed_id}
                              onChange={value => {
                                if (value === matchToEdit.chickenBlue_id) {
                                  setMatchToEdit({
                                    ...matchToEdit,
                                    chickenRed_id: value,
                                    chickenBlue_id: "",
                                  });
                                } else {
                                  setMatchToEdit({
                                    ...matchToEdit,
                                    chickenRed_id: value,
                                  });
                                }
                              }}
                              teamList={chickens}
                            />
                            {errorForm.chickenRed_id && (
                              <FormErrorMessage>
                                {errorForm.chickenRed_id.message}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        </GridItem>
                        <GridItem
                          style={{ display: "flex", alignItems: "center" }}
                          colSpan={1}
                        >
                          <AddIcon
                            cursor="pointer"
                            onClick={onOpenModalAddChicken}
                          />
                        </GridItem>
                      </Grid>

                      <Grid
                        marginBottom="10px"
                        templateColumns="repeat(5, 1fr)"
                        gap={6}
                      >
                        <GridItem colSpan={1} />
                        <GridItem
                          style={{ display: "flex", alignItems: "center" }}
                          colSpan={1}
                        >
                          Đội xanh <Text color="red">*</Text>
                        </GridItem>
                        <GridItem colSpan={2}>
                          <FormControl isInvalid={errorForm.chickenBlue_id}>
                            {/* <Select
                            placeholder="Chọn bên xanh"
                            onChange={e => {
                              if (
                                e.target.value === matchToEdit.chickenRed_id
                              ) {
                                setMatchToEdit({
                                  ...matchToEdit,
                                  chickenBlue_id: e.target.value,
                                  chickenRed_id: "",
                                });
                              }
                              // console.log(e.target.value);
                              else {
                                setMatchToEdit({
                                  ...matchToEdit,
                                  chickenBlue_id: e.target.value,
                                });
                              }
                            }}
                            value={matchToEdit.chickenBlue_id}
                          >
                            {chickens.map((chicken, index) => {
                              return (
                                <option key={index} value={chicken._id}>
                                  {chicken.name}
                                </option>
                              );
                            })}
                          </Select> */}
                            <SelectChicken
                              placeholder="Chọn bên xanh"
                              value={matchToEdit.chickenBlue_id}
                              onChange={value => {
                                if (value === matchToEdit.chickenRed_id) {
                                  setMatchToEdit({
                                    ...matchToEdit,
                                    chickenBlue_id: value,
                                    chickenRed_id: "",
                                  });
                                }
                                // console.log(e.target.value);
                                else {
                                  setMatchToEdit({
                                    ...matchToEdit,
                                    chickenBlue_id: value,
                                  });
                                }
                              }}
                              teamList={chickens}
                            />
                            {errorForm.chickenBlue_id && (
                              <FormErrorMessage>
                                {errorForm.chickenBlue_id.message}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        </GridItem>
                        <GridItem
                          style={{ display: "flex", alignItems: "center" }}
                          colSpan={1}
                        >
                          <AddIcon
                            cursor="pointer"
                            onClick={onOpenModalAddChicken}
                          />
                        </GridItem>
                      </Grid>

                      <Grid
                        marginBottom="10px"
                        templateColumns="repeat(5, 1fr)"
                        gap={6}
                      >
                        <GridItem colSpan={1} />
                        <GridItem
                          style={{ display: "flex", alignItems: "center" }}
                          colSpan={1}
                        >
                          Thời gian live <Text color="red">*</Text>
                        </GridItem>
                        <GridItem colSpan={2}>
                          <FormControl isInvalid={errorForm.timeLive}>
                            <Input
                              type="datetime-local"
                              value={getInputForDatetimeLocalTypeFromUTC0(
                                matchToEdit.timeLive
                              )}
                              onChange={e => {
                                isError("timeLive", e.target.value);
                                setMatchToEdit({
                                  ...matchToEdit,
                                  timeLive: e.target.value,
                                });
                              }}
                            />
                            {errorForm.timeLive && (
                              <FormErrorMessage>
                                {errorForm.timeLive.message}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        </GridItem>
                        {/* <GridItem colSpan={1}>
                  <Input
                    type="date"
                    value={new Date(matchToEdit.d_live)}
                    onChange={value => (matchToEdit.d_live = value)}
                  />
                </GridItem> */}
                        <GridItem colSpan={1} />
                      </Grid>

                      <Grid
                        marginBottom="10px"
                        templateColumns="repeat(5, 1fr)"
                        gap={6}
                      >
                        <GridItem colSpan={1} />
                        <GridItem
                          style={{ display: "flex", alignItems: "center" }}
                          colSpan={1}
                        >
                          Kênh <Text color="red">*</Text>
                        </GridItem>
                        <GridItem colSpan={2}>
                          <FormControl isInvalid={errorForm.channel_id}>
                            <Select
                              placeholder="Chọn kênh"
                              onChange={e => {
                                // console.log(e.target.value);
                                isError("channel_id", e.target.value);
                                setMatchToEdit({
                                  ...matchToEdit,
                                  channel_id: e.target.value,
                                });
                              }}
                              value={matchToEdit.channel_id}
                            >
                              {channels.map((channel, index) => {
                                return (
                                  <option key={index} value={channel._id}>
                                    {channel.name}
                                  </option>
                                );
                              })}
                            </Select>
                            {errorForm.channel_id && (
                              <FormErrorMessage>
                                {errorForm.channel_id.message}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        </GridItem>
                        <GridItem
                          style={{ display: "flex", alignItems: "center" }}
                          colSpan={1}
                        >
                          <AddIcon
                            cursor="pointer"
                            onClick={onOpenModalAddChannel}
                          />
                        </GridItem>
                      </Grid>
                    </Box>

                    <Box
                      width="50%"
                      style={{ border: "1px solid #f2f2f2", padding: "10px" }}
                    >
                      <Text
                        fontSize="16px"
                        textTransform="uppercase"
                        opacity="0.7"
                      >
                        II. Cấu hình thông số trận đấu
                      </Text>

                      <Flex
                        style={{
                          height: "100%",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Grid
                          marginBottom="10px"
                          templateColumns="repeat(3, 1fr)"
                          gap={6}
                          position="relative"
                        >
                          {matchToEdit.status !== "LIVE" && (
                            <div
                              style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                // background: "#fff",
                                cursor: "not-allowed",
                                opacity: "0.6",
                                zIndex: "1",
                              }}
                            ></div>
                          )}
                          <GridItem
                            style={{ display: "flex", alignItems: "center" }}
                            colSpan={1}
                          >
                            Trạng thái hiệp
                          </GridItem>
                          <GridItem colSpan={1}>
                            <RoundSelect
                              currentRound={matchToEdit.round}
                              onChangeRound={newRound => {
                                setMatchToEdit({
                                  ...matchToEdit,
                                  round: newRound,
                                  isBreakRound: false,
                                });
                              }}
                            />
                          </GridItem>
                          <GridItem
                            colSpan={1}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Checkbox
                              size="lg"
                              isChecked={matchToEdit.isBreakRound}
                              onChange={e => {
                                console.log(e.target.checked);
                                setMatchToEdit({
                                  ...matchToEdit,
                                  isBreakRound: e.target.checked,
                                });
                              }}
                            >
                              Nghỉ giữa hiệp{" "}
                              {matchToEdit.isBreakRound
                                ? `${
                                    matchToEdit.round < 8
                                      ? matchToEdit.round +
                                        " - " +
                                        (matchToEdit.round + 1)
                                      : ""
                                  }`
                                : ``}
                            </Checkbox>
                          </GridItem>
                        </Grid>
                        <Grid
                          marginBottom="10px"
                          templateColumns="repeat(3, 1fr)"
                          gap={6}
                          position="relative"
                        >
                          {matchToEdit.status !== "ENDED" && (
                            <div
                              style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                // background: "#fff",
                                cursor: "not-allowed",
                                opacity: "0.6",
                                zIndex: "1",
                              }}
                            ></div>
                          )}
                          <GridItem
                            style={{ display: "flex", alignItems: "center" }}
                            colSpan={1}
                          >
                            Đội đỏ
                          </GridItem>
                          <GridItem colSpan={1}>
                            <Select
                              placeholder="Chọn kết quả"
                              onChange={e => {
                                // console.log(e.target.value);
                                setMatchToEdit({
                                  ...matchToEdit,
                                  resultChickenRed: e.target.value,
                                });
                              }}
                              value={matchToEdit.resultChickenRed}
                            >
                              {matchStatuses.map((status, index) => {
                                return (
                                  <option key={index} value={status.key}>
                                    {status.name}
                                  </option>
                                );
                              })}
                            </Select>
                          </GridItem>
                          <GridItem
                            style={{ display: "flex", alignItems: "center" }}
                            colSpan={1}
                          >
                            Đội xanh
                          </GridItem>
                        </Grid>
                      </Flex>
                    </Box>
                  </Flex>
                </Box>
                <Box
                  width="100%"
                  style={{
                    border: "1px solid #f2f2f2",
                    padding: "10px",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <Flex justify="space-between">
                    <Text
                      fontSize="16px"
                      textTransform="uppercase"
                      opacity="0.7"
                      marginBottom="40px"
                    >
                      III. Cấu hình hiển thị
                    </Text>
                    <Button colorScheme="blue" onClick={saveContents}>
                      {loadingSaveContents && <Spinner />} Lưu
                    </Button>
                  </Flex>
                  <Grid
                    marginBottom="10px"
                    templateColumns="repeat(5, 1fr)"
                    gap={6}
                    position="relative"
                  >
                    <GridItem colSpan={1} />
                    <GridItem style={{ display: "flex" }} colSpan={3}>
                      <FormControl isInvalid={errorForm.contents_red}>
                        <Input
                          background="red"
                          color="white"
                          value={matchToEdit.contents_red}
                          onChange={e => {
                            console.log(e.target.value);
                            setMatchToEdit({
                              ...matchToEdit,
                              contents_red: e.target.value,
                            });
                          }}
                        />
                        {errorForm.contents_red && (
                          <FormErrorMessage>
                            {errorForm.contents_red.message}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                      <FormControl isInvalid={errorForm.contents_green}>
                        <Input
                          background="green"
                          color="white"
                          value={matchToEdit.contents_green}
                          onChange={e => {
                            setMatchToEdit({
                              ...matchToEdit,
                              contents_green: e.target.value,
                            });
                          }}
                        />
                        {errorForm.contents_green && (
                          <FormErrorMessage>
                            {errorForm.contents_green.message}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                      <FormControl isInvalid={errorForm.contents_blue}>
                        <Input
                          background="blue"
                          color="white"
                          value={matchToEdit.contents_blue}
                          onChange={e => {
                            setMatchToEdit({
                              ...matchToEdit,
                              contents_blue: e.target.value,
                            });
                          }}
                        />
                        {errorForm.contents_blue && (
                          <FormErrorMessage>
                            {errorForm.contents_blue.message}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </GridItem>

                    <GridItem
                      colSpan={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Checkbox
                        size="lg"
                        isChecked={matchToEdit.isShowContents}
                        onChange={e => {
                          console.log(e.target.checked);
                          setMatchToEdit({
                            ...matchToEdit,
                            isShowContents: e.target.checked,
                          });
                        }}
                      >
                        Hiển thị
                      </Checkbox>
                    </GridItem>
                  </Grid>
                </Box>

                <Text
                  fontSize="16px"
                  textTransform="uppercase"
                  opacity="0.7"
                  marginBottom="40px"
                >
                  IV. Thay đổi CAMERA
                </Text>
                <ViewCamera
                  authTokenByRoomCodeEndpoint={authTokenByRoomCodeEndpoint}
                  urlRoomId={
                    match.channel && match.channel.idRoom100ms
                      ? match.channel.idRoom100ms
                      : ""
                  }
                />
              </>
            )}
            {loading && (
              <Flex justifyContent="center" alignItems="center" height="50vh">
                <Spinner />
              </Flex>
            )}
          </Box>
        </Box>
        <ModalAddChicken
          isOpen={isOpenModalAddChicken}
          onClose={onCloseModalAddChicken}
          onOkAdd={dataChicken}
        />
        <ModalAddChannel
          isOpen={isOpenModalAddChannel}
          onClose={onCloseModalAddChannel}
          onOkAdd={dataChannel}
        />
      </>
    );
  }
);
export default EditMatchPage;
