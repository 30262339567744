export const USER_STATUSES = [
  {
    value: "ACTIVATED",
    label: "Đã kích hoạt",
  },
  {
    value: "DEACTIVATED",
    label: "Bị cấm",
  },
  {
    value: "NOT_ACTIVATED",
    label: "Chưa kích hoạt",
  },
];
