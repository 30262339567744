import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/menu";
import ChickenItem from "./ChickenItem";
import { Text } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { ChevronDownIcon, ChevronUpIcon } from "@100mslive/react-icons";
import { useEffect, useState } from "react";
import { Flex } from "@100mslive/roomkit-react";

export default function SelectChicken({
  placeholder = "Chọn bên",
  value = "",
  onChange,
  teamList,
  isModal = false,
}) {
  const [selectedChicken, setSelectedChicken] = useState("");
  useEffect(() => {
    console.log(value);
    setSelectedChicken(teamList.find(item => item._id === value));
  }, [value]);
  return (
    <>
      <Menu matchWidth>
        {({ isOpen }) => (
          <>
            <MenuButton
              isActive={isOpen}
              as={Button}
              style={{
                width: "100%",
                height: "fit-content",
                display: "flex",
                alignItems: "center",
              }}
            >
              {!selectedChicken && (
                <Text
                  style={{
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {placeholder}
                </Text>
              )}
              {selectedChicken && (
                <Flex align="center" justify="space-between">
                  <ChickenItem info={selectedChicken}></ChickenItem>
                  {!isOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
                </Flex>
              )}
            </MenuButton>
            <MenuList
              style={{
                maxHeight: !isModal ? "400px" : "180px",
                overflowY: "auto",
                width: "100%",
              }}
            >
              {teamList.map(item => (
                <>
                  <MenuItem
                    width="100%"
                    style={{
                      width: "100%",
                      padding: "0px 12px",
                      borderBottom: "1px solid #e2e8f0",
                    }}
                  >
                    <ChickenItem
                      key={item._id}
                      info={item}
                      onClick={() => onChange(item._id)}
                    />
                  </MenuItem>
                  {/* <MenuDivider /> */}
                </>
              ))}
            </MenuList>
          </>
        )}
      </Menu>
    </>
  );
}
